import React, { useContext } from "react";
import { PageContext } from "../../context/PageProvider";
import { EditorDataContext } from "../../context/EditorDataProvider";
import ToggleSwitch from "../inputs/toggle-switch/ToggleSwitch";
import { useAppSelector } from "../../redux/hook";
import { useTranslation } from "react-i18next";

const ToggleVisibilityHelper = ({ ...props }) => {
    const { page }: any = useContext(PageContext);
    const { editorData, updatePageData }: any = useContext(EditorDataContext);
    const hideHelper = editorData[page]?.hideHelper;
    const { brandConfig } = useAppSelector((state) => state.brandConfig);
    const { primaryColor } = brandConfig;
    const handleChange = () => {
        updatePageData(page, { hideHelper: !hideHelper });
    };
    const { t } = useTranslation();

    return (
        <div {...props}>
            <ToggleSwitch
                color={primaryColor}
                label={t("showPrintBorders")}
                onChange={handleChange}
                checked={!hideHelper}
            />
        </div>
    );
};

export default ToggleVisibilityHelper;
