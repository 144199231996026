import React, { useCallback, useContext, useState } from "react";
import { PhotosContext } from "../../../../context/PhotosProvider";
import { useQueryString } from "../../../../context/QueryStringProvider";
import { removeImageToStorage } from "../../../../services/storage_api";
import PhotoItem from "../../items/photoItem";
import Grid from "../../../Grid/GridContainer";

export default function FoldPhotoMenuGallery() {
    const { photos, removePhoto }: any = useContext(PhotosContext);
    const { storageApi, storageToken } = useQueryString();

    const handleRemoveImage = useCallback(
        (e: React.MouseEvent, id: string, storageSrc: string) => {
            removePhoto(id);
            //TODO TEMPORARY REMOVED IMAGE FROM STORAGE.Need to fix it
            // if (storageApi && storageSrc) {
            //     removeImageToStorage(storageSrc, storageApi, storageToken)
            //         .then()
            //         .catch((err) => {
            //             console.log("can't remove image try again");
            //         });
            // }
            e.stopPropagation();
        },
        [removePhoto, storageToken, storageApi],
    );

    const thumbs = photos.map((file: any, index: number) => (
        <PhotoItem
            key={`img_side_${index}`}
            onRemove={(e, id, src) => handleRemoveImage(e, id, src)}
            id={file.id}
            src={file.storageSrc}
            base64={file.base64}
        />
    ));

    return <Grid itemsPerRow={2}>{thumbs}</Grid>;
}
