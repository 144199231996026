import axios from "axios";
import { EStorageApiMethods } from "../enums/storage-api-methods.enum";
import { IGalleryPhotos } from "../context/PhotosProvider";

const config = { headers: { "Content-Type": "application/json" } };

export const getImagesToStorage = async (
    storageApi: string,
    storageToken: string,
) => {
    return await axios.post(
        storageApi + EStorageApiMethods.GET_IMAGES,
        { storageToken },
        config,
    );
};

export const addImagesToStorage = async (
    data: IGalleryPhotos,
    storageApi: string,
    storageToken: string,
) => {
    return await axios.post(
        storageApi + EStorageApiMethods.ADD_IMAGES,
        { ...data, storageToken },
        config,
    );
};

export const removeImageToStorage = async (
    storageSrc: string,
    storageApi: string,
    storageToken: string,
) => {
    return await axios.post(
        storageApi + EStorageApiMethods.REMOVE_IMAGE,
        { storageSrc, storageToken },
        config,
    );
};
