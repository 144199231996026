import styled, { css } from "styled-components";

export const initialStyle = css`
    &:before {
        margin: 0;
    }
`;

export const FontTelloDiv = styled.div`
    ${initialStyle}
`;

export const FontTelloSpan = styled.span`
    ${initialStyle}
`;
