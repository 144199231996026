import React, { ReactNode, useRef, useState } from "react";
import styled from "styled-components";
import ToolbarBtn from "../buttons/ToolbarBtn";
import Tooltip, { ETooltipPlace } from "../tool-tip/Tooltip";
import { ResetButton } from "../buttons/buttons";
import { ReactComponent as VIcon } from "../../assets/icons/v.svg";
import DropdownMenu from "../dropdown-menu/DropdownMenu";
import { StyledTitleToolBar } from "../style-components/styleComponents";
import { device } from "../../assets/styles/global";

export const OptionsStyled = styled.ul`
    list-style: none;
    background-color: var(--white);
    box-shadow: var(--main-shadow);
    border: solid 1px var(--main-border-color);
    margin: 0;
    padding: 0;
    @media (${device.desktop}) {
        position: absolute;
        right: 0;
        width: 214px;
        border-radius: var(--main-border-radius);
    }
`;

export const StyledButton = styled(ResetButton)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--small-gap);
    font-size: var(--small-font-size);
    border-bottom: solid 1px var(--main-border-color);
    height: 40px;
    &:hover {
        background-color: var(--hover-color);
    }
`;

export const StyledInnerButton = styled.div`
    display: flex;
    align-items: center;
    gap: var(--extra-small-gap);
`;

interface IToolWithOptions {
    id: string;
    toolTipText: string;
    icon: ReactNode;
    handleClick: () => void;
    isInnerMenuOpen: boolean;
    options: { title: string; action: string; icon: ReactNode }[];
    handleClickOptions: (action: any) => void;
    handleDropDownOnClose?: () => void;
}

const ToolWithOptions = ({
    id,
    toolTipText,
    icon,
    handleClick,
    isInnerMenuOpen,
    options,
    handleClickOptions,
    handleDropDownOnClose,
}: IToolWithOptions) => {
    const [activeOption, setActiveOption] = useState<string | null>(null);
    const handleOptionClick = (action: string) => {
        handleClickOptions(action);
        setActiveOption(action);
    };
    const ref = useRef(null);
    return (
        <div style={{ position: "relative" }}>
            <ToolbarBtn
                ref={ref}
                data-tip
                data-for={`${id}ToolTip`}
                onClick={() => handleClick()}
            >
                {icon}
                <StyledTitleToolBar className="hide-desktop">
                    {toolTipText}
                </StyledTitleToolBar>
            </ToolbarBtn>
            <Tooltip
                place={ETooltipPlace.BOTTOM}
                id={`${id}ToolTip`}
                elemRef={ref}
            >
                {toolTipText}
            </Tooltip>
            {isInnerMenuOpen && (
                <DropdownMenu
                    triggerRef={ref}
                    title={toolTipText}
                    handleOnClose={() => {
                        if (handleDropDownOnClose) handleDropDownOnClose();
                    }}
                >
                    <OptionsStyled>
                        {options.map((item, index) => {
                            const isActive = item.action === activeOption;
                            return (
                                <li key={`alignOption${index}`}>
                                    <StyledButton
                                        onClick={() =>
                                            handleOptionClick(item.action)
                                        }
                                        style={
                                            isActive ?
                                                {
                                                    backgroundColor:
                                                        "var(--hover-color)",
                                                }
                                            :   {}
                                        }
                                    >
                                        <StyledInnerButton>
                                            {item.icon}
                                            <span>{item.title}</span>
                                        </StyledInnerButton>
                                        {isActive && <VIcon />}
                                    </StyledButton>
                                </li>
                            );
                        })}
                    </OptionsStyled>
                </DropdownMenu>
            )}
        </div>
    );
};

export default ToolWithOptions;
