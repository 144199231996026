import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as VIcon } from "../../assets/icons/v.svg";
import { ReactComponent as ArrowBottomIcon } from "../../assets/icons/arrow_bottom.svg";
import { ReactComponent as ArrowTopIcon } from "../../assets/icons/arrow_top.svg";
import DropdownMenu from "../dropdown-menu/DropdownMenu";
import { device } from "../../assets/styles/global";
import { StyledTitleToolBar } from "../style-components/styleComponents";

export interface IOptionCustomSelect {
    value: string;
    label: string;
}

interface ICustomSelectProps {
    options: IOptionCustomSelect[];
    onSelect: (value: string) => void;
    direction?: "rtl" | "ltr";
    defaultValue?: string;
    placeHolder?: string;
}

const StyledSelect = styled.div<{ direction: string }>`
    position: relative;
    @media (${device.desktop}) {
        border-radius: var(--main-border-radius);
        height: 30px;
        border: solid 1px var(--main-border-color);
        width: 214px;
    }

    font-size: var(--small-font-size);
    display: flex;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
    direction: ${({ direction }) => direction};
`;
const StyledSelected = styled.div`
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (${device.mobile}) {
        display: flex;
        flex-direction: column;
        gap: var(--extra-small-gap);
        justify-content: center;
    }
`;

const StyledSelectedValue = styled.div`
    @media (${device.mobile}) {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
        width: 50px;
    }
`;
const StyledList = styled.ul<{ direction: string }>`
    @media (${device.desktop}) {
        position: absolute;
        right: 0;
        top: 0;
        width: 214px;
        border-radius: var(--main-border-radius);
    }

    font-size: var(--small-font-size);
    direction: ${({ direction }) => direction};
    list-style: none;
    background-color: var(--white);
    cursor: pointer;
    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: var(--small-gap);
        border-bottom: solid 1px #e7e8e9;
        height: 30px;
        &:hover,
        &.active {
            background-color: var(--hover-color);
        }
        @media (${device.mobile}) {
            padding: var(--medium-gap);
        }
    }
`;

/* TODO Add Select in mobile*/
const CustomSelect = ({
    options,
    onSelect,
    defaultValue,
    direction = "ltr",
    placeHolder = "בחר",
}: ICustomSelectProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState("");
    const selectRef = useRef<HTMLDivElement>(null);

    const handleSelect = (value: string, label: string) => {
        setSelectedValue(label);
        onSelect(value);
        setIsOpen(false);
    };

    useEffect(() => {
        if (defaultValue && defaultValue !== selectedValue) {
            onSelect(defaultValue);
            setSelectedValue(defaultValue);
        }
    }, [defaultValue, onSelect, selectedValue, options]);

    return (
        <StyledSelect direction={direction} ref={selectRef}>
            <StyledSelected onClick={() => setIsOpen(!isOpen)}>
                <StyledSelectedValue>
                    {selectedValue || placeHolder}
                </StyledSelectedValue>
                <StyledTitleToolBar
                    style={{ textAlign: "center" }}
                    className="hide-desktop"
                >
                    {placeHolder}
                </StyledTitleToolBar>
                {!isOpen && <ArrowBottomIcon className="hide-mobile" />}
                {isOpen && <ArrowTopIcon className="hide-mobile" />}
            </StyledSelected>
            {isOpen && (
                <DropdownMenu
                    triggerRef={selectRef}
                    title={placeHolder}
                    handleOnClose={() => setIsOpen(false)}
                >
                    <StyledList direction={direction} className="options-list">
                        {options.map((option) => (
                            <li
                                className={
                                    selectedValue === option.value ?
                                        "active"
                                    :   ""
                                }
                                key={option.value}
                                onClick={() =>
                                    handleSelect(option.value, option.label)
                                }
                            >
                                {option.label}
                                {selectedValue === option.value && <VIcon />}
                            </li>
                        ))}
                    </StyledList>
                </DropdownMenu>
            )}
        </StyledSelect>
    );
};

export default CustomSelect;
