import { configureStore } from "@reduxjs/toolkit";
import stockImagesReducer from "./features/stockImagesSlice";
import brandConfigReducer from "./features/brandConfigSlice";
import errorListPerItemReducer from "./features/errorListPerItemSlice";
import printAreaClientRectReducer from "./features/printAreaClientRectSlice";
import snapPointsReducer from "./features/snapPointsSlice";

export const store = configureStore({
    reducer: {
        stockImages: stockImagesReducer,
        brandConfig: brandConfigReducer,
        errorListPerItem: errorListPerItemReducer,
        printAreaClientRect: printAreaClientRectReducer,
        snapPoints: snapPointsReducer,
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
