import React from "react";

import Edit from "./tools/Edit";
import ColorPick from "./tools/ColorPick";
import Bold from "./tools/Bold";
import UnderLine from "./tools/UnderLine";
import Italic from "./tools/Italic";
import TextDirection from "./tools/TextDirection";
import TextAlignment from "./tools/TextAlignment";
import FontFamily from "./tools/FontFamily";
import FontSize from "./tools/fontSize";
import styled from "styled-components";
import { device } from "../../assets/styles/global";

const StyledWrapTools = styled.div`
    display: flex;
    @media (${device.desktop}) {
        gap: var(--small-gap);
        max-width: 330px;
        width: 100%;
    }
`;

const ToolBarTextItem = () => {
    return (
        <>
            <Edit className="hide-desktop" />
            <StyledWrapTools>
                <FontFamily />
                <FontSize />
            </StyledWrapTools>
            <ColorPick />
            <Bold />
            <UnderLine />
            <Italic />
            <TextDirection />
            <TextAlignment />
        </>
    );
};

export default ToolBarTextItem;
