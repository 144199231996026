import React, {
    ChangeEvent,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { EditorDataContext } from "../../context/EditorDataProvider";
import styled from "styled-components";
import { ESharedWithAllPages } from "../../enums/shared-with-all-pages.enum";
import useSaveProject, { ESaveProjectTypes } from "../../hooks/useSaveProject";

const InputStyled = styled.input`
    border: 1px solid transparent;
    padding: var(--small-gap);
    font-size: var(--main-font-size);
    background-color: transparent;
    outline: none;
    :hover,
    :focus {
        border-color: var(--primary-color-brand);
    }
`;

const ProjectName = ({ ...props }) => {
    const { sharedWithAllPages, updateSharedWithAllPagesByKey }: any =
        useContext(EditorDataContext);
    const [projectName, setProjectName] = useState(
        sharedWithAllPages?.project?.name || "",
    );
    const [debouncedValue, setDebouncedValue] = useState(projectName);
    const { handleOnSaveProject } = useSaveProject();
    const isFirstRender = useRef(true);

    useEffect(() => {
        const handler = setTimeout(() => {
            if (isFirstRender.current) {
                isFirstRender.current = false;
                return;
            }
            updateSharedWithAllPagesByKey(
                ESharedWithAllPages.PROJECT_NAME,
                debouncedValue,
            );
            handleOnSaveProject(null, ESaveProjectTypes.SAVE);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [debouncedValue, updateSharedWithAllPagesByKey]);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setProjectName(event.target.value);
        setDebouncedValue(event.target.value);
    };

    if (!sharedWithAllPages?.project?.enabled) return null;

    return (
        <>
            <InputStyled
                {...props}
                placeholder={sharedWithAllPages?.project?.placeHolder}
                value={projectName}
                onChange={handleInputChange}
            />
        </>
    );
};

export default ProjectName;
