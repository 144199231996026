import React from "react";
import ContextProviders from "./context/ContextProviders";
import { QueryClientProvider, QueryClient } from "react-query";
import InitEditor from "./components/InitEditor";
import { ImportFontTello } from "./assets/Fontello/ImportFontTello";
import Layout from "./components/layout/Layout";
import MessageHandler from "./MessageHandler";
import AppProvider from "./redux/AppProvider";
import "./i18n";

const client = new QueryClient();

const App = () => {
    return (
        <AppProvider>
            <QueryClientProvider client={client}>
                <ContextProviders>
                    <>
                        <ImportFontTello />
                        <MessageHandler />
                        <InitEditor>
                            <Layout />
                        </InitEditor>
                    </>
                </ContextProviders>
            </QueryClientProvider>
        </AppProvider>
    );
};

export default App;
