import React, { useCallback, useContext } from "react";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import { PhotosContext } from "../../../../context/PhotosProvider";
import { nanoid } from "nanoid";
import { MainButton } from "../../../buttons/buttons";
import pdfToImages from "../../../../utils/pdf-to-images";
import FoldPhotoMenuGallery from "./FoldPhotoMenuGallery";
import {
    StyledSideBarContainer,
    StyledSideBarSubTitle,
    StyledSideBarTitle,
} from "../../../style-components/styleComponents";
import { ReactComponent as UploadIcon } from "../../../../assets/icons/upload.svg";

export const allowedFiles = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "application/pdf",
];

const Container = styled(StyledSideBarContainer)``;
const StyledInnerButton = styled.span`
    display: flex;
    align-items: center;
    gap: var(--extra-small-gap);
`;

const AddPhotoMenu = () => {
    const { addPhotos }: any = useContext(PhotosContext);

    const onDrop = useCallback(
        (acceptedFiles) => {
            acceptedFiles.forEach((file: any) => {
                const reader = new FileReader();
                if (file.type === "application/pdf") {
                    pdfToImages(file)
                        .then((images) => {
                            images.forEach((image) => addPhotos(image));
                        })
                        .catch((error) => console.error(error));
                } else {
                    reader.readAsDataURL(file);
                    reader.onloadend = function () {
                        const base64data = reader.result;
                        addPhotos({
                            id: nanoid(),
                            base64: base64data,
                            path: file.path,
                        });
                    };
                }
            });
        },
        [addPhotos],
    );

    const { getRootProps, getInputProps, open } = useDropzone({
        accept: allowedFiles.join(","),
        onDrop,
    });

    return (
        <Container>
            <StyledSideBarTitle className="hide-desktop">
                תמונות
            </StyledSideBarTitle>
            <input {...getInputProps()} />
            <MainButton
                className="AddPhotosButton"
                {...getRootProps()}
                onClick={open}
            >
                <StyledInnerButton>
                    <UploadIcon />
                    <span style={{ fontWeight: "300" }}>העלאת</span>
                    <strong>תמונות</strong>
                </StyledInnerButton>
            </MainButton>
            <StyledSideBarSubTitle>קבצים שהועלו</StyledSideBarSubTitle>
            <FoldPhotoMenuGallery />
        </Container>
    );
};

export default AddPhotoMenu;
