import React, { useContext, useEffect, useState } from "react";
import { EShapes, IListElement } from "./listElements";
import { getSvgObject } from "../../../../utils/editor-items";
import {
    EditorDataContext,
    EUpdateEditorActions,
} from "../../../../context/EditorDataProvider";
import updateStyleSvg, {
    IStyleSvg,
} from "../../../../utils/svg/updateStyleSvg";
import { PageContext } from "../../../../context/PageProvider";
import styled from "styled-components";
import { IEditorDataPageItemSvg } from "../../../../interfaces/editor-data.interface";
import { convert } from "../../../../utils/utils";

export const defaultStyleSvg: IStyleSvg = {
    fill: "#518EED",
    stroke: "#518EED",
    strokeWidth: 0,
};

const ButtonStyled = styled.button`
    background-color: transparent;
    border: none;
    max-width: 80px;
    cursor: pointer;
    img {
        width: 100%;
    }
`;

const FoldElement = ({ svg: svgProps, title, shape }: IListElement) => {
    const { page } = useContext(PageContext);
    const { editorData, updateEditorData }: any = useContext(EditorDataContext);
    const printColor =
        editorData[page].printColor ? editorData[page].printColor : null;
    const [svgItem, setSvgItem] = useState<any>(false);

    const getSvgItem = async () => {
        const response = await fetch(svgProps);
        let svg = await response.text();
        const styleSvg =
            printColor ? { stroke: printColor, fill: printColor } : {};
        const borderColor =
            printColor ? convert(printColor) : defaultStyleSvg.stroke;
        const backgroundColor =
            printColor ? convert(printColor) : defaultStyleSvg.fill;
        svg = updateStyleSvg(svg, { ...defaultStyleSvg, ...styleSvg });
        return { svg, shape, borderColor, backgroundColor };
    };

    useEffect(() => {
        (async () => {
            const item = await getSvgItem();
            setSvgItem(item);
        })();
    }, [page]);

    const handleOnClick = async () => {
        if (!svgItem) return false;
        updateEditorData(null, EUpdateEditorActions.REMOVE_PLACE_HOLDER);
        updateEditorData(getSvgObject(editorData[page], svgItem));
    };

    const handleDragStart = async (e: React.DragEvent) => {
        if (!svgItem) return false;
        const node = e.target as HTMLElement;
        const rect = node.getBoundingClientRect();
        const offsetXElem = e.clientX - rect.x;
        const offsetYElem = e.clientY - rect.y;
        const currentElem = e.target as HTMLImageElement;
        e.dataTransfer.setDragImage(currentElem, offsetXElem, offsetYElem);
        e.dataTransfer.setData(
            "offsetElem",
            JSON.stringify({ offsetXElem, offsetYElem }),
        );
        e.dataTransfer.setData(
            "editorItem",
            JSON.stringify(getSvgObject(editorData[page], svgItem)),
        );
    };

    return (
        <ButtonStyled
            onClick={handleOnClick}
            onDragStart={(e) => handleDragStart(e)}
        >
            <img src={svgProps} alt={title} />
        </ButtonStyled>
    );
};

export default FoldElement;
