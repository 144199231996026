import React from "react";
import useColorsLogic from "../../hooks/useColorsLogic";
import FullColors from "./FullColors";

interface IDropdownColors {
    onClose?: () => void;
    isFold?: boolean;
}

const ChosenColor = ({ onClose, isFold = false }: IDropdownColors) => {
    const { title, initialColor, handleChangeColor, transparent } =
        useColorsLogic();
    return (
        <FullColors
            transparent={transparent}
            isFold={isFold}
            onClose={onClose}
            title={title}
            initialColor={initialColor}
            handleChangeColor={(color) => handleChangeColor(color)}
        />
    );
};

export default ChosenColor;
