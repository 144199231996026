import React from "react";
interface IPhotoIcon {
    fill?: string;
}
export const ElementsIcon = ({ fill = "#518eed" }: IPhotoIcon) => {
    return (
        <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.25 3.25625V8.43682C2.25 8.72369 2.46937 8.94307 2.75625 8.94307H7.93682C8.22369 8.94307 8.44307 8.72369 8.44307 8.43682V3.25625C8.44307 2.96937 8.22369 2.75 7.93682 2.75H2.75625C2.46937 2.75 2.25 2.96937 2.25 3.25625ZM6.80621 7.81245H3.88686C3.59999 7.81245 3.38061 7.59308 3.38061 7.30621V4.38686C3.38061 4.09999 3.59999 3.88061 3.88686 3.88061H6.80621C7.09308 3.88061 7.31245 4.09999 7.31245 4.38686V7.30621C7.31245 7.59308 7.09308 7.81245 6.80621 7.81245Z"
                fill="#1D1D1B"
            />
            <path
                d="M14.6193 10.0566H10.6875C10.0631 10.0566 9.55688 10.5629 9.55688 11.1873V15.1191C9.55688 15.7435 10.0631 16.2497 10.6875 16.2497H14.6193C15.2437 16.2497 15.75 15.7435 15.75 15.1191V11.1873C15.75 10.5629 15.2437 10.0566 14.6193 10.0566ZM14.1131 15.1191H11.1937C10.9069 15.1191 10.6875 14.8997 10.6875 14.6128V11.6935C10.6875 11.4066 10.9069 11.1873 11.1937 11.1873H14.1131C14.4 11.1873 14.6193 11.4066 14.6193 11.6935V14.6128C14.6193 14.8997 14.4 15.1191 14.1131 15.1191Z"
                fill="#1D1D1B"
            />
            <path
                d="M12.6618 2.75C10.9575 2.75 9.57373 4.13374 9.57373 5.8381C9.57373 7.54245 10.9575 8.92619 12.6618 8.92619C14.3662 8.92619 15.7499 7.54245 15.7499 5.8381C15.7499 4.13374 14.3662 2.75 12.6618 2.75ZM12.6618 7.81245C11.565 7.81245 10.6875 6.93496 10.6875 5.8381C10.6875 4.74123 11.565 3.86374 12.6618 3.86374C13.7418 3.86374 14.6362 4.74123 14.6362 5.8381C14.6362 6.93496 13.7418 7.81245 12.6618 7.81245Z"
                fill="#1D1D1B"
            />
            <path
                d="M5.18611 10.8839L2.65488 15.5076C2.46926 15.8451 2.72238 16.2501 3.09363 16.2501H8.13921C8.52733 16.2501 8.76357 15.8451 8.57795 15.5076L6.0636 10.8839C5.87798 10.5296 5.37173 10.5296 5.18611 10.8839ZM6.0636 13.2295L6.68797 14.377C6.87359 14.7145 6.62047 15.1195 6.24922 15.1195H5.00048C4.61236 15.1195 4.37612 14.7145 4.56174 14.377L5.18611 13.2295C5.37173 12.8752 5.87798 12.8752 6.0636 13.2295Z"
                fill="#1D1D1B"
            />
        </svg>
    );
};
