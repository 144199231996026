import React, { createContext, useContext, useEffect, useState } from "react";
import { stringify } from "zipson";
import { IContextProviders } from "../interfaces/context-providers.interface";
import { PageContext } from "./PageProvider";

let flag = true;

interface IValue {
    numState: number;
    numStateRedo: number;
    setNumState: (num: number) => void;
    history: any;
    saveHistory: (state: any) => void;
    setStateStateRedo: (num: number) => void;
}

export const HistoryContext = createContext(null);
export const HistoryProvider = ({ children }: IContextProviders) => {
    const { page }: any = useContext(PageContext);
    const [history, setHistory] = useState<any>([]);
    const [numState, setNumState] = useState(0);
    const [numStateRedo, setStateStateRedo] = useState(0);

    useEffect(() => {
        if (!history.length) {
            return;
        }

        if (numState !== history.length - 1 && flag) {
            const cpHistory = JSON.parse(JSON.stringify(history));
            let newHistory = cpHistory.slice(0, numState);
            newHistory = [...newHistory, history[history.length - 1]];
            setHistory(newHistory);
            setStateStateRedo(0);
            flag = false;
        }

        setNumState(history.length);
    }, [history]);

    const value: any = {
        numState,
        setNumState: (num: number) => setNumState(num),
        numStateRedo,
        setStateStateRedo: (num: number) => setStateStateRedo(num),
        history,
        saveHistory: (state: any) => {
            /*TODO CREATE INTERFACE*/
            const copyState = JSON.parse(JSON.stringify(state));
            const newState = copyState.map((page: any) => {
                page.items.map((item: any) => {
                    if (item.type === "image" && !item.isReplace) {
                        item.base64 = "";
                    }
                    return item;
                });
                return page;
            });
            setHistory([
                ...history,
                { pageHistory: page, data: stringify(newState) },
            ]);
        },
    };

    return (
        <HistoryContext.Provider value={value}>
            {children}
        </HistoryContext.Provider>
    );
};
