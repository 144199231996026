import React, { createContext, useState } from "react";
import TextsType from "../components/sideBar/foldMenu/TextsType";
import AddPhotoMenu from "../components/sideBar/foldMenu/photos/AddPhotoMenu";
import { IContextProviders } from "../interfaces/context-providers.interface";
import { EMenuType } from "../enums/menu-type.enum";
import FoldElements from "../components/sideBar/foldMenu/elements/FoldElements";
import FoldBackgrounds from "../components/sideBar/foldMenu/backgrounds/FoldBackgrounds";
import BorderColor from "../components/colors/BorderColor";
import ChosenColor from "../components/colors/ChosenColors";

interface IFoldMenuContext {
    openFoldMenu: boolean;
    menuType: EMenuType;
    getPickMenu: () => void;
}

export const FoldMenuContext = createContext<IFoldMenuContext | null>(null);
export const FoldMenuProvider = ({ children }: IContextProviders) => {
    const [openFoldMenu, setOpenFoldMenu] = useState(false);
    const [menuType, setMenuType] = useState<any>(null);

    const value = {
        openFoldMenu,
        menuType,
        setOpenFoldMenu: (isOpen: boolean, menuType = null) => {
            setMenuType(menuType);
            setOpenFoldMenu(isOpen);
        },
        getPickMenu: () => {
            switch (menuType) {
                case EMenuType.TEXT:
                    return <TextsType />;
                case EMenuType.COLOR:
                    return <ChosenColor />;
                case EMenuType.PHOTO:
                    return <AddPhotoMenu />;
                case EMenuType.ELEMENTS:
                    return <FoldElements />;
                case EMenuType.BACKGROUND_COLOR:
                    return <ChosenColor />;
                case EMenuType.BACKGROUNDS:
                    return <FoldBackgrounds />;
                case EMenuType.BORDER_COLOR:
                    return <BorderColor />;
                default:
                    return null;
            }
        },
    };

    return (
        <FoldMenuContext.Provider value={value}>
            {children}
        </FoldMenuContext.Provider>
    );
};
