import { Provider } from "react-redux";
import React from "react";
import { store } from "./store";

interface IProviders {
    children: React.ReactNode;
}

const AppProvider = ({ children }: IProviders) => {
    return <Provider store={store}>{children}</Provider>;
};

export default AppProvider;
