import React from "react";
import styled from "styled-components";

const SwitchWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: var(--small-gap);
`;

const Label = styled.label`
    font-size: var(--secondary-font-size);
    cursor: pointer;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
    display: none;
`;

interface ISwitch {
    checked: boolean;
    color?: string;
}
const widthSwitch = 35;
const widthBullet = 18;
const Switch = styled.label<ISwitch>`
    position: relative;
    width: ${widthSwitch}px;
    height: 20px;
    border-radius: 20px;
    background: ${(props) =>
        props.checked ? props.color || "#000000" : "#dfd9ea"};
    transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    vertical-align: middle;
    cursor: pointer;

    &:before {
        content: "";
        position: absolute;
        top: 1px;
        left: ${(props) =>
            props.checked ? `${widthSwitch - widthBullet}px` : "2px"};
        width: ${widthBullet}px;
        height: ${widthBullet}px;
        background: #fafafa;
        border-radius: 50%;
        transition:
            left 0.28s cubic-bezier(0.4, 0, 0.2, 1),
            background 0.28s cubic-bezier(0.4, 0, 0.2, 1),
            box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &:active:before {
        box-shadow: ${(props) =>
            props.checked ?
                "0 2px 8px rgba(0,0,0,0.28), 0 0 0 20px rgba(0,150,136,0.2)"
            :   "0 2px 8px rgba(0,0,0,0.28), 0 0 0 20px rgba(128,128,128,0.1)"};
    }
`;
interface IToggleSwitch {
    label: string;
    onChange: () => void;
    color?: string;
    checked: boolean;
}

const ToggleSwitch = ({ label, onChange, color, checked }: IToggleSwitch) => {
    const uniqueId = Math.random().toString(36).substring(7);
    const id = `toggle-switch-${uniqueId}`;
    return (
        <SwitchWrapper>
            <HiddenCheckbox checked={checked} onChange={onChange} id={id} />
            <Switch htmlFor={id} checked={checked} color={color} />
            {label && <Label htmlFor={id}>{label}</Label>}
        </SwitchWrapper>
    );
};

export default ToggleSwitch;
