import ReactDOM from "react-dom";
import React, { useEffect, useRef, useState } from "react";
import { ToolBarHeight } from "../toolbar/ToolBar";
import styled from "styled-components";
import { device } from "../../assets/styles/global";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ResetButton } from "../buttons/buttons";
import useIsMobile from "../../hooks/useIsMobile";

interface DropdownMenuProps {
    children: React.ReactNode;
    triggerRef?: React.RefObject<HTMLElement>;
    title?: null | string | React.ReactNode;
    handleOnClose?: () => void;
    hideButtonClose?: boolean;
}

const maxHeight = 250;

interface IPositions {
    top: number;
    left: number;
}

interface IStyledDropdownMenu {
    positions: IPositions;
}

const StyledDropdownMenu = styled.div<IStyledDropdownMenu>`
    position: absolute;
    z-index: 8;
    @media (${device.desktop}) {
        top: ${({ positions }) => positions.top}px;
        left: ${({ positions }) => positions.left}px;
    }
    @media (${device.mobile}) {
        bottom: 0;
        width: 100%;
    }
`;

const StyledWrap = styled.div`
    box-shadow: 0 -18px 20px 0 rgba(0, 0, 0, 0.1);
    background: white;
`;
const StyledWrapChildren = styled.div`
    max-height: ${maxHeight}px;
    overflow: auto;
`;
const StyledButtonSlideDown = styled.button`
    border: none;
    background: transparent;
    width: 100%;
    height: 20px;
    &:after {
        content: "";
        width: 59px;
        height: 4px;
        border-radius: 22px;
        box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
        position: absolute;
        left: 50%;
        top: 12px;
        transform: translate(-50%, 0);
    }
`;

const StyledWrapTitle = styled.div`
    padding: 10px var(--small-gap);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--background-text-box);
    .title {
        font-size: var(--secondary-font-size);
        font-weight: bold;
    }
    .btn {
        display: flex;
        align-items: center;
    }
`;

const DropdownMenu: React.FC<DropdownMenuProps> = ({
    children,
    triggerRef,
    title = null,
    handleOnClose,
    hideButtonClose = false,
}) => {
    const [position, setPosition] = useState<IPositions>({ top: 0, left: 0 });
    const [swipeShift, setSwipeShift] = useState(0);
    const [startY, setStartY] = useState(0);
    const [endY, setEndY] = useState(0);
    const [isVisible, setIsVisible] = useState(true);
    const isMobile = useIsMobile();
    const ref = useRef(null);

    const rect = triggerRef?.current?.getBoundingClientRect();
    const leftPosition = rect?.left;
    useEffect(() => {
        if (triggerRef && rect) {
            setPosition({
                top: ToolBarHeight,
                left: rect.left + rect.width,
            });
        }
    }, [triggerRef, leftPosition]);

    const onClose = () => {
        if (handleOnClose) handleOnClose();
        setIsVisible(false);
    };

    const handleTouchStart = (e: React.TouchEvent) => {
        setStartY(e.touches[0].clientY);
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        const touchY = e.touches[0].clientY;
        const shift = startY - touchY;

        if (shift < 0) {
            setSwipeShift(Math.max(shift, -window.innerHeight * 0.7));
        } else {
            setSwipeShift(0);
        }
        setEndY(touchY);
    };

    const handleTouchEnd = () => {
        const currentRef = ref.current as any;
        if (currentRef) {
            const menuHeight = currentRef.offsetHeight;
            const totalShift = startY - endY;
            const shiftPercentage = (Math.abs(totalShift) / menuHeight) * 100;
            if (shiftPercentage > 65) {
                onClose();
            } else {
                setSwipeShift(0);
            }
        }
    };

    if (!isVisible) {
        return null;
    }
    return ReactDOM.createPortal(
        <StyledDropdownMenu
            positions={position}
            style={{ bottom: `${swipeShift}px` }}
            ref={ref}
        >
            {!isMobile && children}

            {isMobile && (
                <>
                    <StyledButtonSlideDown
                        className="hide-desktop"
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}
                    />
                    <StyledWrap>
                        {title && (
                            <StyledWrapTitle className="hide-desktop">
                                <div className="title">{title}</div>
                                {!hideButtonClose && (
                                    <ResetButton
                                        onClick={onClose}
                                        className="btn"
                                    >
                                        <CloseIcon />
                                    </ResetButton>
                                )}
                            </StyledWrapTitle>
                        )}
                        <StyledWrapChildren>{children}</StyledWrapChildren>
                    </StyledWrap>
                </>
            )}
        </StyledDropdownMenu>,
        document.getElementById("modal_canvas") as HTMLElement,
    );
};

export default DropdownMenu;
