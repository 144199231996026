import React, { useState } from "react";
import DropdownMenu from "../dropdown-menu/DropdownMenu";
import ArrowButton from "../buttons/ArrowButton";
import Colors from "./Colors";
import ColorPicker from "./ColorPicker";
import styled from "styled-components";
import useIsMobile from "../../hooks/useIsMobile";
import {
    StyledSideBarContainer,
    StyledSideBarTitle,
} from "../style-components/styleComponents";

interface IDropdownColors {
    title: string;
    initialColor: string;
    handleChangeColor: (color: string) => void;
    onClose?: () => void;
    isFold?: boolean;
    transparent?: boolean;
}

const StyledWrapDropDown = styled.div`
    padding: var(--small-gap);
`;
const FullColors = ({
    title,
    initialColor,
    handleChangeColor,
    onClose,
    isFold = false,
    transparent = false,
}: IDropdownColors) => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const isMobile = useIsMobile();
    const titleComponent =
        isOpen ?
            <ArrowButton
                style={{ fontWeight: "bold" }}
                before={true}
                handleOnclick={() => setOpen(false)}
            >
                {title}
            </ArrowButton>
        :   title;
    const showSection = isFold ? !isFold : isMobile;
    return (
        <>
            {showSection && (
                <DropdownMenu
                    hideButtonClose={isOpen}
                    title={titleComponent}
                    handleOnClose={() => {
                        setOpen(false);
                        if (onClose) onClose();
                    }}
                >
                    <StyledWrapDropDown>
                        {isOpen && (
                            <>
                                <ColorPicker
                                    defaultColor={initialColor}
                                    onColorChange={(color) =>
                                        handleChangeColor(color)
                                    }
                                />
                            </>
                        )}
                        {!isOpen && (
                            <>
                                <Colors
                                    transparent={transparent}
                                    handleAddNewColor={() => setOpen(true)}
                                    handleChangeColor={(color) =>
                                        handleChangeColor(color)
                                    }
                                />
                            </>
                        )}
                    </StyledWrapDropDown>
                </DropdownMenu>
            )}

            {!showSection && (
                <StyledSideBarContainer>
                    {isOpen && (
                        <>
                            <ArrowButton
                                before={true}
                                handleOnclick={() => setOpen(false)}
                            >
                                {title}
                            </ArrowButton>
                            <ColorPicker
                                defaultColor={initialColor}
                                onColorChange={(color) =>
                                    handleChangeColor(color)
                                }
                            />
                        </>
                    )}
                    {!isOpen && (
                        <>
                            <StyledSideBarTitle>{title}</StyledSideBarTitle>
                            <Colors
                                transparent={transparent}
                                handleAddNewColor={() => setOpen(true)}
                                handleChangeColor={(color) =>
                                    handleChangeColor(color)
                                }
                            />
                        </>
                    )}
                </StyledSideBarContainer>
            )}
        </>
    );
};

export default FullColors;
