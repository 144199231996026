import { useMutation } from "react-query";
import { generateImageSingleColor } from "../endpoints";
import useCustomAxios from "./useCustomAxios";

export interface IGenerateImageSingleColor {
    base64: string;
    color: string;
    storageSrc: string | undefined;
}
const useImageSingleColor = () => {
    const customAxios = useCustomAxios();
    const handleOnSubmit = async (params: IGenerateImageSingleColor) =>
        await customAxios.post(generateImageSingleColor, params);
    return useMutation("generateImageSingleColor", handleOnSubmit);
};
export default useImageSingleColor;
