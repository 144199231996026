export const breakpoints = {
    mobile: 768,
    tablet: 1024,
};

export const device = {
    mobile: `(max-width: ${breakpoints.mobile}px)`,
    tablet: `(max-width: ${breakpoints.tablet})px`,
    desktop: `(min-width: ${breakpoints.mobile}px)`,
};
