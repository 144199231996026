export enum EFontStyle {
    EXTRA_BOLD = "ExtraBold",
    SEMI_BOLD = "SemiBold",
    REGULAR = "Regular",
}

export enum EFontWeight {
    EXTRA_BOLD_WEIGHT = "800",
    SEMI_BOLD_WEIGHT = "700",
    REGULAR_WEIGHT = "400",
}
