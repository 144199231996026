import React from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import styled from "styled-components";
import {
    IErrorPerItem,
    setOpenErrorSection,
} from "../../../redux/features/errorListPerItemSlice";
import { StyledSideBarTitle } from "../../style-components/styleComponents";
import { ReactComponent as AlertIcon } from "../../../assets/icons/alert.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import { ResetButton } from "../../buttons/buttons";
import useFindItemById from "../../../hooks/useFindItemById";
import ErrorSectionItem from "./ErrorSectionItem";
import { device } from "../../../assets/styles/global";

const StyledWrapErrorSection = styled.div`
    max-width: 100%;
    height: 100%;
    overflow: auto;
    background-color: var(--white);
    padding: var(--medium-gap);
    box-sizing: border-box;
    @media (${device.mobile}) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        z-index: 55;
        padding-top: 0;
    }
`;
const StyledTitle = styled(StyledSideBarTitle)`
    display: flex;
    align-items: center;
    gap: var(--extra-small-gap);
    background-color: transparent;
    margin: 0;
    padding: 0;
    svg {
        width: 20px;
        height: 20px;
    }
    .count {
        font-weight: normal;
        font-size: var(--small-font-size);
        @media (${device.desktop}) {
            margin-top: 4px;
        }
    }
`;
const StyledWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (${device.mobile}) {
        background-color: var(--background-text-box);
        height: 46px;
        padding: var(--small-gap);
        box-sizing: border-box;
        margin-left: calc(-1 * var(--medium-gap));
        margin-right: calc(-1 * var(--medium-gap));
    }
`;

const StyledWrapperErrorsItem = styled.div`
    margin-top: var(--medium-gap);
    display: flex;
    flex-direction: column;
    gap: var(--small-gap);
`;

const ErrorSection = ({ ...props }) => {
    const errorListPerItem = useAppSelector((state) => state.errorListPerItem);
    const { openErrorSection, errorPerItem } = errorListPerItem;
    const countErrors = errorPerItem.reduce(
        (acc: number, item: IErrorPerItem) => acc + item.errors.length,
        0,
    );
    const dispatch = useAppDispatch();
    const findItemById = useFindItemById();

    if (!openErrorSection || countErrors === 0) return null;
    return (
        <StyledWrapErrorSection {...props}>
            <StyledWrap>
                <StyledTitle>
                    <AlertIcon />
                    <span>שגיאות</span>
                    <span className="count">({countErrors})</span>
                </StyledTitle>
                <ResetButton
                    style={{ padding: 0 }}
                    onClick={() => dispatch(setOpenErrorSection(false))}
                >
                    <CloseIcon />
                </ResetButton>
            </StyledWrap>
            <StyledWrapperErrorsItem>
                {errorPerItem.map((item: IErrorPerItem, index: number) => (
                    <ErrorSectionItem
                        errors={item.errors}
                        key={`error_item${index}`}
                        item={findItemById(item.id)}
                    />
                ))}
            </StyledWrapperErrorsItem>
        </StyledWrapErrorSection>
    );
};

export default ErrorSection;
