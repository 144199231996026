export enum ETextAlignment {
    CENTER = "center",
    LEFT = "left",
    RIGHT = "right",
}

export enum ETextDirection {
    EMPTY = "",
    RTL = "RTL",
    LTR = "LTR",
}
