import React, { useContext, useRef } from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeEditor.svg";
import Tooltip, { ETooltipPlace } from "../tool-tip/Tooltip";
import { nanoid } from "nanoid";
import { EditorDataContext } from "../../context/EditorDataProvider";
import { getTargetOrigin } from "../../utils/origin";
import { NavButton } from "../nav/Nav";
import { EPostMessageActions } from "../../enums/post-message-actions.enum";

const CloseEditor = ({ ...props }) => {
    const { editorData }: any = useContext(EditorDataContext);
    const origin = getTargetOrigin();
    const ref = useRef(null);
    const txt = "יציאה";
    const id = `closeEditor${nanoid()}`;
    return (
        <div {...props}>
            <NavButton
                ref={ref}
                data-tip
                data-for={id}
                onClick={() => {
                    if (origin) {
                        window.parent.postMessage(
                            {
                                action: EPostMessageActions.CLOSE_EDITOR,
                                response: editorData,
                            },
                            origin,
                        );
                    }
                }}
            >
                <CloseIcon />
                <span className="hide-desktop">{txt}</span>
            </NavButton>
            <Tooltip place={ETooltipPlace.BOTTOM} id={id} elemRef={ref}>
                {txt}
            </Tooltip>
        </div>
    );
};

export default CloseEditor;
