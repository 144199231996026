import React, { useContext } from "react";
import { ReactComponent as MainIcon } from "../../assets/icons/menu.svg";
import styled from "styled-components";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as EyeIcon } from "../../assets/icons/eye.svg";
import ProjectName from "../project-name/ProjectName";
import SaveProject from "../SaveProject";
import { ResetButton } from "../buttons/buttons";

import LoadingTopSnake from "../loading-snake/loading-top-snake";
import CloseEditor from "../close-editor/CloseEditor";
import { device } from "../../assets/styles/global";
import ToggleVisibilityHelper from "../toggle-visibility-helper/ToggleVisibilityHelper";

const StyledNav = styled.nav`
    position: fixed;
    z-index: 50;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: var(--white);
    visibility: hidden;
    transition: 0.35s cubic-bezier(0.85, 0, 0.3, 1);
    transform: translateX(100vw);
`;

const StyledWrap = styled.div`
    input.menu-toggle:checked ~ .main-menu {
        visibility: visible;
        transform: translateX(0);
        transition-delay: 0.15s;
    }
`;

const StyledWrapTop = styled.div`
    background-color: var(--background-text-box);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: var(--small-gap);
    .close-icon {
        width: 20px;
        height: 20px;
    }
`;
const StyledUl = styled.ul`
    li {
        display: flex;
        align-items: center;
        padding: var(--small-gap);
        border-bottom: 1px solid var(--main-border-color);
    }
`;

export const NavButton = styled(ResetButton)`
    @media (${device.mobile}) {
        display: flex;
        align-items: center;
        padding: 0;
        gap: var(--medium-gap);
        svg {
            width: 20px;
            height: 20px;
        }
    }
`;
interface INavProps {
    isFetching: boolean;
    refetch: () => void;
    className?: string;
}
const Nav = ({ isFetching, refetch, className }: INavProps) => {
    return (
        <StyledWrap className={className}>
            {isFetching && <LoadingTopSnake />}
            <input
                className="hidden menu-toggle"
                type="checkbox"
                id="menu-toggler"
            />
            <label htmlFor="menu-toggler">
                <MainIcon />
            </label>

            <StyledNav className="main-menu">
                <StyledWrapTop>
                    <ProjectName />
                    <label htmlFor="menu-toggler">
                        <CloseIcon className="close-icon" />
                    </label>
                </StyledWrapTop>
                <StyledUl>
                    <li>
                        <ToggleVisibilityHelper />
                    </li>
                    <li>
                        <SaveProject label={true} />
                    </li>
                    <li>
                        <NavButton onClick={refetch}>
                            <EyeIcon />
                            <span>תצוגה מקדימה</span>
                        </NavButton>
                    </li>
                    <li>
                        <CloseEditor />
                    </li>
                </StyledUl>
            </StyledNav>
        </StyledWrap>
    );
};

export default Nav;
