import React, { createContext, useState } from "react";
import { IContextProviders } from "../interfaces/context-providers.interface";

interface IPhotos {
    id: string;
    base64: string;
}

interface IPhotosInCanvasContext {
    photos: IPhotos;
    savePhotos: (item: IPhotos) => void;
}

export const PhotosInCanvasContext =
    createContext<IPhotosInCanvasContext | null>(null);
export const PhotosInCanvasProvider = ({ children }: IContextProviders) => {
    const [photos, setPhotos] = useState<any>([]);

    const value = {
        photos,
        savePhotos: (item: IPhotos) => {
            const { id, base64 } = item;
            const newItem = [{ id, base64 }];
            setPhotos((prevState: any) => [...prevState, ...newItem]);
        },
    };
    return (
        <PhotosInCanvasContext.Provider value={value}>
            {children}
        </PhotosInCanvasContext.Provider>
    );
};
