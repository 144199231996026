import React, { createContext, useState } from "react";
import { IContextProviders } from "../interfaces/context-providers.interface";

interface IImageDraggedContext {
    imageDragged: boolean;
    setImageDragged: (param: boolean) => void;
}
export const ImageDraggedContext = createContext<IImageDraggedContext | null>(
    null,
);
export const ImageDraggedProvider = ({ children }: IContextProviders) => {
    const [imageDragged, setImageDragged] = useState(false);
    const value = {
        imageDragged,
        setImageDragged: (param: boolean) => setImageDragged(param),
    };

    return (
        <ImageDraggedContext.Provider value={value}>
            {children}
        </ImageDraggedContext.Provider>
    );
};
