import React from "react";
import BackgroundColor from "./tools/BackgroundColor";
import BorderBox from "./tools/BorderBox";
import { IToggleInnerMenu } from "./ToolBar";

const ToolBarSvgItem = ({ innerMenu, toggleInnerMenu }: IToggleInnerMenu) => {
    return (
        <>
            <BackgroundColor />
            <BorderBox
                innerMenu={innerMenu}
                toggleInnerMenu={toggleInnerMenu}
            />
        </>
    );
};

export default ToolBarSvgItem;
