import React, { createContext, useEffect, useState } from "react";
import { IContextProviders } from "../interfaces/context-providers.interface";
import { useQueryString } from "./QueryStringProvider";
import { addImagesToStorage } from "../services/storage_api";
import useIndexedDB, { EIndexDbSettings } from "../hooks/useIndexedDB";

export interface IPhotosContext {
    photos: IGalleryPhotos[];
    addPhotos: (newItem: IGalleryPhotos) => void;
    removePhoto: (id: string) => void;
}

export interface IGalleryPhotos {
    base64: string;
    id: string;
    path: string;
    storageSrc?: string;
}

export const PhotosContext = createContext<IPhotosContext | null>(null);
export const PhotosProvider = ({ children }: IContextProviders) => {
    const { storageApi, storageToken } = useQueryString();

    const { data, addData, deleteData } = useIndexedDB(
        EIndexDbSettings.LOCAL_USE_PHOTOS,
        [],
    );
    const [photos, setPhotos] = useState<IGalleryPhotos[]>([]);

    const handleStorageUpload = async (newItem: IGalleryPhotos) => {
        try {
            const res = await addImagesToStorage(
                newItem,
                storageApi,
                storageToken,
            );
            if (typeof res !== "boolean") {
                const { id, storageSrc } = res.data;
                updatePhotoInState(id, { id, storageSrc });
                if (!storageToken) {
                    await addData(res.data);
                }
            }
        } catch (error) {
            await addData(newItem);
        }
    };

    const updatePhotoInState = (
        id: string,
        updatedPhoto: Partial<IGalleryPhotos>,
    ) => {
        setPhotos((prevPhotos) =>
            prevPhotos.map((photo) =>
                photo.id === id ? { ...photo, ...updatedPhoto } : photo,
            ),
        );
    };

    useEffect(() => {
        if (!storageToken && Array.isArray(data) && data.length !== 0) {
            setPhotos(data);
        }
    }, [data]);

    const value = {
        photos,
        initPhotos: (photos: Array<IGalleryPhotos>) => setPhotos(photos),
        addPhotos: (newItem: IGalleryPhotos) => {
            setPhotos((prev) => [newItem, ...prev]);
            if (storageApi) {
                handleStorageUpload(newItem);
            } else {
                addData(newItem).catch(console.error);
            }
        },
        removePhoto: (id: string) => {
            const items = photos.filter(
                (photo: IGalleryPhotos) => photo.id !== id,
            );
            setPhotos(items);
            if (!storageToken) {
                deleteData(id).then();
            }
        },
    };
    return (
        <PhotosContext.Provider value={value}>
            {children}
        </PhotosContext.Provider>
    );
};
