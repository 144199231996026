import React from "react";
import { PageProvider } from "./PageProvider";
import { EditorDataProvider } from "./EditorDataProvider";
import { PhotosProvider } from "./PhotosProvider";
import { HistoryProvider } from "./HistoryProvider";
import { PhotosInCanvasProvider } from "./PhotosInCanvasProvider";
import { ImageDraggedProvider } from "./ImageDraggedProvider";
import { FoldMenuProvider } from "./FoldMenuProvider";
import { IContextProviders } from "../interfaces/context-providers.interface";
import QueryStringProvider from "./QueryStringProvider";
import CanvasBoundingClientRectProvider from "./CanvasBoundingClientRectProvider";
import FontSizesProvider from "./FontSizesProvider";

const ContextProviders = ({ children }: IContextProviders) => (
    <PageProvider>
        <QueryStringProvider>
            <HistoryProvider>
                <PhotosInCanvasProvider>
                    <FoldMenuProvider>
                        <EditorDataProvider>
                            <CanvasBoundingClientRectProvider>
                                <PhotosProvider>
                                    <ImageDraggedProvider>
                                        <FontSizesProvider>
                                            {children}
                                        </FontSizesProvider>
                                    </ImageDraggedProvider>
                                </PhotosProvider>
                            </CanvasBoundingClientRectProvider>
                        </EditorDataProvider>
                    </FoldMenuProvider>
                </PhotosInCanvasProvider>
            </HistoryProvider>
        </QueryStringProvider>
    </PageProvider>
);

export default ContextProviders;
