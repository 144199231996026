import React from "react";
import { createPortal } from "react-dom";

interface IModal {
    children: React.ReactChild;
}
export const Modal = ({ children }: IModal) => {
    return createPortal(
        <>{children}</>,
        document.getElementById("modal") as HTMLElement,
    );
};

export default Modal;
