export interface IStyleSvg {
    fill: string;
    stroke: string;
    strokeWidth: number;
}

const updateStyleSvg = (svg: string, styleSvg: IStyleSvg) => {
    const { fill, stroke, strokeWidth } = styleSvg;
    const fillStr = fill ? `fill:${fill};` : "";
    const strokeStr = stroke ? `stroke:${stroke};` : "";
    const strokeWidthStr = `stroke-width:${(strokeWidth as number) * 2};`;
    const newStyle = `style="${fillStr}${strokeStr}${strokeWidthStr}"`;
    return svg.replace(/style="([^"]*)"/, newStyle);
};

export default updateStyleSvg;
