import React, { ReactNode, useContext } from "react";
import { EInnerMenu, IToggleInnerMenu } from "../ToolBar";
import ToolWithOptions from "../ToolWithOptions";
import { PageContext } from "../../../context/PageProvider";
import { EditorDataContext } from "../../../context/EditorDataProvider";
import { IEditorDataPageItemText } from "../../../interfaces/editor-data.interface";
import { CM2PX } from "../../../utils/utils";
import { ReactComponent as AlignRightIcon } from "../../../assets/icons/align_right.svg";
import { ReactComponent as AlignCenterIcon } from "../../../assets/icons/align_center.svg";
import { ReactComponent as AlignLeftIcon } from "../../../assets/icons/align_left.svg";
import { ReactComponent as AlignHorizontalIcon } from "../../../assets/icons/align_horizontal.svg";
import { ReactComponent as AlignTopIcon } from "../../../assets/icons/align_top.svg";
import { ReactComponent as AlignBottomIcon } from "../../../assets/icons/align_bottom.svg";
import { borderBox, isContentBox } from "../../../utils/editor-items";
import { useAppSelector } from "../../../redux/hook";
import { useSnapPoints } from "../../../hooks/useSnapPoints";

const ActionIcons = {
    ALIGN_LEFT: <AlignLeftIcon />,
    ALIGN_HORIZONTAL: <AlignHorizontalIcon />,
    ALIGN_RIGHT: <AlignRightIcon />,
    ALIGN_TOP: <AlignTopIcon />,
    ALIGN_CENTER: <AlignCenterIcon />,
    ALIGN_BOTTOM: <AlignBottomIcon />,
};

type TypeActions =
    | "ALIGN_LEFT"
    | "ALIGN_HORIZONTAL"
    | "ALIGN_RIGHT"
    | "ALIGN_TOP"
    | "ALIGN_CENTER"
    | "ALIGN_BOTTOM";
interface IAlignmentOptions {
    title: string;
    action: TypeActions;
    icon: ReactNode;
}

const alignmentOptions: IAlignmentOptions[] = [
    { title: "למעלה", action: "ALIGN_TOP", icon: ActionIcons.ALIGN_TOP },
    { title: "שמאל", action: "ALIGN_LEFT", icon: ActionIcons.ALIGN_LEFT },
    { title: "ימין", action: "ALIGN_RIGHT", icon: ActionIcons.ALIGN_RIGHT },
    { title: "למטה", action: "ALIGN_BOTTOM", icon: ActionIcons.ALIGN_BOTTOM },
    { title: "למרכז", action: "ALIGN_CENTER", icon: ActionIcons.ALIGN_CENTER },
    {
        title: "לאמצע",
        action: "ALIGN_HORIZONTAL",
        icon: ActionIcons.ALIGN_HORIZONTAL,
    },
];

const Alignment = ({ innerMenu, toggleInnerMenu }: IToggleInnerMenu) => {
    const { page }: any = useContext(PageContext);
    const { editorData, updateItems }: any = useContext(EditorDataContext);
    const items = editorData[page].items;
    const pageWidth = CM2PX(editorData[page].width);
    const pageHeight = CM2PX(editorData[page].height);
    const bleed = editorData[page].bleed ? CM2PX(editorData[page].bleed) : 0;
    const snapData = useAppSelector((state) => state.snapPoints);
    const { pages: snapPoints } = snapData;
    const snapPointsPage = snapPoints[page];
    const handleSnapPoints = useSnapPoints(page, snapPointsPage);

    const handleOptionsClick = (action: TypeActions) => {
        updateItems(
            items.map((item: IEditorDataPageItemText) => {
                const border = isContentBox(item) ? borderBox : -borderBox;
                if (item.focus) {
                    switch (action) {
                        case "ALIGN_TOP":
                            item.top = 0 + bleed;
                            break;

                        case "ALIGN_LEFT":
                            item.left = 0 + bleed;
                            break;

                        case "ALIGN_RIGHT":
                            item.left = pageWidth - item.width - bleed - border;
                            break;

                        case "ALIGN_CENTER":
                            item.left = pageWidth / 2 - item.width / 2;
                            break;

                        case "ALIGN_HORIZONTAL":
                            item.left = pageWidth / 2 - item.width / 2;
                            item.top = pageHeight / 2 - item.height / 2;
                            break;

                        case "ALIGN_BOTTOM":
                            item.top =
                                pageHeight - item.height - bleed - border;
                            break;
                    }

                    handleSnapPoints(item, item);
                }
                return item;
            }),
        );
    };

    const handleClick = () =>
        toggleInnerMenu(
            innerMenu === EInnerMenu.ALIGNMENT ? false : EInnerMenu.ALIGNMENT,
        );

    return (
        <ToolWithOptions
            id="alignment"
            icon={<AlignRightIcon />}
            toolTipText="יישור"
            handleClick={handleClick}
            isInnerMenuOpen={innerMenu === EInnerMenu.ALIGNMENT}
            handleClickOptions={handleOptionsClick}
            handleDropDownOnClose={() => toggleInnerMenu(false)}
            options={alignmentOptions}
        />
    );
};

export default Alignment;
