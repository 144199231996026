import React, { useContext, useEffect, useMemo, useState } from "react";
import {
    StyledSideBarContainer,
    StyledSideBarSubTitle,
} from "../../../style-components/styleComponents";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import {
    EStockImagesStatus,
    fetchStockImages,
} from "../../../../redux/features/stockImagesSlice";
import { ImagesState } from "../elements/FoldElements";
import LoadingSnake from "../../../loading-snake/loading-snake";
import Grid from "../../../Grid/GridContainer";
import { IEditorDataItem } from "../../../../interfaces/editor-data.interface";
import {
    EUpdateEditorActions,
    EditorDataContext,
} from "../../../../context/EditorDataProvider";
import { EEditorDataItemTypes } from "../../../../enums/editor-data-item-type.enum";
import { PageContext } from "../../../../context/PageProvider";
import useIsMobile from "../../../../hooks/useIsMobile";
import { FoldMenuContext } from "../../../../context/FoldMenuProvider";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ArrowButton from "../../../buttons/ArrowButton";

const StyledWrap = styled(StyledSideBarContainer)`
    margin-top: var(--large-gap);
`;

export const styledBackgroundImages = `
  width: 65px;
  height: 65px;
  display: flex;
  background-color: var(--background-text-box);
  justify-content: center;
  align-items: center;
  border-radius: var(--main-border-radius);
  overflow: hidden; 
  cursor: pointer; 
`;

const StyledImageContainer = styled.div`
    ${styledBackgroundImages}
`;

const BackgroundImages = () => {
    const { images, status } = useAppSelector((state) => state.stockImages) as {
        images: ImagesState;
        status: EStockImagesStatus;
    };
    const { page }: any = useContext(PageContext);
    const { editorData, updateEditorData }: any = useContext(EditorDataContext);
    const items = editorData[page].items;
    const bgItem = useMemo(
        () =>
            items.find(
                (item: IEditorDataItem) =>
                    item.type === EEditorDataItemTypes.BACKGROUND,
            ),
        [items],
    );
    const { setOpenFoldMenu }: any = useContext(FoldMenuContext);
    const isMobile = useIsMobile();
    const [visibleCount, setVisibleCount] = useState(12);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (status === EStockImagesStatus.IDLE) {
            dispatch(fetchStockImages());
        }
    }, [status, dispatch]);

    if (status === EStockImagesStatus.LOADING) {
        return (
            <>
                <LoadingSnake />
            </>
        );
    }

    const handleClick = (src: string) => {
        if (bgItem) {
            bgItem.backgroundSrc = src;
            updateEditorData(bgItem, EUpdateEditorActions.UPDATE_ITEM);
            if (isMobile) {
                setOpenFoldMenu(false);
            }
        }
    };

    const handleLoadMore = () => {
        setVisibleCount(images.background.images.length);
    };

    return (
        <StyledWrap>
            <StyledSideBarSubTitle>כל הרקעים</StyledSideBarSubTitle>
            <Grid itemsPerRow={4}>
                {images.background &&
                    images.background.images
                        .slice(0, visibleCount)
                        .map((src, index) => (
                            <StyledImageContainer
                                key={`backgrounds_img_${index}`}
                                onClick={() => handleClick(src)}
                            >
                                <LazyLoadImage
                                    src={src}
                                    width="100%"
                                    height="100%"
                                    style={{ objectFit: "cover" }}
                                />
                            </StyledImageContainer>
                        ))}
            </Grid>
            {images.background &&
                visibleCount < images.background.images.length && (
                    <ArrowButton handleOnclick={handleLoadMore}>
                        הצג הכל
                    </ArrowButton>
                )}
        </StyledWrap>
    );
};

export default React.memo(BackgroundImages);
