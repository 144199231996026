import { useAppSelector } from "../redux/hook";
import { createAxiosInstance } from "../utils/axios/axiosConfig";

const useCustomAxios = () => {
    const brandConfig = useAppSelector(
        (state) => state.brandConfig.brandConfig,
    );
    return createAxiosInstance(brandConfig.generatePdf);
};

export default useCustomAxios;
