import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as VIcon } from "../../assets/icons/v.svg";
import { ResetButton } from "../buttons/buttons";
import DropdownMenu from "../dropdown-menu/DropdownMenu";

const StyledSelect = styled.div<{ direction: string }>`
    position: relative;
    width: 100px;
    font-size: var(--small-font-size);
    border: 1px solid var(--main-border-color);
    height: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: var(--main-border-radius);
    direction: ${({ direction }) => direction};
`;

interface IStyledSelected {
    isOpen: boolean;
}
const StyledSelected = styled.div<IStyledSelected>`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    background-color: ${({ isOpen }) =>
        isOpen ? "var(--hover-color)" : "transparent"};
    border-right: 1px solid var(--main-border-color);
    border-left: 1px solid var(--main-border-color);
`;
const StyledInput = styled.input`
    background-color: transparent;
    width: 100%;
    border: none;
    text-align: center;
    outline: none;
    -moz-appearance: textfield;
    font-weight: 4000;

    appearance: none;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

const StyledButton = styled(ResetButton)`
    padding: 10px;
`;
const StyledList = styled.ul`
    cursor: pointer;
    position: absolute;
    right: 0;
    width: 100px;
    top: 0;
    list-style: none;
    background-color: var(--white);
    border-radius: var(--main-border-radius);
    border: solid 1px var(-main-border-color);
    max-height: 300px;
    overflow: auto;
    font-size: var(--small-font-size);
    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: var(--small-gap);
        height: 30px;
        &:hover,
        &.active {
            background-color: var(--hover-color);
        }
    }
`;

export interface IOptionCustomSelect {
    value: string;
    label: string;
}

interface ICustomSelectProps {
    options: IOptionCustomSelect[];
    onSelect: (value: string) => void;
    direction?: "rtl" | "ltr";
    defaultValue?: string;
    max?: number;
    min?: number;
}

const CustomInputNumber = ({
    options,
    onSelect,
    defaultValue,
    direction = "ltr",
    max = Infinity,
    min = 0,
}: ICustomSelectProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(defaultValue || "");
    const selectRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (defaultValue !== selectedValue) {
            setSelectedValue(defaultValue || "");
        }
    }, [defaultValue]);

    useEffect(() => {
        if (isOpen && inputRef.current) {
            inputRef.current.select();
        }
    }, [isOpen]);

    const handleSelect = (value: string, label: string) => {
        onSelect(value);
        setSelectedValue(label);
        setIsOpen(false);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            const { value } = event.currentTarget;
            onSelect(value);
            const matchingOption = options.find(
                (option) => option.value === value,
            );
            if (matchingOption) {
                setSelectedValue(matchingOption.label);
            }
            setIsOpen(false);
        }
    };
    const increment = () => {
        const newValue = parseInt(selectedValue) + 1;
        setSelectedValue(newValue.toString());
        onSelect(newValue.toString());
    };

    const decrement = () => {
        const newValue = parseInt(selectedValue) - 1;
        setSelectedValue(newValue.toString());
        onSelect(newValue.toString());
    };

    return (
        <StyledSelect direction={direction} ref={selectRef}>
            <StyledButton onClick={increment}>+</StyledButton>
            <StyledSelected isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
                <StyledInput
                    ref={inputRef}
                    type="number"
                    onKeyDown={handleKeyDown}
                    value={selectedValue}
                    max={max}
                    min={min}
                    onChange={(e) => setSelectedValue(e.target.value)}
                />
            </StyledSelected>
            <StyledButton onClick={decrement}>-</StyledButton>
            {isOpen && (
                <DropdownMenu triggerRef={selectRef}>
                    <StyledList className="options-list">
                        {options.map((option) => (
                            <li
                                className={
                                    selectedValue === option.value ?
                                        "active"
                                    :   ""
                                }
                                key={option.value}
                                onClick={() =>
                                    handleSelect(option.value, option.label)
                                }
                            >
                                {option.label}
                                {selectedValue === option.value && <VIcon />}
                            </li>
                        ))}
                    </StyledList>
                </DropdownMenu>
            )}
        </StyledSelect>
    );
};

export default CustomInputNumber;
