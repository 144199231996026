import React, { useCallback, useContext, useEffect, useRef } from "react";
import ToolbarBtn from "../../buttons/ToolbarBtn";
import { PageContext } from "../../../context/PageProvider";
import { EditorDataContext } from "../../../context/EditorDataProvider";
import { ReactComponent as UnderLineIcon } from "../../../assets/icons/underline.svg";
import Tooltip, { ETooltipPlace } from "../../tool-tip/Tooltip";
import { addFocusToTextBox } from "../../../utils/utils";
import {
    IEditorDataItem,
    IEditorDataPageItemText,
} from "../../../interfaces/editor-data.interface";
import { StyledTitleToolBar } from "../../style-components/styleComponents";
import useIsMobile from "../../../hooks/useIsMobile";

const UnderLine = () => {
    const { page }: any = useContext(PageContext);
    const { editorData, updateItems }: any = useContext(EditorDataContext);
    const items = editorData[page].items;
    const focusedItem = items.find((item: IEditorDataItem) => item.focus);
    const isMobile = useIsMobile();

    const handleKeyPress = useCallback(
        (e) => {
            if (e.keyCode === 85 && (e.ctrlKey || e.metaKey)) {
                e.preventDefault();
                handleOnUnderline();
            }
        },
        [editorData],
    );

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPress);
        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [handleKeyPress]);

    function handleOnUnderline() {
        updateItems(
            items.map((item: IEditorDataPageItemText) => {
                if (item.focus) item.underline = !item.underline;
                return item;
            }),
        );
        if (!isMobile) {
            addFocusToTextBox();
        }
    }

    const ref: React.RefObject<any> = useRef();
    const txt = "קו תחתון";
    return (
        <>
            <ToolbarBtn
                ref={ref}
                data-tip
                data-for="underLineTooltip"
                className={focusedItem.underline ? "active" : ""}
                onClick={handleOnUnderline}
            >
                <UnderLineIcon />
                <StyledTitleToolBar className="hide-desktop">
                    {txt}
                </StyledTitleToolBar>
            </ToolbarBtn>

            <Tooltip
                place={ETooltipPlace.BOTTOM}
                id="underLineTooltip"
                elemRef={ref}
            >
                {txt}
            </Tooltip>
        </>
    );
};

export default UnderLine;
