import React, { useContext, useRef, useState } from "react";
import ToolbarBtn from "../../buttons/ToolbarBtn";
import Tooltip, { ETooltipPlace } from "../../tool-tip/Tooltip";
import styled from "styled-components";
import { PageContext } from "../../../context/PageProvider";
import { EditorDataContext } from "../../../context/EditorDataProvider";
import {
    IEditorDataItem,
    IEditorDataPageItemSvg,
} from "../../../interfaces/editor-data.interface";
import { EMenuType } from "../../../enums/menu-type.enum";
import { FoldMenuContext } from "../../../context/FoldMenuProvider";
import updateStyleSvg from "../../../utils/svg/updateStyleSvg";
import useIsMobile from "../../../hooks/useIsMobile";
import { StyledTitleToolBar } from "../../style-components/styleComponents";
import ChosenColor from "../../colors/ChosenColors";

interface IBackgroundStyled {
    backgroundColor: string | undefined;
}

const BackgroundStyled = styled.div<IBackgroundStyled>`
    width: 14px;
    height: 14px;
    border: 1px solid black;
    position: relative;
    ${({ backgroundColor }) =>
        backgroundColor && backgroundColor !== "none" ?
            `background-color:${backgroundColor};`
        :   `
        &:after{
            content:'';
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 2px;
            background-color:red;
            transform: translateY(-50%) rotate(-45deg);
        }
    `}
`;

const BackgroundColor = () => {
    const { page }: any = useContext(PageContext);
    const { editorData, updateItems }: any = useContext(EditorDataContext);
    const items = editorData[page].items;
    const focusedItem = items.find((item: IEditorDataItem) => item.focus);
    const printColor =
        editorData[page].printColor ? editorData[page].printColor : null;
    const { setOpenFoldMenu, menuType }: any = useContext(FoldMenuContext);
    const { backgroundColor } = focusedItem;
    const ref: React.RefObject<any> = useRef();
    const isMobile = useIsMobile();
    const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);
    return (
        <>
            <ToolbarBtn
                ref={ref}
                data-tip
                data-for="backGroundTooltip"
                onClick={(e) => {
                    if (printColor) {
                        const color =
                            backgroundColor === printColor ? "none" : (
                                printColor
                            );
                        updateItems(
                            items.map((item: IEditorDataPageItemSvg) => {
                                const { svg, focus } = item;
                                if (focus) {
                                    item.backgroundColor = color;
                                    if (svg) {
                                        const { borderWidth: strokeWidth } =
                                            item;
                                        const fill = color;
                                        const stroke =
                                            item.borderColor ?
                                                item.borderColor
                                            :   "none";
                                        item.svg = updateStyleSvg(svg, {
                                            strokeWidth,
                                            stroke,
                                            fill,
                                        });
                                    }
                                }
                                return item;
                            }),
                        );

                        return true;
                    }
                    if (isMobile) {
                        setIsOpenDropdown(true);
                    } else {
                        setOpenFoldMenu(true, EMenuType.BACKGROUND_COLOR);
                    }
                    e.stopPropagation();
                }}
            >
                <BackgroundStyled backgroundColor={backgroundColor} />
                <StyledTitleToolBar className="hide-desktop">
                    צבע
                </StyledTitleToolBar>
            </ToolbarBtn>
            <Tooltip
                place={ETooltipPlace.BOTTOM}
                id="backGroundTooltip"
                elemRef={ref}
            >
                צבע רקע
            </Tooltip>
            {isMobile && isOpenDropdown && (
                <ChosenColor onClose={() => setIsOpenDropdown(false)} />
            )}
        </>
    );
};

export default BackgroundColor;
