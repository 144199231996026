import React, { useMemo } from "react";
import { ISnapPointElement } from "../../redux/features/snapPointsSlice";
import {
    EAxis,
    getAlignmentWithElement,
} from "../../utils/snaps/alignmentStrategies";

interface ISnapPointsElementProps {
    snapPoints: Record<string, ISnapPointElement>;
    currentId: string | undefined;
    border: number;
}
const SnapPointsElement = ({
    snapPoints,
    currentId,
    border,
}: ISnapPointsElementProps) => {
    const snapPointsArray = Object.values(snapPoints);
    if (!currentId || snapPointsArray.length <= 1) return null;

    const currentElement = snapPoints[currentId];
    if (!currentElement) return null;

    const borderStyle = "1px dashed var(--snap-color)";

    const points = useMemo(() => {
        const newPoints = new Set<{
            top: number;
            left?: number;
            right?: number;
            width: number;
            height: number;
            borderTop?: string;
            borderLeft?: string;
        }>();

        snapPointsArray.forEach((elem) => {
            if (elem.id === currentId) return;

            const alignments = getAlignmentWithElement(
                currentElement,
                0,
                border,
            );

            for (const { condition, positionSnap, axis } of alignments) {
                if (condition(elem)) {
                    const alignment = positionSnap(elem);
                    const positionIsLeft = elem.left < currentElement.left;
                    const positionIsTop = elem.top < currentElement.top;

                    if (axis === EAxis.Y) {
                        newPoints.add({
                            top: alignment,
                            width:
                                positionIsLeft ?
                                    currentElement.right - elem.left
                                :   elem.right - currentElement.left,
                            left:
                                positionIsLeft ?
                                    elem.left
                                :   currentElement.left,
                            height: 1,
                            borderTop: borderStyle,
                        });
                    } else if (axis === EAxis.X) {
                        newPoints.add({
                            top: positionIsTop ? elem.top : currentElement.top,
                            left: alignment,
                            width: 1,
                            height:
                                positionIsTop ?
                                    currentElement.bottom - elem.top
                                :   elem.bottom - currentElement.top,
                            borderLeft: borderStyle,
                        });
                    }
                }
            }
        });

        return Array.from(newPoints);
    }, [snapPoints, currentId, currentElement]);

    return (
        <>
            {points.map((point, index: number) => (
                <div
                    key={`snap_${index}`}
                    className="snap-point"
                    style={{
                        position: "absolute",
                        backgroundColor: "transparent",
                        pointerEvents: "none",
                        zIndex: 100,
                        ...point,
                    }}
                />
            ))}
        </>
    );
};

export default React.memo(SnapPointsElement, (prevProps, nextProps) => {
    return prevProps.snapPoints === nextProps.snapPoints;
});
