import styled from "styled-components";
import { ResetButton, disabledBtn } from "./buttons";
import { device } from "../../assets/styles/global";

const ToolbarBtn = styled(ResetButton)`
    display: flex;
    align-items: center;
    padding: var(--small-gap);
    &:hover {
        background-color: var(--hover-color);
    }
    &.active {
        background-color: var(--hover-color);
    }
    ${disabledBtn}
    @media (${device.mobile}) {
        display: flex;
        flex-direction: column;
        gap: var(--extra-small-gap);
    }
`;
export default ToolbarBtn;
