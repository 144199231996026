import React, { useCallback, useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import { HistoryContext } from "../../../context/HistoryProvider";
import { EditorDataContext } from "../../../context/EditorDataProvider";
import { PhotosInCanvasContext } from "../../../context/PhotosInCanvasProvider";
import { parse } from "zipson";
import Tooltip, { ETooltipPlace } from "../../tool-tip/Tooltip";
import { ERedoAndUndoAction } from "../../../enums/redo-and-undo.enum";
import { EEditorDataItemTypes } from "../../../enums/editor-data-item-type.enum";
import { IEditorDataPage } from "../../../interfaces/editor-data.interface";
import { PageContext } from "../../../context/PageProvider";

export const ButtonStyled = styled.button`
    border: none;
    background: transparent;
    padding: 0;
    color: #000;
    cursor: pointer;
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

interface IRedoAndUndo {
    children: React.ReactChild;
    disabled: boolean;
    action: ERedoAndUndoAction;
}
export const RedoAndUndo = ({ children, disabled, action }: IRedoAndUndo) => {
    const { page, setPage }: any = useContext(PageContext);
    const { initData, editorData }: any = useContext(EditorDataContext);
    const {
        history,
        numState,
        setNumState,
        setStateStateRedo,
        numStateRedo,
    }: any = useContext(HistoryContext);
    const { photos }: any = useContext(PhotosInCanvasContext);
    const ref: React.RefObject<any> = useRef();

    const handleKeyPress = useCallback(
        (e) => {
            if ((e.ctrlKey || e.metaKey) && e.which === 90 && !e.shiftKey) {
                e.preventDefault();
                if (numState !== 1) {
                    handleOnclick(ERedoAndUndoAction.UNDO);
                }
            }
            if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.which === 90) {
                e.preventDefault();
                if (numStateRedo !== 0) {
                    handleOnclick(ERedoAndUndoAction.REDO);
                }
            }
        },
        [editorData],
    );

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPress);
        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [handleKeyPress]);

    function handleOnclick(action: ERedoAndUndoAction) {
        const newNumState = action === "undo" ? numState - 1 : numState + 1;
        setStateStateRedo((prev: number) =>
            action === "undo" ? prev + 1 : prev - 1,
        );

        const index = newNumState - 1;
        if (!history[index]) {
            return false;
        }

        const { pageHistory, data } = history[index];
        let stateParse = parse(data);
        setNumState(newNumState);
        //Update image.
        stateParse = stateParse.map((page: IEditorDataPage) => {
            page.items.map((item) => {
                if (item.type === EEditorDataItemTypes.IMAGE) {
                    const image = photos.find(
                        (photo: any) => photo.id === item.id,
                    );
                    if (image && !item.base64) {
                        item.base64 = image.base64;
                    }
                }
                return item;
            });
            return page;
        });

        initData(stateParse);
        if (pageHistory !== page) {
            setPage(pageHistory);
        }
    }
    return (
        <>
            <div ref={ref} data-tip data-for={`${action}Tooltip`}>
                <ButtonStyled
                    disabled={disabled}
                    onClick={() => {
                        handleOnclick(action);
                    }}
                >
                    {children}
                </ButtonStyled>
            </div>
            <Tooltip
                place={ETooltipPlace.BOTTOM}
                id={`${action}Tooltip`}
                elemRef={ref}
            >
                <>{action === ERedoAndUndoAction.UNDO ? "בטל" : "שחזר"}</>
            </Tooltip>
        </>
    );
};

export default RedoAndUndo;
