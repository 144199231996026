import React, { useCallback, useContext, useEffect, useRef } from "react";
import ToolbarBtn from "../../buttons/ToolbarBtn";
import { PageContext } from "../../../context/PageProvider";
import { EditorDataContext } from "../../../context/EditorDataProvider";
import { addFocusToTextBox } from "../../../utils/utils";
import { ReactComponent as BoldIcon } from "../../../assets/icons/bold.svg";
import Tooltip, { ETooltipPlace } from "../../tool-tip/Tooltip";
import { EFontStyle, EFontWeight } from "../../../enums/font-style.enum";
import {
    IEditorDataItem,
    IEditorDataPageItemText,
} from "../../../interfaces/editor-data.interface";
import { StyledTitleToolBar } from "../../style-components/styleComponents";
import useIsMobile from "../../../hooks/useIsMobile";
import { useTranslation } from "react-i18next";

const Bold = () => {
    const { page }: any = useContext(PageContext);
    const { editorData, updateItems }: any = useContext(EditorDataContext);
    const items = editorData[page].items;
    const focusedItem = items.find((item: IEditorDataItem) => item.focus);
    const isMobile = useIsMobile();
    const { t } = useTranslation();

    const handleKeyPress = useCallback(
        (e) => {
            if (e.keyCode === 66 && (e.ctrlKey || e.metaKey)) {
                e.preventDefault();
                handleOnBold();
            }
        },
        [editorData],
    );

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPress);
        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [handleKeyPress]);

    function handleOnBold() {
        updateItems(
            items.map((item: IEditorDataPageItemText) => {
                if (
                    item.focus &&
                    EFontWeight.EXTRA_BOLD_WEIGHT !== focusedItem.fontWeight
                ) {
                    //Remove Bold
                    if (EFontWeight.SEMI_BOLD_WEIGHT === item.fontWeight) {
                        item.fontWeight = EFontWeight.REGULAR_WEIGHT;
                        item.fontStyle = EFontStyle.REGULAR;
                    }
                    //Add Bold.
                    else {
                        item.fontWeight = EFontWeight.SEMI_BOLD_WEIGHT;
                        item.fontStyle = EFontStyle.SEMI_BOLD;
                    }
                }
                return item;
            }),
        );
        if (!isMobile) {
            addFocusToTextBox();
        }
    }
    const ref: React.RefObject<any> = useRef();
    const txt = t("bold");
    return (
        <>
            <ToolbarBtn
                ref={ref}
                data-tip
                data-for="boldTooltip"
                disabled={
                    EFontWeight.EXTRA_BOLD_WEIGHT === focusedItem.fontWeight
                }
                className={
                    EFontWeight.SEMI_BOLD_WEIGHT === focusedItem.fontWeight ?
                        "active"
                    :   ""
                }
                onClick={handleOnBold}
            >
                <BoldIcon />
                <StyledTitleToolBar className="hide-desktop">
                    {txt}
                </StyledTitleToolBar>
            </ToolbarBtn>
            <Tooltip
                place={ETooltipPlace.BOTTOM}
                id="boldTooltip"
                elemRef={ref}
            >
                {txt}
            </Tooltip>
        </>
    );
};

export default Bold;
