import { useCallback, useEffect, useState } from "react";
import { openDB } from "idb";

export enum EIndexDbSettings {
    DB_NAME = "EditorDb",
    VERSION = 3,
    LOCAL_USE_PHOTOS = "localUsePhotos",
    EDITOR_DATA = "editorData",
}

interface IObjectStores {
    name: any;
    options: {
        keyPath: string;
    };
}

const objectStores: IObjectStores[] = [
    { name: EIndexDbSettings.LOCAL_USE_PHOTOS, options: { keyPath: "id" } },
    { name: EIndexDbSettings.EDITOR_DATA, options: { keyPath: "id" } },
];

function useIndexedDB(storeName: string, initialState: any) {
    const [data, setData] = useState<any>(initialState);

    useEffect(() => {
        async function initDB() {
            try {
                const db = await openDB(
                    EIndexDbSettings.DB_NAME,
                    EIndexDbSettings.VERSION,
                    {
                        upgrade(db) {
                            objectStores.forEach(({ name, options }) => {
                                if (!db.objectStoreNames.contains(name)) {
                                    db.createObjectStore(name, options);
                                }
                            });
                        },
                    },
                );
            } catch (error) {
                console.error("Error initializing DB:", error);
            }
        }
        initDB();
    }, []);

    const refreshData = useCallback(async () => {
        try {
            const db = await openDB(
                EIndexDbSettings.DB_NAME,
                EIndexDbSettings.VERSION,
            );
            if (db.objectStoreNames.contains(storeName)) {
                const tx = db.transaction(storeName, "readonly");
                const store = tx.objectStore(storeName);
                const allData = await store.getAll();
                setData(allData);
            }
        } catch (error) {
            console.error("Error fetching data from IndexedDB:", error);
        }
    }, [storeName]);

    useEffect(() => {
        refreshData();
    }, [refreshData]);

    const addData = async (newData: any) => {
        try {
            const db = await openDB(
                EIndexDbSettings.DB_NAME,
                EIndexDbSettings.VERSION,
            );
            const transaction = db.transaction(storeName, "readwrite");
            const store = transaction.objectStore(storeName);
            await store.add(newData);
            setData((prev: any) => [...prev, newData]);
        } catch (error) {
            console.error("Error adding data to IndexedDB:", error);
        }
    };

    const deleteData = async (id: string) => {
        try {
            const db = await openDB(
                EIndexDbSettings.DB_NAME,
                EIndexDbSettings.VERSION,
            );
            const transaction = db.transaction(storeName, "readwrite");
            const store = transaction.objectStore(storeName);
            await store.delete(id);
            setData((prev: any) => prev.filter((item: any) => item.id !== id));
        } catch (error) {
            console.error("Error deleting data from IndexedDB:", error);
        }
    };

    const clearData = useCallback(async () => {
        try {
            const db = await openDB(
                EIndexDbSettings.DB_NAME,
                EIndexDbSettings.VERSION,
            );
            const transaction = db.transaction(storeName, "readwrite");
            const store = transaction.objectStore(storeName);
            await store.clear();
            setData([]);
        } catch (error) {
            console.error("Error clearing IndexedDB store:", error);
        }
    }, [storeName]);

    const getDataByKey = useCallback(
        async (key: string) => {
            try {
                const db = await openDB(
                    EIndexDbSettings.DB_NAME,
                    EIndexDbSettings.VERSION,
                );
                return await db.get(storeName, key);
            } catch (error) {
                console.error(
                    "Error getting data by key from IndexedDB:",
                    error,
                );
                return null;
            }
        },
        [storeName],
    );

    const updateData = useCallback(
        async (newData: any) => {
            try {
                const db = await openDB(
                    EIndexDbSettings.DB_NAME,
                    EIndexDbSettings.VERSION,
                );
                const transaction = db.transaction(storeName, "readwrite");
                const store = transaction.objectStore(storeName);
                await store.put(newData);
                setData((prev: any) =>
                    prev.map((item: any) =>
                        item.id === newData.id ? { ...item, ...newData } : item,
                    ),
                );
            } catch (error) {
                console.error("Error updating data in IndexedDB:", error);
            }
        },
        [storeName],
    );

    return {
        data,
        addData,
        deleteData,
        clearData,
        getDataByKey,
        updateData,
        refreshData,
    };
}

export default useIndexedDB;
