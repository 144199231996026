import React, { useContext } from "react";
import styled from "styled-components";
import SideBarMenuItem from "../sideBar/SideBarMenuItem";
import { TextIcon } from "../svgs/Text";
import { PhotoIcon } from "../svgs/Photo";
import { EMenuType } from "../../enums/menu-type.enum";
import { ElementsIcon } from "../svgs/ElementsIcon";
import { PageContext } from "../../context/PageProvider";
import { EditorDataContext } from "../../context/EditorDataProvider";
import BackgroundIcon from "../svgs/BackgroundIcon";
import { device } from "../../assets/styles/global";
import { ResetButton } from "../buttons/buttons";
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/arrow_right.svg";
import { FoldMenuContext } from "../../context/FoldMenuProvider";

export interface ISideBarMenuItems {
    menuName: EMenuType;
    iconType: React.ReactElement;
    show: boolean;
}

const ContainerStyled = styled.div`
    position: relative;
    background-color: var(--white);
    box-shadow: var(--main-shadow);
    width: var(--side-bar-width);
    height: 100%;
    z-index: 3;
    @media (${device.mobile}) {
        display: flex;
        width: 100%;
        align-items: center;
        box-shadow: 0 -6px 20px 0 rgba(0, 0, 0, 0.1);
        overflow: hidden;
        z-index: 5;
    }
`;

const ExitIconStyled = styled(ResetButton)`
    width: 35px;
    height: 35px;
    background-color: var(--white);
    box-shadow: var(--main-shadow);
    border-radius: 100%;
    position: absolute;
    left: -25px;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;
`;

const SideBarMenu = (props: React.ComponentProps<any>) => {
    const { page }: any = useContext(PageContext);
    const { editorData }: any = useContext(EditorDataContext);
    const { backgroundColorCanvas } = editorData[page];
    const { setOpenFoldMenu, menuType }: any = useContext(FoldMenuContext);

    const sideBarMenuItems: Array<ISideBarMenuItems> = [
        {
            menuName: EMenuType.ELEMENTS,
            iconType: <ElementsIcon />,
            show: true,
        },
        {
            menuName: EMenuType.BACKGROUNDS,
            iconType: <BackgroundIcon />,
            show: backgroundColorCanvas,
        },
        {
            menuName: EMenuType.TEXT,
            iconType: <TextIcon />,
            show: true,
        },
        {
            menuName: EMenuType.PHOTO,
            iconType: <PhotoIcon />,
            show: true,
        },
    ];

    return (
        <div className="relative">
            <ContainerStyled {...props}>
                {sideBarMenuItems.map((itemProps, index) =>
                    itemProps.show ?
                        <SideBarMenuItem
                            {...itemProps}
                            key={`sideBarItem_${index}`}
                        />
                    :   "",
                )}
            </ContainerStyled>
            {menuType && (
                <ExitIconStyled
                    onClick={() => setOpenFoldMenu(false)}
                    className="hide-mobile"
                >
                    <ArrowRightIcon />
                </ExitIconStyled>
            )}
        </div>
    );
};

export default SideBarMenu;
