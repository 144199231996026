import React from "react";
import { LoadingSnake } from "./loading-snake";
import { Modal } from "../modal/modal";

export const LoadingTopSnake = () => (
    <Modal>
        <LoadingSnake />
    </Modal>
);
export default LoadingTopSnake;
