import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import ToolBar, { ToolBarHeight } from "../toolbar/ToolBar";
import PlayArea from "./PlayArea";
import { PageContext } from "../../context/PageProvider";
import { EditorDataContext } from "../../context/EditorDataProvider";

import {
    IEditorDataItem,
    IEditorDataPage,
} from "../../interfaces/editor-data.interface";
import { EEditorDataItemTypes } from "../../enums/editor-data-item-type.enum";
import { FoldMenuContext } from "../../context/FoldMenuProvider";
import { device } from "../../assets/styles/global";
import Zoom from "../zoom/Zoom";
import { getInitialPoints } from "../../utils/snaps";
import { useAppDispatch } from "../../redux/hook";
import { setSnapPoints } from "../../redux/features/snapPointsSlice";
import KeyboardHandler from "../KeyboardHandler/KeyboardHandler";

const Container = styled.div`
    background-color: #fafafa;
    display: grid;
    grid-template-rows: 0px 1fr;
    @media (${device.mobile}) {
        grid-template-rows: 1fr;
    }
    overflow: hidden;
`;

interface ToolBarStyled {
    toolBarIsOpen: boolean;
}

const ToolBarStyled = styled.div<ToolBarStyled>`
    position: relative;
    z-index: 1;
    width: 100%;
    height: ${ToolBarHeight}px;
    padding: 0 var(--small-gap);
    display: flex;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    box-sizing: border-box;
    background-color: ${({ toolBarIsOpen }) =>
        toolBarIsOpen ? "var(--white)" : "transparent"};
    &::-webkit-scrollbar {
        height: 4px;
    }

    @media (${device.mobile}) {
        position: absolute;
        bottom: 0px;
        z-index: -1;
        height: var(--mobile-side-bar-height);
        background-color: var(--white);
        ${({ toolBarIsOpen }) =>
            toolBarIsOpen &&
            `
      z-index: 6;
    `}
    }
`;

const Canvas = ({ ...props }) => {
    const { page }: any = useContext(PageContext);
    const { editorData, updateFocusElements, updateEditorData }: any =
        useContext(EditorDataContext);
    const items = editorData[page].items;
    const focusedItem = items.find((item: IEditorDataItem) => item.focus);
    const { setOpenFoldMenu }: any = useContext(FoldMenuContext);
    const isToolbarOpen =
        focusedItem && focusedItem.type !== EEditorDataItemTypes.BACKGROUND;

    const dispatch = useAppDispatch();

    const handleClick = (e: React.MouseEvent) => {
        const focusedItem = items.find((item: IEditorDataItem) => item.focus);
        const elem = e.target as HTMLElement;
        if (
            elem.closest(".editorWidget") ||
            elem.classList.contains("editorWidget") ||
            typeof focusedItem === "undefined"
        ) {
            return false;
        }
        setOpenFoldMenu(false);
        updateFocusElements(null);
    };

    useEffect(() => {
        const initialPoints = getInitialPoints(editorData);
        dispatch(setSnapPoints(initialPoints));
    }, []);

    return (
        <Container {...props}>
            <ToolBarStyled toolBarIsOpen={isToolbarOpen}>
                {isToolbarOpen && <ToolBar focusedItem={focusedItem} />}
            </ToolBarStyled>
            <KeyboardHandler
                page={page}
                focusedItem={focusedItem}
                updateEditorData={updateEditorData}
            />
            <div
                style={{ overflow: "hidden" }}
                id="canvas"
                onClick={handleClick}
            >
                {editorData.map(
                    (pageData: IEditorDataPage, index: number) =>
                        page === index && (
                            <PlayArea
                                numPages={editorData.length}
                                pageData={pageData}
                                key={`page_${index}`}
                            />
                        ),
                )}
            </div>
            <div id="modal_canvas"></div>
            <Zoom />
        </Container>
    );
};

export default Canvas;
