import React, { useContext, useEffect, useRef } from "react";
import PrintArea from "./PrintArea";
import styled from "styled-components";
import { CM2PX, getOppositeScale } from "../../utils/utils";
import { PageContext } from "../../context/PageProvider";
import {
    EditorDataContext,
    EUpdateEditorActions,
} from "../../context/EditorDataProvider";
import { EPaginationActions } from "../../enums/pagination-actions.enum";
import { FontTelloSpan } from "../../assets/Fontello/FontTello";
import { ToolBarHeight } from "../toolbar/ToolBar";
import { CanvasBoundingClientRectContext } from "../../context/CanvasBoundingClientRectProvider";
import { FoldMenuContext } from "../../context/FoldMenuProvider";
import MiniToolbar from "../toolbar/mini-toolbar/MiniToolBar";
import { useAppSelector } from "../../redux/hook";
import useIsMobile from "../../hooks/useIsMobile";
import { IEditorDataPage } from "../../interfaces/editor-data.interface";

const WrapPlayAreaStyled = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    direction: rtl;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

interface ICssCanvasSizes {
    pageScale: number;
    cssCanvasSizes: {
        w: number;
        h: number;
    };
}

const ContainerStyled = styled.div<ICssCanvasSizes>`
    width: ${({ cssCanvasSizes }) => cssCanvasSizes.w + "px"};
    height: ${({ cssCanvasSizes }) => cssCanvasSizes.h + "px"};
    position: relative;
    transform: scale(${({ pageScale }) => pageScale});
    transform-origin: center;
`;

interface IPaginationBtnStyled {
    action: EPaginationActions;
    oppositeScale: number;
}
const PaginationBtnStyled = styled.button<IPaginationBtnStyled>`
    box-shadow:
        0 4px 5px 0 rgb(0 0 0 / 14%),
        0 2px 4px 0 rgb(0 0 0 / 20%);
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 100%;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 50%;
    z-index: 10;
    transform: translate(0, -50%) scale(${({ oppositeScale }) => oppositeScale});
    ${({ action, oppositeScale }) => {
        const position = `-${40 + 20 * oppositeScale}px`;
        return `${
            action === EPaginationActions.NEXT ?
                `left:${position}`
            :   `right:${position}`
        }`;
    }}
`;

interface IPlayArea {
    pageData: IEditorDataPage;
    numPages: number;
}
const PlayArea = ({ pageData, numPages }: IPlayArea) => {
    const { page, setPage }: any = useContext(PageContext);
    const { updateEditorData, updateScale }: any =
        useContext(EditorDataContext);
    const { openFoldMenu }: any = useContext(FoldMenuContext);
    const inputEl: React.RefObject<any> = useRef(null);
    const canvasRef = useRef<HTMLDivElement>(null);
    const { canvasBoundingClientRect, setCanvasBoundingClientRect } =
        useContext(CanvasBoundingClientRectContext);
    const openErrorSection = useAppSelector(
        (state) => state.errorListPerItem.openErrorSection,
    );
    const isMobile = useIsMobile();
    const canvasSize = {
        w: CM2PX(pageData.width),
        h: CM2PX(pageData.height),
    };

    const scale = pageData.scale ? pageData.scale : 1;
    const oppositeScale = getOppositeScale(scale);

    function handleDragOver(e: React.DragEvent) {
        e.preventDefault();
    }

    function handleOnDrop(e: any) {
        if (e.isReplaced) {
            return false;
        }

        updateEditorData(null, EUpdateEditorActions.REMOVE_PLACE_HOLDER);
        let item = e.dataTransfer.getData("editorItem");
        if (item) {
            item = JSON.parse(item);
            let { top, left } = item;
            const jsonOffsetElem = e.dataTransfer.getData("offsetElem");

            if (jsonOffsetElem) {
                const offsetElem = JSON.parse(
                        e.dataTransfer.getData("offsetElem"),
                    ),
                    { offsetYElem, offsetXElem } = offsetElem,
                    elemPrintArea = inputEl.current.querySelector("div"),
                    offsetTopPrintArea = Math.round(
                        elemPrintArea.getBoundingClientRect().y,
                    ),
                    offsetLeftPrintArea = Math.round(
                        elemPrintArea.getBoundingClientRect().x,
                    );
                top =
                    (e.clientY - offsetYElem - offsetTopPrintArea) *
                    oppositeScale;
                left =
                    (e.clientX - offsetXElem - offsetLeftPrintArea) *
                    oppositeScale;
            }

            item = { ...item, top, left };
            updateEditorData(item);
        }
    }

    function handleClickPagination(action: EPaginationActions) {
        const lastPage = numPages - 1;
        const nextPage = page === lastPage ? 0 : page + 1;
        const prevPage = page === 0 ? lastPage : page - 1;
        setPage(action === EPaginationActions.NEXT ? nextPage : prevPage);
    }

    /**
     * Update Global context canvas Bounding ClientRect.
     * */
    useEffect(() => {
        const { current: canvasElem } = canvasRef;
        if (canvasElem && !canvasBoundingClientRect[page]) {
            const rect = canvasElem.getBoundingClientRect();
            setCanvasBoundingClientRect(page, rect);
        }
    }, []);

    /**
     * Adjust Size canvas.
     * */
    useEffect(() => {
        const { current: elemWrapCanvas } = inputEl;
        const canvasBoundingClientRectByPage = canvasBoundingClientRect[page];
        function adjustCanvas() {
            if (elemWrapCanvas && canvasBoundingClientRectByPage) {
                const marginWrapCanvas = isMobile ? 0 : 80;
                let { width: wrapCanvasWidth, height: wrapCanvasHeight } =
                    elemWrapCanvas.getBoundingClientRect();
                wrapCanvasHeight =
                    wrapCanvasHeight - (ToolBarHeight + marginWrapCanvas);
                wrapCanvasWidth = wrapCanvasWidth - (marginWrapCanvas + 90);

                const { height: canvasHeight, width: canvasWidth } =
                    canvasBoundingClientRectByPage;
                const { h: originalCanvasHeight, w: originalCanvasWidth } =
                    canvasSize;
                const newScale = Math.min(
                    wrapCanvasWidth / originalCanvasWidth,
                    wrapCanvasHeight / originalCanvasHeight,
                );
                //Check the if.
                if (
                    canvasHeight >= wrapCanvasHeight ||
                    canvasWidth >= wrapCanvasWidth
                ) {
                    updateScale(newScale);
                }
            }
        }
        adjustCanvas();
        window.addEventListener("resize", adjustCanvas);
        return () => {
            window.removeEventListener("resize", adjustCanvas);
        };
    }, [canvasBoundingClientRect, openFoldMenu, openErrorSection]);

    return (
        <WrapPlayAreaStyled ref={inputEl}>
            <ContainerStyled
                ref={canvasRef}
                pageScale={scale}
                onDragOver={handleDragOver}
                onDrop={handleOnDrop}
                cssCanvasSizes={canvasSize}
            >
                {numPages > 1 && (
                    <PaginationBtnStyled
                        action={EPaginationActions.PREV}
                        oppositeScale={oppositeScale}
                        onClick={() =>
                            handleClickPagination(EPaginationActions.PREV)
                        }
                        type="button"
                    >
                        <FontTelloSpan className="icon-right-open" />
                    </PaginationBtnStyled>
                )}
                <MiniToolbar />
                <div id={`portal_page_${page}`} />
                <PrintArea pageData={pageData} />
                {numPages > 1 && (
                    <PaginationBtnStyled
                        action={EPaginationActions.NEXT}
                        oppositeScale={oppositeScale}
                        onClick={() =>
                            handleClickPagination(EPaginationActions.NEXT)
                        }
                        type="button"
                    >
                        <FontTelloSpan className="icon-left-open" />
                    </PaginationBtnStyled>
                )}
            </ContainerStyled>
        </WrapPlayAreaStyled>
    );
};

export default PlayArea;
