import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IInitialState {
    w: number;
    h: number;
    x: number;
    y: number;
}

const initialState: IInitialState = {
    w: 0,
    h: 0,
    x: 0,
    y: 0,
};

const printAreaClientRectSlice = createSlice({
    initialState,
    name: "printAreaClientRect",
    reducers: {
        setPrintAreaClientRect(state, action: PayloadAction<IInitialState>) {
            return action.payload;
        },
    },
});

export const { setPrintAreaClientRect } = printAreaClientRectSlice.actions;
export default printAreaClientRectSlice.reducer;
