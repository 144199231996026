import React, { createContext, useState } from "react";
import { IContextProviders } from "../interfaces/context-providers.interface";

export interface IPageContext {
    page: number;
    setPage?: React.Dispatch<React.SetStateAction<number>>;
}

export const PageContext = createContext<IPageContext>({
    page: 0,
});

export const PageProvider = ({ children }: IContextProviders) => {
    const [page, setPage] = useState(0);
    const value = { page, setPage };
    return (
        <PageContext.Provider value={value}>{children}</PageContext.Provider>
    );
};
