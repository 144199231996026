import React from "react";
interface IPhotoIcon {
    fill?: string;
}
export const PhotoIcon = ({ fill = "#518eed" }: IPhotoIcon) => {
    return (
        <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.645 16.8125H14.355C14.8742 16.8125 15.3721 16.6063 15.7392 16.2392C16.1063 15.8721 16.3125 15.3742 16.3125 14.855V4.145C16.3125 3.62584 16.1063 3.12794 15.7392 2.76084C15.3721 2.39374 14.8742 2.1875 14.355 2.1875H3.645C3.12584 2.1875 2.62794 2.39374 2.26084 2.76084C1.89374 3.12794 1.6875 3.62584 1.6875 4.145V14.855C1.6875 15.3742 1.89374 15.8721 2.26084 16.2392C2.62794 16.6063 3.12584 16.8125 3.645 16.8125ZM14.355 3.3125C14.5758 3.3125 14.7875 3.40021 14.9437 3.55633C15.0998 3.71246 15.1875 3.92421 15.1875 4.145V14.855C15.1875 15.0758 15.0998 15.2875 14.9437 15.4437C14.7875 15.5998 14.5758 15.6875 14.355 15.6875H3.645C3.42421 15.6875 3.21246 15.5998 3.05633 15.4437C2.90021 15.2875 2.8125 15.0758 2.8125 14.855V4.145C2.8125 3.92421 2.90021 3.71246 3.05633 3.55633C3.21246 3.40021 3.42421 3.3125 3.645 3.3125H14.355Z"
                fill="#1D1D1B"
            />
            <path
                d="M15.7499 14.6071C15.8309 14.6069 15.911 14.5894 15.9847 14.5557C16.0584 14.5221 16.1241 14.4732 16.1774 14.4121C16.2254 14.3556 16.2617 14.2901 16.2843 14.2195C16.3069 14.1489 16.3153 14.0745 16.309 14.0006C16.3027 13.9267 16.2819 13.8548 16.2478 13.789C16.2136 13.7232 16.1667 13.6648 16.1099 13.6171L12.2699 10.3771C11.9832 10.1352 11.6162 10.0094 11.2414 10.0247C10.8665 10.04 10.511 10.1951 10.2449 10.4596L8.42243 12.2821C8.3545 12.3402 8.26806 12.3722 8.17868 12.3722C8.08929 12.3722 8.00285 12.3402 7.93493 12.2821L6.16493 10.9996C5.90264 10.7977 5.58093 10.6882 5.24993 10.6882C4.91892 10.6882 4.59721 10.7977 4.33493 10.9996L1.89743 12.9421C1.83932 12.9884 1.79089 13.0457 1.75492 13.1107C1.71895 13.1757 1.69613 13.2472 1.68778 13.321C1.67942 13.3948 1.68568 13.4696 1.70621 13.541C1.72674 13.6124 1.76113 13.679 1.80743 13.7371C1.85372 13.7953 1.911 13.8437 1.976 13.8797C2.04101 13.9156 2.11246 13.9384 2.18629 13.9468C2.26011 13.9552 2.33485 13.9489 2.40626 13.9284C2.47766 13.9078 2.54431 13.8734 2.60242 13.8271L5.03242 11.8771C5.098 11.8267 5.17842 11.7993 5.26118 11.7993C5.34393 11.7993 5.42435 11.8267 5.48993 11.8771L7.25993 13.2496C7.54803 13.466 7.90438 13.5714 8.26381 13.5466C8.62324 13.5218 8.96175 13.3685 9.21743 13.1146L11.0399 11.2546C11.1088 11.1925 11.1984 11.158 11.2912 11.158C11.384 11.158 11.4735 11.1925 11.5424 11.2546L15.3899 14.4796C15.4904 14.5646 15.6184 14.6099 15.7499 14.6071Z"
                fill="#1D1D1B"
            />
            <path
                d="M6.75 9.3125C7.15792 9.3125 7.55669 9.19154 7.89586 8.96491C8.23504 8.73828 8.49939 8.41616 8.6555 8.03929C8.81161 7.66241 8.85245 7.24771 8.77287 6.84763C8.69329 6.44754 8.49685 6.08004 8.20841 5.79159C7.91996 5.50315 7.55246 5.30671 7.15237 5.22713C6.75229 5.14755 6.33759 5.18839 5.96071 5.3445C5.58384 5.50061 5.26172 5.76496 5.03509 6.10414C4.80846 6.44331 4.6875 6.84208 4.6875 7.25C4.6875 7.79701 4.9048 8.32161 5.29159 8.70841C5.67838 9.0952 6.20299 9.3125 6.75 9.3125ZM6.75 6.3125C6.93542 6.3125 7.11667 6.36748 7.27085 6.4705C7.42502 6.57351 7.54518 6.71993 7.61614 6.89123C7.68709 7.06254 7.70566 7.25104 7.66949 7.4329C7.63331 7.61476 7.54402 7.7818 7.41291 7.91291C7.2818 8.04403 7.11475 8.13331 6.9329 8.16949C6.75104 8.20566 6.56254 8.18709 6.39123 8.11614C6.21993 8.04518 6.07351 7.92502 5.9705 7.77085C5.86748 7.61668 5.8125 7.43542 5.8125 7.25C5.8125 7.00136 5.91127 6.7629 6.08709 6.58709C6.2629 6.41127 6.50136 6.3125 6.75 6.3125Z"
                fill="#1D1D1B"
            />
        </svg>
    );
};
