/* eslint-disable */
import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf";
// @ts-ignore
import pdfjsWorker from "pdfjs-dist/legacy/build/pdf.worker.entry";

import { nanoid } from "nanoid";

const pdfToImages = async (file: File): Promise<any[]> => {
    const reader = new FileReader();
    pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
    return new Promise((resolve, reject) => {
        reader.onload = async (event) => {
            const result = event.target?.result;
            if (result instanceof ArrayBuffer) {
                const typedArray = new Uint8Array(result);
                const loadingTask = pdfjsLib.getDocument({ data: typedArray });
                const pdf = await loadingTask.promise;

                let images = [];
                for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
                    const page = await pdf.getPage(pageNum);
                    const viewport = page.getViewport({ scale: 1.5 });
                    const canvas = document.createElement("canvas");
                    const context = canvas.getContext("2d");
                    if (context) {
                        canvas.height = viewport.height;
                        canvas.width = viewport.width;

                        await page.render({
                            canvasContext: context,
                            viewport: viewport,
                        }).promise;
                        const base64data = canvas.toDataURL();
                        images.push({
                            id: nanoid(),
                            base64: base64data,
                            path: `page-${pageNum}.png`,
                        });
                    } else {
                        reject("Failed to obtain the canvas context");
                    }
                }
                resolve(images);
            } else {
                reject("The loaded file is not an ArrayBuffer");
            }
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    });
};

export default pdfToImages;
