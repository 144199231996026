import React, { useCallback, useContext, useEffect, useState } from "react";

import {
    EditorDataContext,
    EUpdateEditorActions,
} from "../../../context/EditorDataProvider";
import { HistoryContext } from "../../../context/HistoryProvider";
import {
    addFocusToTextBox,
    CM2PX,
    getOppositeScale,
    getSizeWithScale,
} from "../../../utils/utils";
import {
    ICropBox,
    IEditorDataItem,
    IEditorDataPageItemImage,
    IEditorDataPageItemSvg,
} from "../../../interfaces/editor-data.interface";

/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import ResizableRect from "../../../components/react-resizable-rotatable-draggable-touch-v2/index";
import { PageContext } from "../../../context/PageProvider";
import recreateSvg from "../../../utils/svg/recreateSvg";
import { EEditorDataItemTypes } from "../../../enums/editor-data-item-type.enum";
import useIsMobile from "../../../hooks/useIsMobile";
import {
    EErrorType,
    IErrorPerItem,
    addErrorPerItem,
    delErrorPerItem,
} from "../../../redux/features/errorListPerItemSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import ErrorInItem from "../../error-list-per-item/ErrorInItem";

import { validationBleed } from "../../../utils/resizable/validation";
import { isCropped, updateCropElement } from "../../../utils/resizable/crop";
import CropElement from "./CropElement";
import { setIsMove } from "../../../redux/features/snapPointsSlice";
import { isContentBox } from "../../../utils/editor-items";
import OuterStyled from "./OuterStyled";

import { useSnapPoints } from "../../../hooks/useSnapPoints";

interface IResizable {
    children: React.ReactChild;
    currentItem: IEditorDataItem;
    beforeResizableRect?: boolean;
    rotate?: boolean;
    hasBorder?: boolean;
    zoomable?: string;
    excludeAspectRatioPoints?: boolean;
    handleOnClick?: (e: React.MouseEvent<HTMLElement>) => void;
    cropElement?: boolean;
}

export interface IPropsBox {
    width: number;
    height: number;
    top: number;
    left: number;
    rotation: number;
}

let tmpPropsBox: IPropsBox = {
    width: 0,
    height: 0,
    top: 0,
    left: 0,
    rotation: 0,
};

let itemResizable: null | IEditorDataItem = null;

const Resizable = ({
    children,
    currentItem,
    rotate = true,
    hasBorder = true,
    excludeAspectRatioPoints = false,
    handleOnClick,
    zoomable = "w,e,n,s,nw, ne, se, sw",
    cropElement = false,
}: IResizable) => {
    const { page }: any = useContext(PageContext);
    const { editorData, updateEditorData, updateFocusElements }: any =
        useContext(EditorDataContext);
    const { saveHistory }: any = useContext(HistoryContext);
    const [dragged, setDragged] = useState(false);
    const scale = editorData[page].scale ? editorData[page].scale : 1;
    const oppositeScale = getOppositeScale(scale);
    const pageWidth = CM2PX(editorData[page].width);
    const pageHeight = CM2PX(editorData[page].height);
    const isMobile = useIsMobile();
    const bleed = editorData[page].bleed ? CM2PX(editorData[page].bleed) : 0;
    const isFixCropped = currentItem?.isFixCropped;
    const snapData = useAppSelector((state) => state.snapPoints);
    const { pages: snapPoints } = snapData;
    const snapPointsPage = snapPoints[page];
    const handleSnapPoints = useSnapPoints(page, snapPointsPage);

    const includeBorder =
        currentItem.borderWidth && !currentItem.svg ?
            currentItem.borderWidth * 2
        :   0;
    const dispatch = useAppDispatch();
    const errorsItems = useAppSelector(
        (state) => state.errorListPerItem.errorPerItem,
    );
    const itemIndex = errorsItems.findIndex(
        (item: IErrorPerItem) => item.id === currentItem.id,
    );

    const [cropBox, setCropBox] = useState<ICropBox>({
        width: 0,
        height: 0,
        leftDelta: 0,
        bottomDelta: 0,
        x: 0,
        y: 0,
    });

    const [propsBox, setPropsBox] = useState<IPropsBox>({
        width: 0,
        height: 0,
        top: 0,
        left: 0,
        rotation: 0,
    });

    useEffect(() => {
        setCropBox({
            width: currentItem.cropElement?.width || currentItem.width,
            height: currentItem.cropElement?.height || currentItem.height,
            leftDelta: currentItem.cropElement?.leftDelta || 0,
            bottomDelta: currentItem.cropElement?.bottomDelta || 0,
            x: currentItem.cropElement?.x || 0,
            y: currentItem.cropElement?.y || 0,
        });
        setPropsBox({
            width: currentItem.width,
            height: currentItem.height,
            top: currentItem.top,
            left: currentItem.left,
            rotation: currentItem.rotation ? currentItem.rotation : 0,
        });
    }, [currentItem]);

    useEffect(() => {
        if (dragged) {
            document.body.classList.add("item-dragged");
        } else {
            document.body.classList.remove("item-dragged");
        }
        return () => {
            document.body.classList.remove("item-dragged");
        };
    }, [dragged]);

    useEffect(() => {
        if (currentItem.type === EEditorDataItemTypes.BACKGROUND) {
            return;
        }

        validationBleed({
            currentItem,
            propsBox,
            bleed,
            includeBorder,
            pageHeight,
            pageWidth,
            dispatch,
        });
    }, [propsBox]);

    useEffect(() => {
        setPropsBox({
            ...propsBox,
            width: currentItem.width,
            top: currentItem.top,
            height: currentItem.height,
            left: currentItem.left,
            rotation: currentItem.rotation,
        });
    }, [editorData]);

    const handleRotate = useCallback(
        ({ rotateAngle }: { rotateAngle: number }) => {
            setPropsBox((prevPropsBox) => ({
                ...prevPropsBox,
                rotation: rotateAngle,
            }));
        },
        [],
    );

    const handleResize = useCallback(
        ({
            style: { top, left, width, height },
            deltaW,
            deltaH,
            type,
        }: any) => {
            if (scale !== 1) {
                if (!itemResizable) {
                    itemResizable = currentItem;
                }

                top = getSizeWithScale(top, itemResizable.top, scale);
                left = getSizeWithScale(left, itemResizable.left, scale);
                height = getSizeWithScale(height, itemResizable.height, scale);
                width = getSizeWithScale(width, itemResizable.width, scale);
            }

            const newProps = {
                ...propsBox,
                top: Math.round(top),
                left: Math.round(left),
                width: Math.round(width),
                height: Math.round(height),
            };

            handleSnapPoints({ ...newProps }, currentItem);

            const svg = recreateSvg(
                currentItem as IEditorDataPageItemSvg,
                newProps,
            );
            if (svg) {
                updateEditorData(
                    { ...currentItem, ...newProps, svg },
                    EUpdateEditorActions.UPDATE_ITEM,
                );
            } else {
                updateCropElement(
                    type,
                    width,
                    height,
                    cropElement,
                    cropBox,
                    setCropBox,
                );
                setPropsBox(newProps);
            }
        },
        [cropBox, cropElement, currentItem, propsBox, scale, updateEditorData],
    );

    const handleDrag = useCallback(
        ({ deltaX, deltaY }: any) => {
            const left = propsBox.left + deltaX * oppositeScale;
            const top = propsBox.top + deltaY * oppositeScale;

            const { top: adjustedTop, left: adjustedLeft } = handleSnapPoints(
                { ...propsBox, left, top },
                currentItem,
            );

            setPropsBox((prevPropsBox) => ({
                ...prevPropsBox,
                top: adjustedTop,
                left: adjustedLeft,
            }));
        },
        [oppositeScale, propsBox.left, propsBox.top, snapPointsPage, dispatch],
    );

    const handleResizeStart = useCallback(() => {
        dispatch(setIsMove(true));
        updateFocusElements(currentItem.id);
        setDragged(true);
    }, [currentItem.id, updateFocusElements]);

    const handleResizeEnd = useCallback(async () => {
        dispatch(setIsMove(false));
        itemResizable = null;
        const elem = { ...currentItem, ...propsBox };
        if (elem.type === EEditorDataItemTypes.IMAGE) {
            const imageElem = elem as IEditorDataPageItemImage;
            if (imageElem.resolution) {
                const { width: orig_w, height: orig_h } = imageElem.resolution;
                const { width: new_w, height: new_h } = imageElem;
                if (new_w > orig_w || new_h > orig_h) {
                    dispatch(
                        addErrorPerItem({
                            id: currentItem.id,
                            errorType: EErrorType.IMAGE_RESOLUTION,
                        }),
                    );
                } else {
                    dispatch(
                        delErrorPerItem({
                            id: currentItem.id,
                            errorType: EErrorType.IMAGE_RESOLUTION,
                        }),
                    );
                }
            }
        }

        if (isCropped(cropBox)) {
            elem.cropElement = cropBox;
        }
        saveHistory(
            await updateEditorData(elem, EUpdateEditorActions.UPDATE_ITEM),
        );
        setDragged(false);
    }, [
        cropBox,
        currentItem,
        dispatch,
        propsBox,
        saveHistory,
        updateEditorData,
    ]);

    const handleDragStart = useCallback(() => {
        dispatch(setIsMove(true));
        tmpPropsBox = propsBox;
        updateFocusElements(currentItem.id);
        setDragged(true);
    }, [currentItem.id, propsBox, updateFocusElements, dispatch]);

    const handleDragEnd = useCallback(async () => {
        dispatch(setIsMove(false));
        currentItem.firstClick = false;
        if (
            currentItem.type === EEditorDataItemTypes.TEXT &&
            tmpPropsBox &&
            propsBox.top === tmpPropsBox.top &&
            propsBox.left === tmpPropsBox.left
        ) {
            const focus = { firstClick: false };
            if (!isMobile) {
                addFocusToTextBox();
                focus.firstClick = true;
            }
            updateEditorData(
                { ...currentItem, ...propsBox, ...focus },
                EUpdateEditorActions.UPDATE_ITEM,
            );
        } else {
            saveHistory(
                await updateEditorData(
                    { ...currentItem, ...propsBox },
                    EUpdateEditorActions.UPDATE_ITEM,
                ),
            );
        }

        setDragged(false);
    }, [currentItem, isMobile, propsBox, saveHistory, updateEditorData]);

    const handleOnRotateEnd = useCallback(
        async () =>
            saveHistory(
                await updateEditorData(
                    { ...currentItem, ...propsBox },
                    EUpdateEditorActions.UPDATE_ITEM,
                ),
            ),
        [currentItem, propsBox, saveHistory, updateEditorData],
    );

    const hasError = itemIndex !== -1;

    return (
        <>
            {hasError && (
                <ErrorInItem itemId={currentItem.id} propsBox={propsBox} />
            )}
            <OuterStyled
                elemHeight={currentItem.height}
                elemWidth={currentItem.width}
                hasBorder={hasBorder}
                borderWidth={
                    currentItem.type === EEditorDataItemTypes.SVG ?
                        false
                    :   currentItem.borderWidth
                }
                cropElement={cropElement}
                borderColor={currentItem.borderColor}
                borderColorRgb={currentItem.borderColorRgb}
                hasRotate={rotate}
                opacity={currentItem.opacity}
                className={currentItem.focus ? "item-focused" : ""}
                currentItem={currentItem.focus}
                dragged={dragged}
                lock={currentItem.lock || isFixCropped}
                isContentBox={isContentBox(currentItem)}
                oppositeScale={oppositeScale}
                onClick={(e) => (handleOnClick ? handleOnClick(e) : false)}
            >
                {!cropElement && (
                    <span
                        style={{
                            position: "absolute",
                            zIndex:
                                currentItem.focus && currentItem.firstClick ?
                                    1
                                :   0,
                            transform: `rotate(${propsBox.rotation}deg)`,
                            transformOrigin: "center",
                            left: propsBox.left + includeBorder,
                            top: propsBox.top + includeBorder,
                            width: propsBox.width,
                            height: propsBox.height,
                        }}
                    >
                        {children}
                    </span>
                )}

                <ResizableRect
                    excludeAspectRatioPoints={excludeAspectRatioPoints}
                    left={propsBox.left}
                    top={propsBox.top}
                    width={propsBox.width}
                    height={propsBox.height}
                    rotateAngle={propsBox.rotation}
                    aspectRatio={false}
                    zoomable={
                        isFixCropped ? "w,e,n,s"
                        : currentItem.lock ?
                            ""
                        :   zoomable
                    }
                    onRotate={currentItem.lock ? () => false : handleRotate}
                    onRotateEnd={handleOnRotateEnd}
                    onResize={currentItem.lock ? () => false : handleResize}
                    onResizeStart={handleResizeStart}
                    onResizeEnd={handleResizeEnd}
                    onDragStart={isFixCropped ? () => false : handleDragStart}
                    onDrag={
                        currentItem.lock || isFixCropped ?
                            () => false
                        :   handleDrag
                    }
                    onDragEnd={handleDragEnd}
                >
                    {cropElement && (
                        <CropElement
                            updateEditorData={updateEditorData}
                            currentItem={currentItem}
                            cropBox={cropBox}
                        >
                            {children}
                        </CropElement>
                    )}
                </ResizableRect>
            </OuterStyled>
        </>
    );
};

export default Resizable;
