import React, { useCallback, useContext, useEffect, useRef } from "react";

import {
    EditorDataContext,
    EUpdateEditorActions,
} from "../../../context/EditorDataProvider";
import Tooltip, { ETooltipPlace } from "../../tool-tip/Tooltip";
import { HistoryContext } from "../../../context/HistoryProvider";
import ToolbarBtn from "../../buttons/ToolbarBtn";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg";
import { nanoid } from "nanoid";
import { StyledTitleToolBar } from "../../style-components/styleComponents";

interface ITrash {
    showToolBarTxt?: boolean;
}
const Trash = ({ showToolBarTxt = true }: ITrash) => {
    const { updateEditorData }: any = useContext(EditorDataContext);
    const { saveHistory }: any = useContext(HistoryContext);
    const handleKeyPress = useCallback((e) => {
        if (e.keyCode === 46 || (e.metaKey && e.keyCode === 8)) {
            e.preventDefault();
            handleOnTrash();
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPress);
        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [handleKeyPress]);

    const handleOnTrash = async () =>
        saveHistory(
            await updateEditorData({}, EUpdateEditorActions.REMOVE_ITEM),
        );

    const ref = useRef(null);
    const tooltipId = `trashTooltip-${nanoid()}`;
    const txt = "מחיקה";
    return (
        <>
            <ToolbarBtn
                data-tip
                data-for={tooltipId}
                ref={ref}
                className=""
                onClick={handleOnTrash}
            >
                <TrashIcon />
                {showToolBarTxt && (
                    <StyledTitleToolBar className="hide-desktop">
                        {txt}
                    </StyledTitleToolBar>
                )}
            </ToolbarBtn>
            <Tooltip place={ETooltipPlace.BOTTOM} id={tooltipId} elemRef={ref}>
                {txt}
            </Tooltip>
        </>
    );
};

export default Trash;
