/* eslint-disable */
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { getLength, getAngle, getCursor } from "../utils";
import StyledRect from "./StyledRect";
import { ReactComponent as RotateIcon } from "../../../assets/icons/rotate.svg";
const zoomableMap = {
    n: "t",
    s: "b",
    e: "r",
    w: "l",
    ne: "tr",
    nw: "tl",
    se: "br",
    sw: "bl",
};

export default class Rect extends PureComponent {
    static propTypes = {
        styles: PropTypes.object,
        zoomable: PropTypes.string,
        rotatable: PropTypes.bool,
        onResizeStart: PropTypes.func,
        onResize: PropTypes.func,
        onResizeEnd: PropTypes.func,
        onRotateStart: PropTypes.func,
        onRotate: PropTypes.func,
        onRotateEnd: PropTypes.func,
        onDragStart: PropTypes.func,
        onDrag: PropTypes.func,
        onDragEnd: PropTypes.func,
        parentRotateAngle: PropTypes.number,
        onClick: PropTypes.func,
        onDoubleClick: PropTypes.func,
        className: PropTypes.string,
        color: PropTypes.string,
    };

    setElementRef = (ref) => {
        this.$element = ref;
    };

    // Drag
    startDrag = (e) => {
        let { clientX: startX, clientY: startY } = e.touches ? e.touches[0] : e;
        this.props.onDragStart && this.props.onDragStart(e);
        this._isMouseDown = true;
        const onMove = (e) => {
            e.preventDefault();
            if (!this._isMouseDown) return; // patch: fix windows press win key during mouseup issue
            e.stopImmediatePropagation();
            const { clientX, clientY } = e.touches ? e.touches[0] : e;
            const deltaX = clientX - startX;
            const deltaY = clientY - startY;
            this.props.onDrag({ deltaX, deltaY, event: e });
            startX = clientX;
            startY = clientY;
        };
        const onUp = (e) => {
            document.removeEventListener("mousemove", onMove);
            document.removeEventListener("mouseup", onUp);
            document.removeEventListener("touchmove", onMove);
            document.removeEventListener("touchend", onUp);
            if (!this._isMouseDown) return;
            this._isMouseDown = false;
            this.props.onDragEnd && this.props.onDragEnd(e);
        };
        document.addEventListener("mousemove", onMove);
        document.addEventListener("mouseup", onUp);
        document.addEventListener("touchmove", onMove);
        document.addEventListener("touchend", onUp);
    };

    // Rotate
    startRotate = (e) => {
        if (e.button !== 0 && !e.touches) return;
        const { clientX, clientY } = e.touches ? e.touches[0] : e;
        const {
            styles: {
                transform: { rotateAngle: startAngle },
            },
        } = this.props;
        const rect = this.$element.getBoundingClientRect();
        const center = {
            x: rect.left + rect.width / 2,
            y: rect.top + rect.height / 2,
        };
        const startVector = {
            x: clientX - center.x,
            y: clientY - center.y,
        };
        this.props.onRotateStart && this.props.onRotateStart(e);
        this._isMouseDown = true;
        const onMove = (e) => {
            if (!this._isMouseDown) return; // patch: fix windows press win key during mouseup issue
            e.stopImmediatePropagation();
            const { clientX, clientY } = e.touches ? e.touches[0] : e;
            const rotateVector = {
                x: clientX - center.x,
                y: clientY - center.y,
            };
            const angle = getAngle(startVector, rotateVector);
            this.props.onRotate({ angle, startAngle, event: e });
        };
        const onUp = (e) => {
            document.removeEventListener("mousemove", onMove);
            document.removeEventListener("mouseup", onUp);
            document.removeEventListener("touchmove", onMove);
            document.removeEventListener("touchend", onUp);
            if (!this._isMouseDown) return;
            this._isMouseDown = false;
            this.props.onRotateEnd && this.props.onRotateEnd(e);
        };
        document.addEventListener("mousemove", onMove);
        document.addEventListener("mouseup", onUp);
        document.addEventListener("touchmove", onMove);
        document.addEventListener("touchend", onUp);
    };

    // Resize
    startResize = (e, cursor) => {
        if (e.button !== 0 && !e.touches) return;
        document.body.style.cursor = cursor;
        const {
            styles: {
                position: { centerX, centerY },
                size: { width, height },
                transform: { rotateAngle },
            },
        } = this.props;
        const { clientX: startX, clientY: startY } =
            e.touches ? e.touches[0] : e;
        const rect = { width, height, centerX, centerY, rotateAngle };
        const type = e.target.getAttribute("class").split(" ")[0];
        this.props.onResizeStart && this.props.onResizeStart(e);
        this._isMouseDown = true;
        const onMove = (e) => {
            if (!this._isMouseDown) return; // patch: fix windows press win key during mouseup issue
            e.stopImmediatePropagation();
            const { clientX, clientY } = e.touches ? e.touches[0] : e;
            const deltaX = clientX - startX;
            const deltaY = clientY - startY;
            const alpha = Math.atan2(deltaY, deltaX);
            const deltaL = getLength(deltaX, deltaY);
            const isShiftKey = e.shiftKey;
            this.props.onResize({
                deltaL,
                alpha,
                rect,
                type,
                isShiftKey,
                event: e,
            });
        };

        const onUp = (e) => {
            document.body.style.cursor = "auto";
            document.removeEventListener("mousemove", onMove);
            document.removeEventListener("mouseup", onUp);
            document.removeEventListener("touchmove", onMove);
            document.removeEventListener("touchend", onUp);
            if (!this._isMouseDown) return;
            this._isMouseDown = false;
            this.props.onResizeEnd && this.props.onResizeEnd(e);
        };
        document.addEventListener("mousemove", onMove);
        document.addEventListener("mouseup", onUp);
        document.addEventListener("touchmove", onMove);
        document.addEventListener("touchend", onUp);
    };

    render() {
        const {
            styles: {
                position: { centerX, centerY },
                size: { width, height },
                transform: { rotateAngle },
            },
            zoomable,
            rotatable,
            parentRotateAngle,
            onClick,
            onDoubleClick,
            className,
            color,
            children,
        } = this.props;
        const style = {
            width: Math.abs(width),
            height: Math.abs(height),
            transform: `rotate(${rotateAngle}deg)`,
            left: centerX - Math.abs(width) / 2,
            top: centerY - Math.abs(height) / 2,
        };
        const direction = zoomable
            .split(",")
            .map((d) => d.trim())
            .filter((d) => d); // TODO: may be speed up

        return (
            <StyledRect
                color={color}
                ref={this.setElementRef}
                onMouseDown={this.startDrag}
                onTouchStart={this.startDrag}
                className={`rect single-resizer ${className || ""}`}
                style={style}
                onClick={onClick}
                onDoubleClick={onDoubleClick}
            >
                {children}

                {rotatable && (
                    <div
                        className="rotate"
                        onMouseDown={this.startRotate}
                        onTouchStart={this.startRotate}
                    >
                        <RotateIcon />
                    </div>
                )}

                {direction.map((d) => {
                    const cursor = `${getCursor(rotateAngle + parentRotateAngle, d)}-resize`;
                    return (
                        <div
                            key={d}
                            style={{ cursor }}
                            className={`${zoomableMap[d]} resizable-handler`}
                            onMouseDown={(e) => this.startResize(e, cursor)}
                            onTouchStart={(e) => this.startResize(e, cursor)}
                        />
                    );
                })}

                {direction.map((d) => {
                    return (
                        <div key={d} className={`${zoomableMap[d]} square`} />
                    );
                })}
            </StyledRect>
        );
    }
}
