import React, { useContext } from "react";
import styled from "styled-components";
import { ResetButton, disabledBtn } from "../buttons/buttons";
import { FoldMenuContext } from "../../context/FoldMenuProvider";
import { ISideBarMenuItems } from "../layout/SideBarMenu";
import { useTranslation } from "react-i18next";

const ContainerStyled = styled(ResetButton)`
    display: flex;
    flex-direction: column;
    padding: var(--medium-gap) 0;
    align-items: center;
    width: 100%;
    &:hover,
    &.active {
        background-color: var(--hover-color);
        .icon {
            > svg {
                fill: #0c2b49 !important;
            }
            > svg {
                stroke: #0c2b49 !important;
            }
        }
        .text {
            font-weight: bold;
        }
    }
    ${disabledBtn}
`;

const SideBarMenuItemTextStyled = styled.div`
    font-size: var(--small-font-size);
`;

const CombinedShapeStyled = styled.div``;

const SideBarMenuItem = ({ menuName, iconType }: ISideBarMenuItems) => {
    const { setOpenFoldMenu, menuType }: any = useContext(FoldMenuContext);
    const active = menuType === menuName ? "active" : "";
    const { t } = useTranslation();

    return (
        <>
            <ContainerStyled
                className={`SideBarMenuItem ${active}`}
                onClick={() => {
                    setOpenFoldMenu(true, menuName);
                }}
            >
                <CombinedShapeStyled className={`${menuName} icon`}>
                    {iconType}
                </CombinedShapeStyled>
                <SideBarMenuItemTextStyled className="text">
                    {t(`sideBarMenu.items.${menuName}`)}
                </SideBarMenuItemTextStyled>
            </ContainerStyled>
        </>
    );
};

export default SideBarMenuItem;
