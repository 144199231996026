import React from "react";
import Crop from "./tools/Crop";

const ToolBarImageItem = () => {
    return (
        <>
            <Crop />
        </>
    );
};

export default ToolBarImageItem;
