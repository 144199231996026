import React, { useContext } from "react";
import styled from "styled-components";
import { FoldMenuContext } from "../../context/FoldMenuProvider";
import StorageApi from "../StorageApi";
import { EMenuType } from "../../enums/menu-type.enum";
import { ResetButton } from "../buttons/buttons";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { device } from "../../assets/styles/global";

const ContainerStyled = styled.div`
    position: relative;
    width: var(--side-bar-fold-width);
    padding: var(--small-gap);
    box-sizing: border-box;
    background-color: #fff;
    height: 100%;
    border-left: solid 1px #ced5db;
    overflow-x: hidden;
    overflow-y: auto;
    @media (${device.mobile}) {
        position: absolute;
        width: 100%;
        z-index: 2;
        height: calc(100% - var(--mobile-side-bar-height));
    }
`;

const ExitIconStyled = styled(ResetButton)`
    z-index: 2;
    position: absolute;
    top: var(--small-gap);
    left: var(--small-gap);
`;

const SideBarFoldMenu = ({ ...props }) => {
    const { setOpenFoldMenu, menuType, getPickMenu }: any =
        useContext(FoldMenuContext);
    return (
        <ContainerStyled {...props}>
            {menuType === EMenuType.PHOTO && <StorageApi />}
            {getPickMenu()}
            <ExitIconStyled
                onClick={() => setOpenFoldMenu(false)}
                className="hide-desktop"
            >
                <CloseIcon />
            </ExitIconStyled>
        </ContainerStyled>
    );
};

export default SideBarFoldMenu;
