import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import {
    EditorDataContext,
    EUpdateEditorActions,
} from "../../../context/EditorDataProvider";
import { CM2PX } from "../../../utils/utils";
import { PageContext } from "../../../context/PageProvider";
import { ImageDraggedContext } from "../../../context/ImageDraggedProvider";
import { ISideBarTextBlockData } from "../../../interfaces/side-bar-text-block.interface";
import { EFontStyle, EFontWeight } from "../../../enums/font-style.enum";
import { getText } from "../../../utils/editor-items";
import {
    StyledSideBarContainer,
    StyledSideBarInner,
    StyledSideBarTitle,
    StyledSideBarSubTitle,
} from "../../style-components/styleComponents";
import useIsMobile from "../../../hooks/useIsMobile";
import { marginBleed } from "../../../utils/error-items";

const TextBlockItem = styled.div`
    justify-self: center;
    font-weight: 600;
    height: 45px;
    padding: var(--medium-gap);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: var(--background-text-box);
    &:hover {
        background-color: var(--secondary-color-brand);
    }
`;

function TextsType() {
    const { page }: any = useContext(PageContext);
    const { editorData, updateEditorData }: any = useContext(EditorDataContext);
    const printColor =
        editorData[page].printColor ? editorData[page].printColor : null;
    const pageWidth = CM2PX(editorData[page].width);
    const { setImageDragged }: any = useContext(ImageDraggedContext);
    const bleed = editorData[page].bleed ? CM2PX(editorData[page].bleed) : 0;
    const { height } = editorData[page];
    const heightCanvas = CM2PX(height);
    const { maxFontSize, minFontSize } = editorData[page] || {};
    const isMobile = useIsMobile();

    const initialWidth = 265;
    const bleedRight = pageWidth - bleed - initialWidth - marginBleed;

    const [sideBarTextBlockData, setSideBarTextBlockData] = useState<
        ISideBarTextBlockData[]
    >([
        {
            textLabel: "הוסף כותרת",
            text: "כותרת חדשה",
            fontSize: 33,
            fontSizeSideBar: "32px", //OLD 32
            fontWeight: EFontWeight.EXTRA_BOLD_WEIGHT,
            fontStyle: EFontStyle.EXTRA_BOLD,
            width: initialWidth,
            top: 0 + bleed + marginBleed,
            left: bleedRight,
        },
        {
            textLabel: "הוסף כותרת משנה",
            text: "כותרת משנה חדשה",
            fontSize: 24,
            fontSizeSideBar: "22px", //OLD 22
            fontStyle: EFontStyle.SEMI_BOLD,
            fontWeight: EFontWeight.SEMI_BOLD_WEIGHT,
            width: initialWidth,
            top: 60 + bleed + marginBleed,
            left: bleedRight,
        },
        {
            textLabel: "הוסף גוף טקסט",
            text: "גוף טקסט חדש",
            fontSize: 12,
            fontSizeSideBar: "16px", //16
            fontStyle: EFontStyle.REGULAR,
            fontWeight: EFontWeight.REGULAR_WEIGHT,
            width: initialWidth,
            top: 120 + bleed + marginBleed,
            left: bleedRight,
        },
    ]);

    function handleDragStart(
        e: React.DragEvent,
        itemProps: ISideBarTextBlockData,
    ) {
        const node = e.target as HTMLElement;
        const rect = node.getBoundingClientRect();
        const offsetXElem = e.clientX - rect.x;
        const offsetYElem = e.clientY - rect.y;
        e.dataTransfer.setData(
            "offsetElem",
            JSON.stringify({ offsetXElem, offsetYElem }),
        );
        e.dataTransfer.setData(
            "editorItem",
            JSON.stringify(getText(itemProps, printColor)),
        );
        setImageDragged(false);
    }

    function handleOnClick(
        e: React.MouseEvent,
        itemProps: ISideBarTextBlockData,
    ) {
        updateEditorData(null, EUpdateEditorActions.REMOVE_PLACE_HOLDER);
        updateEditorData(
            getText({ ...itemProps, firstClick: !isMobile }, printColor),
        );
    }

    useEffect(() => {
        if (minFontSize && maxFontSize) {
            setSideBarTextBlockData((currentData) =>
                currentData.map((item, index) => {
                    switch (index) {
                        case 0: {
                            const new_fontSize =
                                minFontSize + minFontSize * 0.8;
                            const fontSize =
                                new_fontSize < maxFontSize ? new_fontSize : (
                                    maxFontSize
                                );
                            return { ...item, fontSize: fontSize };
                        }
                        case 1: {
                            const numberBetweenMinMax =
                                (minFontSize + maxFontSize) / 2;
                            const fontSize = Math.min(
                                numberBetweenMinMax,
                                maxFontSize,
                            );
                            return { ...item, fontSize };
                        }
                        case 2:
                            return { ...item, fontSize: minFontSize };
                        default:
                            return item;
                    }
                }),
            );
        } else if (minFontSize) {
            setSideBarTextBlockData((currentData) =>
                currentData.map((item, index) => {
                    switch (index) {
                        case 0:
                            return {
                                ...item,
                                fontSize: minFontSize + minFontSize * 0.8,
                            };
                        case 1:
                            return {
                                ...item,
                                fontSize: minFontSize + minFontSize * 0.3,
                            };
                        case 2:
                            return { ...item, fontSize: minFontSize };
                        default:
                            return item;
                    }
                }),
            );
        } else if (maxFontSize) {
            setSideBarTextBlockData((currentData) =>
                currentData.map((item, index) => {
                    const fontSize =
                        item.fontSize > maxFontSize ?
                            maxFontSize
                        :   item.fontSize;
                    return { ...item, fontSize };
                }),
            );
        }
    }, [page]);

    return (
        <StyledSideBarContainer>
            <StyledSideBarTitle>סגנון טקסט</StyledSideBarTitle>
            <StyledSideBarSubTitle>
                הקש על טקסט כדי להוסיף לדף
            </StyledSideBarSubTitle>
            <StyledSideBarInner className="SideBarTextMenu">
                {sideBarTextBlockData.map((item, index) => {
                    item.top = heightCanvas > 200 ? item.top : 0;
                    return (
                        <TextBlockItem
                            className="TextBlockItem"
                            key={`side_bar_${index}`}
                            draggable
                            onDragStart={(e) => handleDragStart(e, item)}
                            onClick={(e) => handleOnClick(e, item)}
                            style={{ fontSize: item.fontSizeSideBar }}
                        >
                            {item.textLabel}
                        </TextBlockItem>
                    );
                })}
            </StyledSideBarInner>
        </StyledSideBarContainer>
    );
}

export default TextsType;
