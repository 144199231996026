export enum EMenuType {
    TEXT = "text",
    COLOR = "color",
    FONT = "font",
    PHOTO = "photo",
    PRODUCT = "product",
    BORDER_COLOR = "borderColor",
    ELEMENTS = "elements",
    BACKGROUND_COLOR = "background_color",
    BACKGROUNDS = "backgrounds",
}
