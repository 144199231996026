import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";

i18n.use(HttpApi)
    .use(initReactI18next)
    .init({
        lng: "he",
        fallbackLng: "he",
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json",
        },
    })
    .then(() => console.log("i18n initialized successfully"))
    .catch((error) => console.error("Failed to initialize i18n", error));

export default i18n;
