import React, { useContext, useEffect } from "react";
import { createGlobalStyle } from "styled-components";
import TopBarMenu from "./TopBarMenu";
import Canvas from "../canvas/Canvas";
import SideBarFoldMenu from "./SideBarFoldMenu";
import SideBarMenu from "./SideBarMenu";
import styled from "styled-components";
import { FoldMenuContext } from "../../context/FoldMenuProvider";
import { useAppSelector } from "../../redux/hook";
import { device } from "../../assets/styles/global";
import useIsMobile from "../../hooks/useIsMobile";
import ErrorSection from "../error-list-per-item/error-section/ErrorSection";

interface IGridConfig {
    state: boolean;
    showErrorSection: boolean;
}

const getGridTemplateAreas = ({ state, showErrorSection }: IGridConfig) => {
    if (state) {
        return showErrorSection ?
                '"sidebar foldmenu canvas errorSection"'
            :   '"sidebar foldmenu canvas"';
    } else {
        return showErrorSection ?
                '"sidebar canvas errorSection"'
            :   '"sidebar canvas"';
    }
};

const getGridTemplateColumns = ({ state, showErrorSection }: IGridConfig) => {
    if (state) {
        return showErrorSection ?
                "var(--side-bar-width) var(--side-bar-fold-width) 1fr var(--side-bar-fold-width)"
            :   "var(--side-bar-width) var(--side-bar-fold-width) 1fr";
    } else {
        return showErrorSection ?
                "var(--side-bar-width) 1fr var(--side-bar-fold-width)"
            :   "var(--side-bar-width) 1fr";
    }
};

const AppMainStyled = styled.div<IGridConfig>`
    display: grid;
    width: 100%;
    position: relative;
    height: calc(var(--app-height) - var(--top-bar-menu-height));
    overflow: hidden;

    @media (${device.desktop}) {
        grid-template-areas: ${({ state, showErrorSection }) =>
            getGridTemplateAreas({ state, showErrorSection })};
        grid-template-columns: ${({ state, showErrorSection }) =>
            getGridTemplateColumns({ state, showErrorSection })};
    }

    @media (${device.mobile}) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr var(--mobile-side-bar-height);
        grid-template-areas: "canvas" "sidebar";
        ${({ state }) => state && "height:var(--app-height)"};
    }
`;
interface IContainerStyledProps {
    primaryColor?: string;
    fontFamily?: string;
    secondaryColor?: string;
}

interface IGlobalStyledProps {
    fontFamily?: string;
    primaryColor?: string;
}

const GlobalStyle = createGlobalStyle<IGlobalStyledProps>`
  
  
  
  body,button,input {
    font-family: ${({ fontFamily }) => fontFamily || "Arial, sans-serif"};
  }
  ::-webkit-scrollbar {
      width: 8px;
  }

  ::-webkit-scrollbar-track {
      background: #fafafa;
  }

  ::-webkit-scrollbar-thumb {
      background-color: ${({ primaryColor }) => primaryColor};
  }

  @media (${device.desktop}) {
    .hide-desktop{
      display: none!important;
    }
  }
  
  @media (${device.mobile}) {
    html, body {
        font-size: 16px;
        overflow: hidden;
    }

    .hide-mobile {
        display: none !important;
    }
    .toolbar-icon{
      width: 22px;height: 22px;
    }
    :root{
      --top-bar-menu-height:56px;
    }
  }
`;

const ContainerStyled = styled.div<IContainerStyledProps>`
    --primary-color-brand: ${({ primaryColor }) => primaryColor};
    --secondary-color-brand: ${({ secondaryColor }) => secondaryColor};
    --font-family: ${({ fontFamily }) => fontFamily};
    display: flex;
    flex-direction: column;
`;

const Layout = () => {
    const { brandConfig } = useAppSelector((state) => state.brandConfig);
    const { primaryColor, fontFamily, secondaryColor } = brandConfig;
    const { openFoldMenu }: any = useContext(FoldMenuContext);
    const isMobile = useIsMobile();
    const openErrorSection = useAppSelector(
        (state) => state.errorListPerItem.openErrorSection,
    );

    useEffect(() => {
        const updateHeight = () => {
            document.documentElement.style.setProperty(
                "--app-height",
                `${window.innerHeight}px`,
            );
        };

        window.addEventListener("resize", updateHeight);
        updateHeight();

        return () => window.removeEventListener("resize", updateHeight);
    }, []);

    return (
        <>
            <GlobalStyle fontFamily={fontFamily} primaryColor={primaryColor} />
            <ContainerStyled
                secondaryColor={secondaryColor}
                primaryColor={primaryColor}
                fontFamily={fontFamily}
            >
                {!isMobile || !openFoldMenu ?
                    <TopBarMenu />
                :   null}
                <AppMainStyled
                    showErrorSection={openErrorSection}
                    state={openFoldMenu}
                >
                    <SideBarMenu style={{ gridArea: "sidebar" }} />
                    {openFoldMenu && (
                        <SideBarFoldMenu style={{ gridArea: "foldmenu" }} />
                    )}
                    <Canvas style={{ gridArea: "canvas" }} />
                    <ErrorSection style={{ gridArea: "errorSection" }} />
                </AppMainStyled>
            </ContainerStyled>
        </>
    );
};

export default Layout;
