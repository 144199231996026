import { useState, useEffect } from "react";
import { breakpoints } from "../assets/styles/global";

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(
        window.innerWidth < breakpoints.mobile,
    );

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < breakpoints.mobile);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return isMobile;
};

export default useIsMobile;
