import React, { memo, useContext, useEffect, useState } from "react";
import { useQueryString } from "../context/QueryStringProvider";
import { nanoid } from "nanoid";
import LoadingSnake from "./loading-snake/loading-snake";
import { PhotosContext, IGalleryPhotos } from "../context/PhotosProvider";
import {
    addImagesToStorage,
    getImagesToStorage,
} from "../services/storage_api";
import useIndexedDB, { EIndexDbSettings } from "../hooks/useIndexedDB";

let isFetched = false;
const StorageApi = () => {
    const { storageApi, storageToken } = useQueryString();
    const { addPhotos }: any = useContext(PhotosContext);

    if (!storageToken || isFetched) {
        return <></>;
    }

    const { initPhotos }: any = useContext(PhotosContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>(null);
    const { data, clearData } = useIndexedDB(
        EIndexDbSettings.LOCAL_USE_PHOTOS,
        null,
    );

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const res = await getImagesToStorage(storageApi, storageToken);
                const storagePhotos = res.data.map((storageSrc: string) => {
                    return { storageSrc, id: nanoid() };
                });

                isFetched = true;
                const localPhotos = data;
                let localPhotosObject: IGalleryPhotos[] = [];

                if (localPhotos) {
                    localPhotosObject = data;
                    const results = await Promise.all(
                        localPhotosObject.map((item: IGalleryPhotos) =>
                            addImagesToStorage(item, storageApi, storageToken),
                        ),
                    );
                    localPhotosObject = results.map((result, index) => ({
                        ...localPhotosObject[index],
                        storageSrc: result.data?.storageSrc,
                        id: result.data?.id,
                    }));

                    await clearData();
                }

                initPhotos([...localPhotosObject, ...storagePhotos]);
                /*add photos*/
            } catch (error) {
                if (error instanceof Error) {
                    setError(error);
                }
                setLoading(false);
            } finally {
                setLoading(false);
            }
        };

        if (data) {
            fetchData().then();
        }
    }, [data]);

    if (loading) return <LoadingSnake />;
    return <></>;
};

export default memo(StorageApi);
