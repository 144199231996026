import React, { useContext } from "react";
import {
    IEditorDataItem,
    IEditorDataPageItemText,
} from "../../../interfaces/editor-data.interface";
import ToolbarBtn from "../../buttons/ToolbarBtn";
import { EditorDataContext } from "../../../context/EditorDataProvider";
import { PageContext } from "../../../context/PageProvider";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import { addFocusToTextBox } from "../../../utils/utils";
import { StyledTitleToolBar } from "../../style-components/styleComponents";

const Edit = ({ ...props }) => {
    const { page }: any = useContext(PageContext);
    const { editorData, updateItems }: any = useContext(EditorDataContext);
    const items = editorData[page].items;

    const handleClick = async () => {
        await updateItems(
            items.map((item: IEditorDataPageItemText) => {
                if (item.focus) item.editable = true;
                return item;
            }),
        );

        addFocusToTextBox();
    };

    return (
        <ToolbarBtn {...props} onClick={handleClick}>
            <EditIcon />
            <StyledTitleToolBar>עריכה</StyledTitleToolBar>
        </ToolbarBtn>
    );
};

export default Edit;
