import React from "react";
import { IEditorDataPageItemSvg } from "../../interfaces/editor-data.interface";
import Resizable from "./utils/Resizable";
import styled from "styled-components";
import { EShapes } from "../sideBar/foldMenu/elements/listElements";

interface ISvgBox {
    currentItem: IEditorDataPageItemSvg;
}
const ContainerSvgStyled = styled.div`
    width: 100%;
    height: 100%;
    svg {
        position: absolute;
        width: 100% !important;
        height: 100% !important;
        top: 0;
        left: 0;
    }
`;

const SvgBox = ({ currentItem }: ISvgBox) => {
    const { svg, shape } = currentItem;
    const excludeAspectRatioPoints: string[] = [
        EShapes.RECTANGLE,
        EShapes.ROUND_RECTANGLE,
    ];

    return (
        <Resizable
            currentItem={currentItem}
            excludeAspectRatioPoints={excludeAspectRatioPoints.includes(
                shape || "",
            )}
        >
            <ContainerSvgStyled dangerouslySetInnerHTML={{ __html: svg }} />
        </Resizable>
    );
};
export default SvgBox;
