import { ICropBox } from "../../interfaces/editor-data.interface";

export const updateCropElement = (
    type: string,
    width: number,
    height: number,
    cropElement: boolean,
    cropBox: ICropBox,
    setCropBox: (cropBox: ICropBox) => void,
) => {
    if (!cropElement) {
        return false;
    }

    let newCropBox = { ...cropBox };
    const originalAspectRatio = cropBox.width / cropBox.height;

    const calculateNewDimensions = () => {
        let newHeight = height + cropBox.y + cropBox.bottomDelta;
        let newWidth = newHeight * originalAspectRatio;

        if (width + cropBox.x + cropBox.leftDelta > newWidth) {
            newWidth = width + cropBox.x + cropBox.leftDelta;
            newHeight = newWidth / originalAspectRatio;
        }

        return { newWidth, newHeight };
    };

    switch (type) {
        case "r":
            newCropBox.x = newCropBox.width - width - newCropBox.leftDelta;
            if (newCropBox.x < 0) {
                newCropBox.width += Math.abs(newCropBox.x);
                newCropBox.height = newCropBox.width / originalAspectRatio;
                newCropBox.x = 0;
                newCropBox.bottomDelta = newCropBox.height - height;
            }
            break;
        case "l":
            newCropBox.leftDelta = newCropBox.width - width - newCropBox.x;
            if (newCropBox.leftDelta < 0) {
                newCropBox.width += Math.abs(newCropBox.leftDelta);
                newCropBox.height = newCropBox.width / originalAspectRatio;
                newCropBox.leftDelta = 0;
                newCropBox.bottomDelta = newCropBox.height - height;
            }
            break;
        case "t":
            newCropBox.y = newCropBox.height - height - newCropBox.bottomDelta;
            if (newCropBox.y < 0) {
                newCropBox.height += Math.abs(newCropBox.y);
                newCropBox.width = newCropBox.height * originalAspectRatio;
                newCropBox.y = 0;
                newCropBox.leftDelta = newCropBox.width - width;
            }
            break;
        case "b":
            newCropBox.bottomDelta = newCropBox.height - height - newCropBox.y;
            if (newCropBox.bottomDelta < 0) {
                newCropBox.height += Math.abs(newCropBox.bottomDelta);
                newCropBox.width = newCropBox.height * originalAspectRatio;
                newCropBox.bottomDelta = 0;
                newCropBox.leftDelta = newCropBox.width - width;
            }
            break;
        case "bl":
        case "br":
        case "tr":
        case "tl":
            {
                const { newWidth, newHeight } = calculateNewDimensions();

                if (newCropBox.leftDelta !== 0) {
                    const aspectRatio = newCropBox.leftDelta / newCropBox.width;
                    newCropBox.leftDelta = newWidth * aspectRatio;
                }

                if (newCropBox.x !== 0) {
                    const aspectRatio = newCropBox.x / newCropBox.width;
                    newCropBox.x = newWidth * aspectRatio;
                }

                if (newCropBox.bottomDelta !== 0) {
                    const aspectRatio =
                        newCropBox.bottomDelta / newCropBox.height;
                    newCropBox.bottomDelta = newHeight * aspectRatio;
                }

                if (newCropBox.y !== 0) {
                    const aspectRatio = newCropBox.y / newCropBox.height;
                    newCropBox.y = newHeight * aspectRatio;
                }

                newCropBox = {
                    ...newCropBox,
                    width: newWidth,
                    height: newHeight,
                };
            }
            break;
    }

    setCropBox(newCropBox);
};

export const isCropped = (cropBox: ICropBox) =>
    cropBox.bottomDelta !== 0 ||
    cropBox.leftDelta !== 0 ||
    cropBox.x !== 0 ||
    cropBox.y !== 0;
