import styled from "styled-components";

const globalCssBtn = `
  min-width: 154px;
  height: 40px;
  font-size: var(--secondary-font-size);
  font-weight: bold;
  display:flex;
  justify-content: center;
  align-items:center;
  border-radius: var(--main-border-radius);
  border: 1px var(--primary-color-brand) solid;
  cursor: pointer;
`;

export const disabledBtn = `
    &:disabled{
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

export const MainButton = styled.button`
    ${globalCssBtn}
    background-color:var(--primary-color-brand);
    &:hover:enabled,
    &:active {
        background-color: var(--secondary-color-brand);
        border-color: var(--secondary-color-brand);
    }
    ${disabledBtn}
`;

export const SecondaryButton = styled.button`
    ${globalCssBtn}
    background-color: var(--white);
    &:hover:enabled,
    &:active {
        background-color: var(--secondary-color-brand);
        border-color: var(--secondary-color-brand);
    }
    ${disabledBtn}
`;

export const IconButton = styled.button`
    background-color: inherit;
    border: none;
    cursor: pointer;
    padding: 0;
`;

export const ResetButton = styled.button`
    cursor: pointer;
    background-color: transparent;
    border: none;
    font-size: inherit;
`;
