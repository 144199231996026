import React, { useContext, useEffect } from "react";
import {
    IEditorDataItem,
    IEditorDataPageItemImage,
} from "../../interfaces/editor-data.interface";
import styled from "styled-components";
import { nanoid } from "nanoid";
import { EEditorDataItemTypes } from "../../enums/editor-data-item-type.enum";
import Resizable from "./utils/Resizable";
import { PageContext } from "../../context/PageProvider";
import {
    EditorDataContext,
    EUpdateEditorActions,
} from "../../context/EditorDataProvider";
import { CM2PX } from "../../utils/utils";
import { FoldMenuContext } from "../../context/FoldMenuProvider";
import { EMenuType } from "../../enums/menu-type.enum";
import useIsMobile from "../../hooks/useIsMobile";

interface IBackgroundBox {
    currentItem?: IEditorDataItem;
}
interface IBackgroundColorStyled {
    backgroundColor: string;
    backgroundSrc: string | boolean;
}
const BackgroundColorStyled = styled.div<IBackgroundColorStyled>`
    width: 100%;
    height: 100%;
    z-index: -1;
    ${({ backgroundColor, backgroundSrc }) =>
        backgroundSrc ?
            `background-image: url("${backgroundSrc}"); background-size: cover;background-position:center`
        :   `background-color: ${backgroundColor};`}
`;

const BackgroundBox = ({ currentItem }: IBackgroundBox) => {
    const isMobile = useIsMobile();

    const { page }: any = useContext(PageContext);
    const { editorData, updateEditorData }: any = useContext(EditorDataContext);
    const { backgroundColorCanvas } = editorData[page];
    if (!backgroundColorCanvas) return <></>;

    const width = CM2PX(editorData[page].width);
    const height = CM2PX(editorData[page].height);
    const { setOpenFoldMenu }: any = useContext(FoldMenuContext);

    useEffect(() => {
        if (!currentItem) {
            const id = nanoid();
            const item = {
                id,
                key: id,
                type: EEditorDataItemTypes.BACKGROUND,
                focus: false,
                backgroundColor: backgroundColorCanvas,
                width,
                height,
                rotation: 0,
                borderWidth: 0,
                top: 0,
                left: 0,
                lock: true,
            };
            updateEditorData(item, EUpdateEditorActions.ADD_ITEM);
        }
    }, []);

    if (!currentItem) {
        return <></>;
    }

    const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
        if (isMobile) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }
        currentItem.focus = true;
        updateEditorData(currentItem, EUpdateEditorActions.UPDATE_ITEM);
        setOpenFoldMenu(true, EMenuType.BACKGROUNDS);
    };

    const { backgroundColor, backgroundSrc = false } = currentItem ?? {
        backgroundColor: backgroundColorCanvas,
    };

    return (
        <Resizable handleOnClick={handleOnClick} currentItem={currentItem}>
            <BackgroundColorStyled
                backgroundSrc={backgroundSrc}
                backgroundColor={backgroundColor as string}
            />
        </Resizable>
    );
};

export default BackgroundBox;
