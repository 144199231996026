import {
    IEditorDataItem,
    IEditorDataPageItemImage,
    IEditorDataPageItemSvg,
    IEditorDataPageItemText,
} from "../../../interfaces/editor-data.interface";
import React from "react";
import { EErrorType } from "../../../redux/features/errorListPerItemSlice";
import { EEditorDataItemTypes } from "../../../enums/editor-data-item-type.enum";
import styled from "styled-components";
import { PhotoIcon } from "../../svgs/Photo";
import { TextIcon } from "../../svgs/Text";
import { ElementsIcon } from "../../svgs/ElementsIcon";

interface IErrorSectionItemProps {
    item?: IEditorDataItem;
    errors: EErrorType[];
}

const StyledWrap = styled.div`
    width: 100%;
    background-color: var(--white);
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: var(--main-border-radius);
    padding: var(--small-gap);
    box-sizing: border-box;
    gap: var(--medium-gap);
    display: flex;
    flex-direction: column;
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 88%;
        display: flex;
        flex-direction: column;
        gap: var(--small-gap);
    }
`;

const StyledImageError = styled.div`
    margin: 0 auto;
    max-width: 110px;
    overflow: hidden;

    img {
        max-width: 100%;
        max-height: 100%;
        height: auto;
        width: auto;
        object-fit: contain;
    }
`;

const StyledTextError = styled.div`
    margin: 0 auto;
    width: 80%;
    height: 30px;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: var(--extra-small-gap);
    border: 1px solid var(--primary-color-brand);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const StyledWrapList = styled.div`
    display: flex;
    gap: var(--extra-small-gap);
    svg {
        width: 12%;
    }
`;

const ErrorSectionItem = ({ item, errors }: IErrorSectionItemProps) => {
    if (!item) return null;

    const renderContentBasedOnType = (item: IEditorDataItem) => {
        switch (item.type) {
            case EEditorDataItemTypes.TEXT: {
                const textItem = item as IEditorDataPageItemText;
                return <StyledTextError>{textItem.content}</StyledTextError>;
            }

            case EEditorDataItemTypes.IMAGE: {
                const imageItem = item as IEditorDataPageItemImage;
                return (
                    <StyledImageError>
                        <img
                            alt={imageItem.id}
                            src={imageItem.storageSrc || imageItem.base64}
                        />
                    </StyledImageError>
                );
            }

            case EEditorDataItemTypes.SVG: {
                const svgItem = item as IEditorDataPageItemSvg;
                const svgBlob = new Blob([svgItem.svg], {
                    type: "image/svg+xml",
                });
                const imageUrl = URL.createObjectURL(svgBlob);
                return (
                    <StyledImageError>
                        <img alt={svgItem.id} src={imageUrl} />
                    </StyledImageError>
                );
            }

            default:
                return null;
        }
    };

    return (
        <StyledWrap>
            <StyledWrapList>
                {item.type === EEditorDataItemTypes.IMAGE && <PhotoIcon />}
                {item.type === EEditorDataItemTypes.TEXT && <TextIcon />}
                {item.type === EEditorDataItemTypes.SVG && <ElementsIcon />}
                <ul>
                    {errors.map((error, index) => (
                        <li key={`error_item_${index}`}>{error}</li>
                    ))}
                </ul>
            </StyledWrapList>
            {renderContentBasedOnType(item)}
        </StyledWrap>
    );
};

export default ErrorSectionItem;
