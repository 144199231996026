import axios from "axios";
import { IGeneratePdf } from "../../redux/features/brandConfigSlice";

export const createAxiosInstance = (generatePdf: IGeneratePdf) => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlOrigin = urlParams.get("origin");

    const baseUrlMapping = {
        sandbox: generatePdf.sandbox,
        staging: generatePdf.staging,
        local: generatePdf.local,
        localhost: generatePdf.local,
    };

    const baseURL = Object.entries(baseUrlMapping).reduce(
        (acc, [key, value]) => {
            return urlOrigin?.includes(key) ? value : acc;
        },
        generatePdf.production,
    );

    return axios.create({
        baseURL,
        headers: {
            "Content-Type": "application/json",
        },
    });
};
