import React, { memo, useContext, useEffect, useState } from "react";
import LoadingTopSnake from "./loading-snake/loading-top-snake";
import { EditorDataContext } from "../context/EditorDataProvider";
import { HistoryContext } from "../context/HistoryProvider";
import { PhotosInCanvasContext } from "../context/PhotosInCanvasProvider";
import {
    IEditorData,
    IEditorDataPage,
} from "../interfaces/editor-data.interface";
import { EEditorDataItemTypes } from "../enums/editor-data-item-type.enum";
import { getImage } from "../utils/editor-items";
import { useQueryString } from "../context/QueryStringProvider";
import { a4, bcard, pen } from "../utils/helpers";
import { CM2POINTS, CM2PX } from "../utils/utils";
import useAutoSave from "../hooks/useAutoSave";
import useIndexedDB, { EIndexDbSettings } from "../hooks/useIndexedDB";

interface IInitEditor {
    children: React.ReactChild;
}
const InitEditor = ({ children }: IInitEditor) => {
    /*TODO @jonathandana check type any*/
    const { initData }: any = useContext(EditorDataContext);
    const [isLoading, setIsLoading] = useState(true);
    const { saveHistory }: any = useContext(HistoryContext);
    const { savePhotos }: any = useContext(PhotosInCanvasContext);
    const {
        endpointInit,
        autoSaveId,
        clearSavedAutoData,
        setClearSavedAutoData,
    } = useQueryString();
    const { getDataByKey, deleteData } = useIndexedDB(
        EIndexDbSettings.EDITOR_DATA,
        [],
    );

    useAutoSave();
    /*Default Data*/
    let initialData: IEditorData = [
        // {
        //     width: 9.5,
        //     height: 5.5,
        //     backgroundColorCanvas: "white",
        //     scale: 1,
        //     items: [],
        //     sharedWithAllPages: {
        //         chosenColors: ["#5e17eb", "#ff3131"],
        //         project: {
        //             enabled: true,
        //             name: "",
        //             placeHolder: "הפרויקט שלי",
        //         },
        //     },
        // },
        {
            width: 9.5,
            height: 5.5,
            backgroundColorCanvas: "white",
            scale: 1,
            items: [],
            sharedWithAllPages: {
                chosenColors: ["#5e17eb", "#ff3131"],
                project: {
                    enabled: true,
                    name: "",
                    placeHolder: "הפרויקט שלי",
                },
            },
            bleed: 0.25,
            helper: bcard,
        },
        {
            width: 29.7,
            height: 21,
            backgroundColorCanvas: "white",
            items: [],
            sharedWithAllPages: {
                chosenColors: ["#5e17eb", "#ff3131"],
                project: {
                    enabled: true,
                    name: "",
                    placeHolder: "הפרויקט שלי",
                },
            },
            helper: a4,
            hideHelper: true,
        },

        // {
        //     width: 4.5,
        //     height: 1,
        //     backgroundColorCanvas: "white",
        //     scale: 1,
        //     helper: pen,
        //     minFontSize: 20,
        //     items: [],
        // },
        // {
        //     width: 9.5,
        //     height: 5.5,
        //     maxFontSize: 200,
        //     bgColor: "black",
        //     helper: bcard,
        //     scale: 1,
        //     items: [],
        //     bleed: 0.25,
        //     printColor: "red",
        // },
        {
            width: 4.5,
            height: 1,
            bgColor: "black",
            helper: pen,
            scale: 1,
            items: [],
            printColor: "orange",
            minFontSize: 20,
            maxFontSize: 300,
        },
    ];

    const applyDimensionConversions = (page: IEditorDataPage) => {
        page.widthPoints = CM2POINTS(page.width);
        page.heightPoints = CM2POINTS(page.height);
        page.cssWidth = CM2PX(page.width);
        page.cssHeight = CM2PX(page.height);
        return page;
    };

    useEffect(() => {
        (async () => {
            let existingData = null;

            if (autoSaveId && !clearSavedAutoData) {
                existingData = await getDataByKey(autoSaveId);
            }
            if (autoSaveId && clearSavedAutoData) {
                await deleteData(autoSaveId);
                setClearSavedAutoData(false);
            }

            if (existingData?.editorData) {
                initialData = existingData?.editorData.map(
                    applyDimensionConversions,
                );
                initData(initialData);
            } else if (endpointInit) {
                const resp = await fetch(endpointInit);
                const json = await resp.json();
                initialData = json.map((page: IEditorDataPage) => {
                    page.items = page.items.map((item) => {
                        if (
                            !item.id &&
                            item.type === EEditorDataItemTypes.IMAGE
                        ) {
                            item = getImage(page, item);
                        }
                        //Save Photos.
                        if (
                            item.type === EEditorDataItemTypes.IMAGE &&
                            item.base64
                        ) {
                            savePhotos(item);
                        }
                        return item;
                    });
                    return applyDimensionConversions(page);
                });
                initData(initialData);
            } else {
                const dataWithConversions = initialData.map(
                    applyDimensionConversions,
                );
                initData(dataWithConversions);
            }
            saveHistory(initialData);
            setIsLoading(false);
        })();
    }, []);

    if (isLoading) return <LoadingTopSnake />;

    return <> {children} </>;
};

export default memo(InitEditor);
