import React from "react";

interface IBackgroundIcon {
    fill?: string;
}
const BackgroundIcon = ({ fill = "#518eed" }: IBackgroundIcon) => {
    return (
        <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.1284 9.06797L10.8242 4.77899L11.954 3.65042C12.16 3.4446 12.1602 3.11069 11.9544 2.90466C11.7486 2.6986 11.4147 2.69844 11.2086 2.90423L4.84511 9.2608C3.95612 9.50883 3.302 10.326 3.302 11.293C3.302 11.5842 3.53809 11.8203 3.82934 11.8203C4.1206 11.8203 4.35669 11.5842 4.35669 11.293C4.35669 10.8837 4.59101 10.5282 4.93252 10.3534L9.39373 14.7993C9.8049 15.2101 10.3447 15.4154 10.8847 15.4153C11.4247 15.4153 11.9647 15.2098 12.3756 14.799L15.1295 12.0522C15.528 11.6537 15.7474 11.1238 15.7473 10.5602C15.7472 9.99662 15.5276 9.46686 15.1284 9.06797ZM14.3842 11.3059L11.6303 14.0528C11.2193 14.4638 10.5503 14.4639 10.1387 14.0527L6.31118 10.2383H12.1614C12.4526 10.2383 12.6887 10.0022 12.6887 9.71096C12.6887 9.41971 12.4526 9.18362 12.1614 9.18362H6.41475L10.078 5.52439L14.3834 9.81459C14.5828 10.0138 14.6926 10.2786 14.6926 10.5605C14.6927 10.8422 14.583 11.1071 14.3842 11.3059Z"
                fill="#1D1D1B"
            />
            <path
                d="M4.75796 13.2782C4.48962 12.8776 4.22427 12.5538 4.21309 12.5402C4.11292 12.4183 3.96342 12.3477 3.80566 12.3477C3.64791 12.3477 3.4984 12.4183 3.39824 12.5402C3.38706 12.5538 3.1217 12.8776 2.85336 13.2782C2.31254 14.0857 2.25 14.4731 2.25 14.6944C2.25 15.5522 2.94789 16.25 3.80566 16.25C4.66344 16.25 5.36132 15.5522 5.36132 14.6944C5.36132 14.4731 5.29878 14.0857 4.75796 13.2782ZM3.80566 15.1953C3.52941 15.1953 3.30469 14.9706 3.30466 14.6956C3.30476 14.6935 3.31853 14.4776 3.73413 13.8585C3.75804 13.8229 3.78193 13.7879 3.80566 13.7538C3.82937 13.7879 3.85328 13.8229 3.8772 13.8585C4.28557 14.4669 4.30598 14.6859 4.30664 14.6953C4.30611 14.9711 4.08157 15.1953 3.80566 15.1953Z"
                fill="#1D1D1B"
            />
        </svg>
    );
};

export default BackgroundIcon;
