import React from "react";
import { ResetButton } from "../buttons/buttons";
import { ReactComponent as AlertIcon } from "../../assets/icons/alert.svg";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import {
    IErrorPerItem,
    setOpenErrorSection,
} from "../../redux/features/errorListPerItemSlice";
import { device } from "../../assets/styles/global";

const StyledButton = styled(ResetButton)`
    position: relative;
    @media (${device.mobile}) {
        position: fixed;
        bottom: calc(var(--mobile-side-bar-height) + 10px);
        left: 20px;
        background-color: var(--white);
        border-radius: 50%;
        width: 50px;
        height: 50px;
    }
    svg {
        width: 30px;
        height: 30px;
    }
    span {
        position: absolute;
        top: -5px;
        right: 0px;
        background-color: var(--primary-color-brand);
        width: var(--medium-gap);
        height: var(--medium-gap);
        border-radius: 50%;
        font-size: var(--small-font-size);
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
const ErrorListButton = ({ ...props }) => {
    const errorListPerItem = useAppSelector((state) => state.errorListPerItem);
    const { openErrorSection, errorPerItem } = errorListPerItem;
    const dispatch = useAppDispatch();
    const countErrors = errorPerItem.reduce(
        (acc: number, item: IErrorPerItem) => acc + item.errors.length,
        0,
    );

    if (countErrors === 0) {
        return null;
    }

    return (
        <StyledButton
            {...props}
            onClick={() => dispatch(setOpenErrorSection(!openErrorSection))}
        >
            <AlertIcon />
            <span>{countErrors}</span>
        </StyledButton>
    );
};

export default ErrorListButton;
