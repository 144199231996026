import { useQueryString } from "../context/QueryStringProvider";

const targetOrigins = [
    "http://127.0.0.1:8081",
    "https://pix.co.il/",
    "https://local-pix.co.il/",
    "https://sandbox.pix.co.il/",
    "http://localhost:3000/",
    "https://sandbox.stickit.co.il/",
    "https://stickit.co.il/",
];

export const getTargetOrigin = () => {
    const { origin } = useQueryString();
    if (!origin) {
        return false;
    }
    return targetOrigins.includes(origin) ? origin : false;
};
