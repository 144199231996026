import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ISnapPointElement {
    id: string;
    top: number;
    left: number;
    right: number;
    bottom: number;
    center: {
        x: number;
        y: number;
    };
    w: number;
    h: number;
}

export interface ISnapPoint {
    top: number;
    left: number;
    w?: number;
    h?: number;
    show?: boolean;
}

export interface ISnapPointsPage {
    snapPointsCanvas: ISnapPoint[];
    snapPointElements?: Record<string, ISnapPointElement>;
}

interface IInitialState {
    pages: ISnapPointsPage[] | [];
    isLoading: boolean;
    isMove: boolean;
}

const initialState: IInitialState = {
    pages: [],
    isLoading: true,
    isMove: false,
};

interface ISetSnapPointsByPagePayload extends ISnapPointsPage {
    pageIndex: number;
}

const snapPointsSlice = createSlice({
    initialState,
    name: "snapPoints",
    reducers: {
        setSnapPoints(state, action: PayloadAction<ISnapPointsPage[]>) {
            state.pages = action.payload;
            state.isLoading = false;
        },
        setIsMove(state, action: PayloadAction<boolean>) {
            state.isMove = action.payload;
        },
        setSnapPointsByPage(
            state,
            action: PayloadAction<ISetSnapPointsByPagePayload>,
        ) {
            const { pageIndex, snapPointsCanvas, snapPointElements } =
                action.payload;
            state.pages[pageIndex] = {
                snapPointsCanvas,
                snapPointElements: snapPointElements || {},
            };
        },
        removeSnapPointsElement(
            state,
            action: PayloadAction<{ pageIndex: number; elementId: string }>,
        ) {
            const { pageIndex, elementId } = action.payload;
            const snapPointsPage = state.pages[pageIndex];
            if (snapPointsPage && snapPointsPage.snapPointElements) {
                delete snapPointsPage.snapPointElements[elementId];
            }
        },
        addSnapPointsElement(
            state,
            action: PayloadAction<{
                pageIndex: number;
                points: ISnapPointElement;
            }>,
        ) {
            const { pageIndex, points } = action.payload;
            const snapPointsPage = state.pages[pageIndex];
            if (snapPointsPage) {
                if (!snapPointsPage.snapPointElements) {
                    snapPointsPage.snapPointElements = {};
                }
                snapPointsPage.snapPointElements[points.id] = points;
            }
        },
    },
});

export const {
    setSnapPoints,
    setSnapPointsByPage,
    removeSnapPointsElement,
    addSnapPointsElement,
    setIsMove,
} = snapPointsSlice.actions;
export default snapPointsSlice.reducer;
