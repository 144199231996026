import React from "react";
import styled from "styled-components";
import { device } from "../../assets/styles/global";

export const StyledSideBarTitle = styled.h2`
    font-size: var(--font-size);

    @media (${device.mobile}) {
        background-color: var(--background-text-box);
        margin: calc(-1 * var(--small-gap));
        padding: var(--small-gap);
        margin-bottom: var(--extra-small-gap);
    }
`;

export const StyledSideBarSubTitle = styled.h3`
    font-weight: 500;
    font-size: var(--secondary-font-size);
`;

export const StyledSideBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--medium-gap);
`;

export const StyledSideBarInner = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--extra-small-gap);
`;

export const StyledTitleToolBar = styled.span`
    font-size: var(--small-font-size);
    width: 52px;
`;
