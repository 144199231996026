import React, { useCallback, useContext, useEffect } from "react";
import { Range } from "react-range";
import styled from "styled-components";
import { EditorDataContext } from "../../context/EditorDataProvider";
import { PageContext } from "../../context/PageProvider";
import { IEditorDataItem } from "../../interfaces/editor-data.interface";
const StyledRange = styled.div`
    direction: ltr;
    padding: var(--large-gap);
    display: flex;
    gap: var(--small-gap);
    align-items: center;
`;
const Zoom = () => {
    const { page }: any = useContext(PageContext);
    const { editorData, updateScale }: any = useContext(EditorDataContext);
    const items = editorData[page].items;
    const focusedItem = items.find((item: IEditorDataItem) => item.focus);
    const scale = editorData[page].scale ? editorData[page].scale : 1;
    const min = 0;
    const max = 300;
    const onChange = useCallback(
        (values: number[]) => {
            const scale = values[0] / 100;
            updateScale(scale);
        },
        [updateScale],
    );

    const handleKeyDown = useCallback(
        (e: KeyboardEvent) => {
            if (e.ctrlKey) {
                e.preventDefault();
                if (e.key === "+" || e.key === "=") {
                    updateScale(Math.min(scale + 0.1, max / 100));
                } else if (e.key === "-") {
                    updateScale(Math.max(scale - 0.1, min / 100));
                } else if (e.key === "0") {
                    updateScale(1);
                }
            }
        },
        [scale, updateScale, min, max],
    );

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    if (focusedItem?.isFixCropped) {
        return null;
    }

    const rangeValue = scale * 100;
    return (
        <StyledRange className="hide-mobile">
            <Range
                step={1}
                min={min}
                max={max}
                values={[rangeValue]}
                onChange={(values: any) => onChange(values)}
                renderTrack={({ props, children }) => {
                    const percent = ((rangeValue - min) / (max - min)) * 100;
                    return (
                        <div
                            {...props}
                            style={{
                                height: "10px",
                                width: "100%",
                                maxWidth: "340px",
                                borderRadius: "var(--main-border-radius)",
                                backgroundColor: "var(--main-border-color)",
                                position: "relative",
                            }}
                        >
                            <div
                                style={{
                                    position: "absolute",
                                    height: "10px",
                                    width: `${percent}%`,
                                    borderRadius: "var(--main-border-radius)",
                                    backgroundColor:
                                        "var(--primary-color-brand)",
                                }}
                            />
                            {children}
                        </div>
                    );
                }}
                renderThumb={({ props }) => (
                    <div
                        {...props}
                        style={{
                            cursor: "pointer",
                            height: "22px",
                            width: "22px",
                            boxShadow: "var(--main-shadow)",
                            borderRadius: "100%",
                            backgroundColor: "var(--white)",
                        }}
                    />
                )}
            />
            <div>{Math.floor(rangeValue)}%</div>
        </StyledRange>
    );
};

export default Zoom;
