import styled from "styled-components";
import { hexToRgb } from "../../../utils/utils";
import { RGBColor } from "react-color";
import { borderBox } from "../../../utils/editor-items";

const sizeSquare = 8.5;

interface IOuterStyled {
    placeHolder?: boolean;
    currentItem: boolean;
    borderWidth: number | boolean;
    dragged: boolean;
    hasRotate: boolean;
    oppositeScale: number;
    borderColor?: string;
    opacity?: number;
    borderColorRgb?: RGBColor;
    elemHeight: number;
    elemWidth: number;
    hasBorder: boolean;
    lock: boolean | undefined;
    cropElement?: boolean;
    isContentBox: boolean;
}

const pointsSize = 12;

const OuterStyled = styled.div<IOuterStyled>`
    img,
    svg {
        ${({ opacity }) => (opacity ? `opacity:${opacity / 100};` : ``)};
    }
    [data-text="true"] {
        ${({ opacity }) => (opacity ? `opacity:${opacity / 100};` : ``)};
    }
    span {
        ${({ placeHolder }) => (placeHolder ? `opacity:0.4;` : ``)};
    }

    & > div {
        box-sizing: ${({ isContentBox }) =>
            isContentBox ? "content-box" : "border-box"};
        border: ${({ hasBorder }) =>
            hasBorder ? `${borderBox}px solid transparent` : "none"};
        border-color: ${({ currentItem, borderWidth }) =>
            currentItem && !borderWidth ?
                "var(--primary-color-brand)"
            :   `transparent`};
        font-weight: bold;
        cursor: ${({ lock }) => (lock ? "default" : "move")};
        background-color: transparent;
        :hover {
            border-color: var(--primary-color-brand);
        }
    }

    .single-resizer .rotate {
        display: ${({ currentItem, dragged, hasRotate }) =>
            currentItem && !dragged && hasRotate ? `flex;` : `none`};
        ${({ lock }) => (lock ? "display:none!important;" : "")}
        width: 24px;
        height: 24px;
        border-radius: 15.5px;
        margin: 0;
        /* border: solid 1px var(--primary-color-brand); */
        top: auto;
        background-color: var(--white);
        box-shadow: var(--main-shadow);
        padding: 2px;
        align-items: center;
        bottom: ${({ oppositeScale }) =>
            `${oppositeScale >= 1 ? `-${40 * oppositeScale}px` : `-30px`}`};
        left: 50%;
        transform: translate(-50%, 0)
            scale(${({ oppositeScale }) => oppositeScale});
        cursor: grabbing;
    }

    .rotate {
        i {
            display: none;
        }
    }

    .single-resizer .square {
        display: none;
    }

    .single-resizer .resizable-handler {
        display: ${({ currentItem, dragged }) =>
            currentItem && !dragged ? `block;` : `none`};
        transform: scale(${({ oppositeScale }) => oppositeScale});
        width: ${pointsSize}px;
        height: ${pointsSize}px;
        border-radius: 7.5px;
        border: solid 1px var(--primary-color-brand);
        background-color: white;
        &:hover,
        &:active {
            background-color: var(--primary-color-brand);
            display: block;
        }

        &.r,
        &.l {
            width: 8px;
            height: 20px;
            margin: 0;
            top: 50%;
            transform: translate(0, -50%)
                scale(${({ oppositeScale }) => oppositeScale});
            ${({ elemHeight, oppositeScale }) => {
                return `
                &:after{
                    content:"";
                    position:absolute;
                    top:-${elemHeight / oppositeScale / 2 - pointsSize}px;
                    height:${elemHeight / oppositeScale}px;
                    right:0;
                    left:0;
                }
            `;
            }}
        }
        &.t,
        &.b {
            width: 20px;
            height: 8px;
            left: 50%;
            margin-left: 0;
            transform: translate(-50%, 0)
                scale(${({ oppositeScale }) => oppositeScale});
            ${({ elemWidth, oppositeScale }) => {
                return `
                &:after{
                    content:"";
                    left:-${elemWidth / oppositeScale / 2 - pointsSize}px;
                    position:absolute;
                    width:${elemWidth / oppositeScale}px;
                    top:0;
                    bottom:0;
                }
            `;
            }}
        }

        &.l {
            left: -6px;
        }
        &.r {
            right: -6px;
        }
        &.tl,
        &.tr {
            top: -7px;
        }
        &.bl,
        &.tl {
            left: -7px;
        }
        &.tr,
        &.br {
            right: -7px;
        }
        &.bl,
        &.br {
            bottom: -7px;
        }
    }

    ${({
        borderWidth,
        borderColor,
        opacity,
        oppositeScale,
        borderColorRgb,
    }) => {
        if (borderWidth) {
            const borderW = (borderWidth as number) * 2;
            const sizeFromSquare = sizeSquare + borderW;
            opacity = opacity ? opacity / 100 : 1;
            const rgba =
                borderColorRgb ?
                    `rgba(${borderColorRgb.r},${borderColorRgb.g},${borderColorRgb.b},${opacity})`
                :   hexToRgb(borderColor as string, opacity);

            return `
           .single-resizer .rotate{
                bottom:${oppositeScale >= 1 ? `-${(40 + borderW) * oppositeScale}px` : `-${30 + borderW}px`};
            } 
            & > div{
                border:${borderW}px solid ${rgba}!important;
            }
            
            .single-resizer .resizable-handler{
                &.l{left:-${sizeFromSquare - 4}px;}
                &.r{right:-${sizeFromSquare - 4}px;}
                &.tl,&.tr{top:-${sizeFromSquare}px;}
                &.bl,&.tl{left:-${sizeFromSquare}px;}
                &.tr,&.br{right:-${sizeFromSquare}px;}
                &.bl,&.br{bottom:-${sizeFromSquare}px;}
            }
        `;
        }
    }}
`;

export default OuterStyled;
