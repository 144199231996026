import React, { useContext } from "react";
import { ReactComponent as RedoIcon } from "../../../assets/icons/redo.svg";
import { HistoryContext } from "../../../context/HistoryProvider";
import RedoAndUndo from "./RedoAndUndo";
import { ERedoAndUndoAction } from "../../../enums/redo-and-undo.enum";

const Redo = () => {
    const { numStateRedo }: any = useContext(HistoryContext);
    return (
        <RedoAndUndo
            action={ERedoAndUndoAction.REDO}
            disabled={numStateRedo === 0}
        >
            <RedoIcon className="toolbar-icon" />
        </RedoAndUndo>
    );
};

export default Redo;
