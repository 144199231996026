import React, { useEffect } from "react";
import TextBox from "../editorWidgets/TextBox";
import ImageBox from "../editorWidgets/ImageBox";
import styled from "styled-components";
import EditorWidget from "../editorWidgets/utils/EditorWidget";
import Helper from "./Helper";
import {
    IEditorDataItem,
    IEditorDataPage,
    IEditorDataPageItemImage,
    IEditorDataPageItemSvg,
    IEditorDataPageItemText,
} from "../../interfaces/editor-data.interface";
import { EEditorDataItemTypes } from "../../enums/editor-data-item-type.enum";
import SvgBox from "../editorWidgets/SvgBox";
import BackgroundBox from "../editorWidgets/BackgroundBox";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { setPrintAreaClientRect } from "../../redux/features/printAreaClientRectSlice";
import { getOppositeScale } from "../../utils/utils";
import SnapPointsRenderer from "../snaps/SnapPointsRenderer";

interface IPrintAreaContainerStyled {
    bgColor?: string;
}
const PrintAreaContainerStyled = styled.div<IPrintAreaContainerStyled>`
    width: inherit;
    height: inherit;
    background-color: ${({ bgColor }) => (bgColor ? bgColor : "white")};
    box-shadow:
        0 1px 3px 0 rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.12),
        0 0 2px 0 rgba(0, 0, 0, 0.14);
`;

interface IPrintArea {
    pageData: IEditorDataPage;
}
const PrintArea = ({ pageData }: IPrintArea) => {
    const scale = pageData.scale ? pageData.scale : 1;
    const oppositeScale = getOppositeScale(scale);

    const items = pageData.items;
    const dispatch = useAppDispatch();
    const itemBackground = items.find(
        (item: IEditorDataItem) =>
            item.type === EEditorDataItemTypes.BACKGROUND,
    );
    const printAreaRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (printAreaRef.current) {
            const clientRect = printAreaRef.current.getBoundingClientRect();
            dispatch(
                setPrintAreaClientRect({
                    w: clientRect.width * oppositeScale,
                    h: clientRect.height * oppositeScale,
                    x: clientRect.x * oppositeScale,
                    y: clientRect.y * oppositeScale,
                }),
            );
        }
    }, [pageData, dispatch, oppositeScale, printAreaRef?.current]);

    return (
        <PrintAreaContainerStyled
            ref={printAreaRef}
            bgColor={pageData?.bgColor}
        >
            <Helper />
            <SnapPointsRenderer />
            <EditorWidget>
                <BackgroundBox currentItem={itemBackground} />
            </EditorWidget>
            {items.map((item: IEditorDataItem) => {
                return (
                    <EditorWidget item={item} key={`editor-widget-${item.key}`}>
                        <>
                            {item.type === EEditorDataItemTypes.TEXT && (
                                <TextBox
                                    currentItem={
                                        item as IEditorDataPageItemText
                                    }
                                    key={item.key}
                                />
                            )}

                            {item.type === EEditorDataItemTypes.IMAGE && (
                                <ImageBox
                                    currentItem={
                                        item as IEditorDataPageItemImage
                                    }
                                    key={item.id}
                                />
                            )}

                            {item.type === EEditorDataItemTypes.SVG && (
                                <SvgBox
                                    currentItem={item as IEditorDataPageItemSvg}
                                    key={item.id}
                                />
                            )}
                        </>
                    </EditorWidget>
                );
            })}
        </PrintAreaContainerStyled>
    );
};

export default PrintArea;
