import circle from "../../../../assets/shapes/circle.svg";
import rectangle from "../../../../assets/shapes/rectangle.svg";
import roundedRectangle from "../../../../assets/shapes/rounded_rectangle.svg";
import triangle from "../../../../assets/shapes/triangle.svg";
import hexagon from "../../../../assets/shapes/hexagon.svg";

interface IListElements {
    groupName: string;
    groupItems: IListElement[];
}

export interface IListElement {
    title?: string;
    shape?: EShapes;
    svg: string;
}

export enum EShapes {
    CIRCLE = "circle",
    RECTANGLE = "rectangle",
    ROUND_RECTANGLE = "round_rectangle",
    TRIANGLE = "triangle",
    HEXAGON = "hexagon",
}

const listElements: IListElements[] = [
    {
        groupName: "צורות",
        groupItems: [
            { title: "עיגול", svg: circle, shape: EShapes.CIRCLE },
            { title: "מלבן", svg: rectangle, shape: EShapes.RECTANGLE },
            {
                title: "מלבן מעוגל",
                svg: roundedRectangle,
                shape: EShapes.ROUND_RECTANGLE,
            },
            { title: "משלולש", svg: triangle, shape: EShapes.TRIANGLE },
            { title: "משושה", svg: hexagon, shape: EShapes.HEXAGON },
        ],
    },
];
export default listElements;
