import React, { useContext, useRef } from "react";
import ToolbarBtn from "../../buttons/ToolbarBtn";
import Tooltip, { ETooltipPlace } from "../../tool-tip/Tooltip";
import { PageContext } from "../../../context/PageProvider";
import { EditorDataContext } from "../../../context/EditorDataProvider";
import {
    IEditorDataItem,
    IEditorDataPageItemText,
} from "../../../interfaces/editor-data.interface";
import { ReactComponent as LockIcon } from "../../../assets/icons/lock.svg";
import { ReactComponent as UnLockIcon } from "../../../assets/icons/unlock.svg";
import { nanoid } from "nanoid";
import { StyledTitleToolBar } from "../../style-components/styleComponents";

interface ILock {
    showToolBarTxt?: boolean;
}
const Lock = ({ showToolBarTxt = true }: ILock) => {
    const padlock = useRef(null);
    const padlockOpen = useRef(null);
    const { page }: any = useContext(PageContext);
    const { editorData, updateItems }: any = useContext(EditorDataContext);
    const items = editorData[page].items;
    const focusedItem = items.find((item: IEditorDataItem) => item.focus);
    const { lock } = focusedItem;
    const padlockToolTip = `padlockToolTip-${nanoid()}`;
    const padlockOpenToolTip = `padlockOpenToolTip-${nanoid()}`;

    const txtUnLook = "שחרר אובייקט";
    const txtLook = "נעל אובייקט";

    return (
        <>
            {lock && (
                <>
                    <ToolbarBtn
                        ref={padlock}
                        data-tip
                        data-for={padlockToolTip}
                        onClick={() => {
                            updateItems(
                                items.map((item: IEditorDataPageItemText) => {
                                    if (item.focus) item.lock = false;
                                    return item;
                                }),
                            );
                        }}
                    >
                        <LockIcon />
                        <StyledTitleToolBar>שחרר</StyledTitleToolBar>
                    </ToolbarBtn>
                </>
            )}

            {!lock && (
                <>
                    <ToolbarBtn
                        ref={padlockOpen}
                        data-tip
                        data-for={padlockOpenToolTip}
                        onClick={() => {
                            updateItems(
                                items.map((item: IEditorDataPageItemText) => {
                                    if (item.focus) item.lock = true;
                                    return item;
                                }),
                            );
                        }}
                    >
                        <UnLockIcon />
                        {showToolBarTxt && (
                            <StyledTitleToolBar className="hide-desktop">
                                נעל
                            </StyledTitleToolBar>
                        )}
                    </ToolbarBtn>
                    <Tooltip
                        place={ETooltipPlace.BOTTOM}
                        id={padlockOpenToolTip}
                        elemRef={padlockOpen}
                    >
                        {txtLook}
                    </Tooltip>
                </>
            )}
        </>
    );
};

export default Lock;
