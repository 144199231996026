import React from "react";
import ReactLoading from "react-loading";
import { useAppSelector } from "../../redux/hook";
import styled from "styled-components";
import tw from "twin.macro";

const StyledLoadingSnake = styled.div`
    ${tw`absolute top-0 left-0 right-0 bottom-0 z-50
    flex items-center justify-center
  `}
    background-color: rgba(0,0,0,.4);
`;

export const LoadingSnake = () => {
    const { brandConfig } = useAppSelector((state) => state.brandConfig);
    const { primaryColor } = brandConfig;
    return (
        <StyledLoadingSnake>
            <ReactLoading
                type="bars"
                color={primaryColor}
                height={55}
                width={55}
            />
        </StyledLoadingSnake>
    );
};

export default LoadingSnake;
