import React, { useContext } from "react";
import styled from "styled-components";
import { MainButton, SecondaryButton } from "../buttons/buttons";
import Undo from "../buttons/RedoAndUndo/Undo";
import Popup from "../popups/Popup";
import Redo from "../buttons/RedoAndUndo/Redo";
import { EditorDataContext } from "../../context/EditorDataProvider";
import useGeneratePdf from "../../hooks/useGeneratePdf";
import LoadingTopSnake from "../loading-snake/loading-top-snake";
import SaveProject from "../SaveProject";
import ProjectName from "../project-name/ProjectName";
import Logo from "../logo/Logo";
import { device } from "../../assets/styles/global";
import useIsMobile from "../../hooks/useIsMobile";
import Nav from "../nav/Nav";
import ErrorListButton from "../error-list-per-item/ErrorListButton";
import CloseEditor from "../close-editor/CloseEditor";
import ToggleVisibilityHelper from "../toggle-visibility-helper/ToggleVisibilityHelper";
import { useTranslation } from "react-i18next";

const Container = styled.div`
    height: var(--top-bar-menu-height);
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 22;
    box-shadow: var(--main-shadow);
    padding-left: var(--large-gap);
    padding-right: var(--small-gap);
    @media (${device.mobile}) {
        --size-toolbar-icons: 22px;
        padding: 0 var(--small-gap);
        .endOfEditing {
            min-width: 77px;
            height: 36px;
        }
    }
`;
const InnerContainer = styled.div`
    display: flex;
    align-items: center;
    gap: var(--medium-gap);
    @media (${device.desktop}) {
        &.right {
            width: 70%;
        }
    }
    @media (${device.mobile}) {
        gap: var(--large-gap);
    }
`;

const TopBarMenu = () => {
    /*TODO @jonathandana check type any*/
    const { editorData }: any = useContext(EditorDataContext);
    const { isFetching, refetch, pdfSrc, setPdfSrc }: any =
        useGeneratePdf(editorData);
    const isMobile = useIsMobile();
    const { t } = useTranslation();

    return (
        <Container>
            <InnerContainer className="right">
                <Logo className="hide-mobile" />
                <CloseEditor className="hide-mobile" />
                <Nav
                    isFetching={isFetching}
                    refetch={refetch}
                    className="hide-desktop"
                />
                {isMobile && <SaveProject />}
                <Undo />
                <Redo />
                <ToggleVisibilityHelper className="hide-mobile" />
                <ProjectName className="hide-mobile" />
            </InnerContainer>

            <InnerContainer className="left">
                {isFetching && <LoadingTopSnake />}

                {pdfSrc && (
                    <Popup
                        isOpen={pdfSrc}
                        onClosePopup={() => {
                            setPdfSrc(false);
                        }}
                    >
                        <>
                            {pdfSrc && (
                                <iframe
                                    src={`data:application/pdf;base64,base64encodedpdf ${pdfSrc}`}
                                    width="100%"
                                    height="500px"
                                />
                            )}
                        </>
                    </Popup>
                )}

                <ErrorListButton />
                {!isMobile && <SaveProject />}

                <SecondaryButton
                    className="preview hide-mobile"
                    onClick={refetch}
                >
                    {t("preview")}
                </SecondaryButton>
                <MainButton className="endOfEditing" onClick={refetch}>
                    {t("continue")}
                </MainButton>
            </InnerContainer>
        </Container>
    );
};

export default TopBarMenu;
