import React from "react";
import styled from "styled-components";

interface GridProps {
    itemsPerRow: number;
    gap?: string;
}

const GridContainer = styled.div<GridProps>`
    display: grid;
    grid-template-columns: repeat(${(props) => props.itemsPerRow}, 1fr);
    gap: ${(props) => props.gap || "10px"};
    align-items: center;
`;

const Grid: React.FC<GridProps> = ({ children, itemsPerRow, gap }) => {
    return (
        <GridContainer itemsPerRow={itemsPerRow} gap={gap}>
            {children}
        </GridContainer>
    );
};

export default Grid;
