// useFoldColorsLogic.js
import { useContext } from "react";
import { PageContext } from "../context/PageProvider";
import {
    EUpdateEditorActions,
    EditorDataContext,
} from "../context/EditorDataProvider";
import {
    IEditorDataItem,
    IEditorDataPageItemText,
} from "../interfaces/editor-data.interface";
import updateStyleSvg from "../utils/svg/updateStyleSvg";
import { EEditorDataItemTypes } from "../enums/editor-data-item-type.enum";

const useColorsLogic = () => {
    const { page }: any = useContext(PageContext);
    const { editorData, updateItems, updateEditorData }: any =
        useContext(EditorDataContext);
    const items = editorData[page].items;
    const bgItem = items.find(
        (item: IEditorDataItem) =>
            item.type === EEditorDataItemTypes.BACKGROUND,
    );
    const defaultColor = "#000";
    const focusedItem = items.find((item: IEditorDataItem) => item.focus);
    let transparent = false;
    let initialColor = !bgItem ? defaultColor : bgItem.backgroundColor;
    let title = "רקעים";
    const { type, color, backgroundColor, lock } = focusedItem ?? {};
    if (!lock) {
        switch (type) {
            case EEditorDataItemTypes.TEXT:
                initialColor = color ? color : defaultColor;
                title = "צבע טקסט";
                break;
            case EEditorDataItemTypes.SVG:
                title = "צבע האלמנט";
                transparent = true;
                initialColor = backgroundColor ? backgroundColor : defaultColor;
                break;
        }
    }
    const handleChangeColor = (color: string) => {
        let updateBackgroundColor = true;
        updateItems(
            items.map((item: IEditorDataPageItemText) => {
                const { type, focus, svg, borderWidth, lock } = item;
                if (type === EEditorDataItemTypes.TEXT && focus && !lock) {
                    updateBackgroundColor = false;
                    item.color = color;
                }
                if (svg && focus && !lock) {
                    updateBackgroundColor = false;
                    item.backgroundColor = color ? color : "none";
                    const stroke = item.borderColor ? item.borderColor : "none";
                    item.svg = updateStyleSvg(svg, {
                        strokeWidth: borderWidth,
                        stroke,
                        fill: item.backgroundColor,
                    });
                }
                return item;
            }),
        );

        if (bgItem && updateBackgroundColor) {
            bgItem.backgroundColor = color;
            bgItem.backgroundSrc = false;
            updateEditorData(bgItem, EUpdateEditorActions.UPDATE_ITEM);
        }
    };

    return { title, initialColor, handleChangeColor, transparent };
};

export default useColorsLogic;
