import React, { useContext } from "react";
import { PageContext } from "../../context/PageProvider";
import { EditorDataContext } from "../../context/EditorDataProvider";
import styled from "styled-components";

const HelperStyled = styled.div`
    pointer-events: none;
    position: relative;
    z-index: 5;
`;
const Helper = () => {
    const { page }: any = useContext(PageContext);
    const { editorData }: any = useContext(EditorDataContext);
    const helperData = editorData[page]?.helper;
    const hideHelper = editorData[page]?.hideHelper;
    if (!helperData || hideHelper) return <></>;
    return (
        <HelperStyled
            dangerouslySetInnerHTML={{ __html: helperData }}
        ></HelperStyled>
    );
};

export default Helper;
