import React from "react";
import styled from "styled-components";
import { ISnapPoint } from "../../redux/features/snapPointsSlice";

const StyledSnapCanvas = styled.div<ISnapPoint>`
    pointer-events: none;
    position: absolute;
    z-index: 100;
    top: ${({ top }) => top}px;
    left: ${({ left }) => left}px;
    width: ${({ w }) => (w ? w : 2)}px;
    height: ${({ h }) => (h ? h : 2)}px;
    background-color: var(--snap-color);
    display: ${({ show }) => (show ? "block" : "none")};
`;

const SnapCanvas = ({ top, left, w, h, show }: ISnapPoint) => {
    return <StyledSnapCanvas w={w} h={h} top={top} left={left} show={show} />;
};

export default React.memo(SnapCanvas, (prevProps, nextProps) => {
    return prevProps.show === nextProps.show;
});
