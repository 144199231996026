import React, { memo } from "react";
import Modal from "../modal/modal";
import tw from "twin.macro";
import styled from "styled-components";

const StyledPopup = styled.div`
    ${tw`fixed top-0 left-0 right-0 bottom-0 z-20`}

    .overlay {
        background-color: rgba(0, 0, 0, 0.5);
        ${tw`fixed top-0 left-0 right-0 bottom-0 `}
    }

    .content-popup {
        ${tw`fixed z-30 bg-white w-full box-border `}
        max-height: calc(100vh - 20rem);
        max-width: 49.2rem;
        padding: 2rem;
        top: 8.2rem;
        left: 50%;
        transform: translate(-50%, 0);
        box-shadow:
            0 4px 5px 0 rgba(0, 0, 0, 0.2),
            0 3px 14px 3px rgba(0, 0, 0, 0.12),
            0 8px 10px 1px rgba(0, 0, 0, 0.14);
    }

    .close {
        position: absolute;
        left: 0.5rem;
        top: 0.5rem;
    }
`;

interface IPopup {
    children: React.ReactChild;
    isOpen: boolean;
    onClosePopup: any;
}
const Popup = ({ children, isOpen = false, onClosePopup }: IPopup) => {
    if (!isOpen) {
        return null;
    }

    return (
        <Modal>
            <StyledPopup>
                <div onClick={onClosePopup} className="overlay" />
                <div className="content-popup">
                    <button
                        onClick={onClosePopup}
                        className="close"
                        type="button"
                    >
                        X
                    </button>
                    {children}
                </div>
            </StyledPopup>
        </Modal>
    );
};

export default memo(Popup);
