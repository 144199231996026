import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Trash from "./tools/Trash";
import { EEditorDataItemTypes } from "../../enums/editor-data-item-type.enum";
import { IEditorDataItem } from "../../interfaces/editor-data.interface";
import Lock from "./tools/Lock";
import ToolBarTextItem from "./ToolBarTextItem";
import ToolBarImageItem from "./ToolBarImageItem";
import ToolBarSvgItem from "./ToolBarSvgItem";
import ToolBarGeneralItem from "./ToolBarGeneralItem";

export const ToolBarHeight = 55;
interface IToolBar {
    focusedItem: IEditorDataItem;
}

type argsToggle = EInnerMenu | boolean;

export enum EInnerMenu {
    ARRANGE = "arrange",
    BORDER_BOX = "border_box",
    OPACITY = "opacity",
    ALIGNMENT = "alignment",
}
export interface IToggleInnerMenu {
    innerMenu: argsToggle;
    toggleInnerMenu: (id: argsToggle) => void;
}

const StyleOptsContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

const ToolBar = ({ focusedItem }: IToolBar) => {
    const [innerMenu, setInnerMenu] = useState<argsToggle>(false);
    const toggleInnerMenu = (id: argsToggle) => setInnerMenu(id);
    const isFixCropped = focusedItem?.isFixCropped;
    const isTextItem =
        focusedItem.type === EEditorDataItemTypes.TEXT && !focusedItem.lock;
    const isImageItem =
        focusedItem.type === EEditorDataItemTypes.IMAGE && !focusedItem.lock;
    const isSvgItem =
        focusedItem.type === EEditorDataItemTypes.SVG && !focusedItem.lock;
    const isLocked = focusedItem.lock;
    const hasPlaceholder = focusedItem.placeHolder;

    useEffect(() => toggleInnerMenu(false), []);
    return (
        <StyleOptsContainer>
            {isTextItem && !hasPlaceholder && <ToolBarTextItem />}
            {isImageItem && !hasPlaceholder && <ToolBarImageItem />}
            {isSvgItem && !hasPlaceholder && (
                <ToolBarSvgItem
                    innerMenu={innerMenu}
                    toggleInnerMenu={toggleInnerMenu}
                />
            )}

            {!isFixCropped && !hasPlaceholder && (
                <>
                    {!isLocked && (
                        <ToolBarGeneralItem
                            innerMenu={innerMenu}
                            toggleInnerMenu={toggleInnerMenu}
                        />
                    )}
                    <Lock />
                    {!isLocked && <Trash />}
                </>
            )}
            {hasPlaceholder && <Trash />}
        </StyleOptsContainer>
    );
};

export default ToolBar;
