import { createSlice } from "@reduxjs/toolkit";
import branchConfigJson from "../../brand-config/brandConfig.json";

enum EBrands {
    PIX = "pix",
    STICKIT = "stickit",
}

const getBrand = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlOrigin = urlParams.get("origin");
    let brand = EBrands.STICKIT;

    if (urlOrigin?.includes(EBrands.PIX)) {
        brand = EBrands.PIX;
    }

    return brand;
};

const brand = getBrand();

export interface IGeneratePdf {
    local: string;
    sandbox: string;
    staging: string;
    production: string;
}
interface IInitialState {
    brandConfig: {
        fontFamily: string;
        logo: string;
        primaryColor: string;
        secondaryColor: string;
        generatePdf: IGeneratePdf;
    };
}

const initialState: IInitialState = {
    brandConfig: branchConfigJson[brand],
};

const brandConfigSlice = createSlice({
    initialState,
    name: "brandConfig",
    reducers: {
        setBrandConfig(state, action) {
            state.brandConfig = action.payload;
        },
    },
});

export const { setBrandConfig } = brandConfigSlice.actions;
export default brandConfigSlice.reducer;
