import React from "react";
import { useAppSelector } from "../../redux/hook";
import pix from "../../brand-config/images/pix-logo.svg";
import stickit from "../../brand-config/images/stick-it-logo.svg";
import styled from "styled-components";

const StyledImage = styled.img`
    max-width: 55px;
`;

const Logo = ({ ...props }) => {
    const { brandConfig } = useAppSelector((state) => state.brandConfig);
    const { logo } = brandConfig;

    let imageLogo = "";
    switch (logo) {
        case "pix":
            imageLogo = pix;
            break;
        case "stickit":
            imageLogo = stickit;
            break;
    }

    return <StyledImage {...props} src={imageLogo} alt="לוגו" />;
};

export default Logo;
