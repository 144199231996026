import React, { useEffect } from "react";
import { useQueryString } from "./context/QueryStringProvider";

const MessageHandler = () => {
    const { setStorageToken } = useQueryString();
    useEffect(() => {
        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);

    const handleMessage = (event: MessageEvent) => {
        const { action, token } = event.data;
        if (action === "storageToken") {
            setStorageToken(token);
        }
    };
    return null;
};

export default MessageHandler;
