import React, { ReactNode, useContext } from "react";
import { PageContext } from "../../../context/PageProvider";
import { EditorDataContext } from "../../../context/EditorDataProvider";
import { IEditorDataItem } from "../../../interfaces/editor-data.interface";
import { EInnerMenu, IToggleInnerMenu } from "../ToolBar";
import ToolWithOptions from "../ToolWithOptions";
import { EEditorDataItemTypes } from "../../../enums/editor-data-item-type.enum";
import { ReactComponent as ArrangeIcon } from "../../../assets/icons/arrange.svg";

import { ReactComponent as BackgroundIcon } from "../../../assets/icons/move_background.svg";
import { ReactComponent as FrontIcon } from "../../../assets/icons/move_front.svg";
import { ReactComponent as BackIcon } from "../../../assets/icons/move_back.svg";
import { ReactComponent as ForwardIcon } from "../../../assets/icons/move_forward.svg";

type TypeActions =
    | "MOVE_BACKGROUND"
    | "MOVE_FRONT"
    | "MOVE_BACK"
    | "MOVE_FORWARD";
interface IArrangeOptions {
    title: string;
    action: TypeActions;
    icon: ReactNode;
}

const ActionIcons = {
    MOVE_BACKGROUND: <BackgroundIcon />,
    MOVE_FRONT: <FrontIcon />,
    MOVE_BACK: <BackIcon />,
    MOVE_FORWARD: <ForwardIcon />,
};

const arrangeOptions: IArrangeOptions[] = [
    {
        title: "העבר לרקע",
        action: "MOVE_BACKGROUND",
        icon: ActionIcons.MOVE_BACKGROUND,
    },
    { title: "העבר לחזית", action: "MOVE_FRONT", icon: ActionIcons.MOVE_FRONT },
    { title: "העבר אחורה", action: "MOVE_BACK", icon: ActionIcons.MOVE_BACK },
    {
        title: "העבר קדימה",
        action: "MOVE_FORWARD",
        icon: ActionIcons.MOVE_FORWARD,
    },
];

let has_go_front = false;
let has_go_back = false;
const Arrange = ({ innerMenu, toggleInnerMenu }: IToggleInnerMenu) => {
    const { page }: any = useContext(PageContext);
    const { editorData, updateOrderItems }: any = useContext(EditorDataContext);
    const items = editorData[page].items;
    const focusedItems = items.filter((item: IEditorDataItem) => item.focus);

    const handleOptionsClick = (action: TypeActions) => {
        let cp_items = items.filter((item: IEditorDataItem) => !item.focus);
        const bg_item = items.find(
            (item: IEditorDataItem) =>
                item.type === EEditorDataItemTypes.BACKGROUND,
        );
        let position = null;
        switch (action) {
            case "MOVE_BACKGROUND":
                has_go_back = focusedItems[0].id;
                position = 0;
                break;

            case "MOVE_FRONT":
                has_go_front = focusedItems[0].id;
                position = cp_items.length;
                break;

            case "MOVE_BACK":
                if (has_go_back === focusedItems[0].id) {
                    has_go_back = false;
                }

                position = has_go_back ? 1 : 0;
                break;

            case "MOVE_FORWARD":
                if (has_go_front === focusedItems[0].id) {
                    has_go_front = false;
                }
                position = has_go_front ? cp_items.length - 1 : cp_items.length;
                break;
        }

        if (position === null) return false;
        cp_items.splice(position, 0, focusedItems[0]);
        cp_items = cp_items.filter(
            (item: IEditorDataItem) =>
                item.type !== EEditorDataItemTypes.BACKGROUND,
        );
        if (bg_item) {
            updateOrderItems([bg_item, ...cp_items]);
        } else {
            updateOrderItems(cp_items);
        }
    };
    const handleClick = () =>
        toggleInnerMenu(
            innerMenu === EInnerMenu.ARRANGE ? false : EInnerMenu.ARRANGE,
        );

    return (
        <ToolWithOptions
            id="arrange"
            icon={<ArrangeIcon />}
            toolTipText="סדר"
            handleClick={handleClick}
            isInnerMenuOpen={innerMenu === EInnerMenu.ARRANGE}
            handleClickOptions={handleOptionsClick}
            handleDropDownOnClose={() => {
                toggleInnerMenu(false);
            }}
            options={arrangeOptions}
        />
    );
};

export default Arrange;
