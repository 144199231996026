import { Dispatch } from "@reduxjs/toolkit";
import { IPropsBox } from "../../components/editorWidgets/utils/Resizable";
import { EEditorDataItemTypes } from "../../enums/editor-data-item-type.enum";
import { IEditorDataItem } from "../../interfaces/editor-data.interface";
import {
    EErrorType,
    addErrorPerItem,
    delErrorPerItem,
} from "../../redux/features/errorListPerItemSlice";
import { marginBleed } from "../error-items";

interface IValidationBleed {
    currentItem: IEditorDataItem;
    propsBox: IPropsBox;
    bleed: number | null;
    includeBorder: number;
    pageHeight: number;
    pageWidth: number;
    dispatch: Dispatch;
}

export const validationBleed = ({
    currentItem,
    propsBox,
    bleed,
    includeBorder,
    pageHeight,
    pageWidth,
    dispatch,
}: IValidationBleed) => {
    if (!bleed) {
        return false;
    }

    const { width, height, top, left, rotation } = propsBox;
    const border =
        currentItem.type === EEditorDataItemTypes.SVG || !includeBorder ?
            0
        :   includeBorder;

    const elWidthWithBorder = width + border * 2;
    const elHeightWithBorder = height + border * 2;

    const rotationInRadians = rotation * (Math.PI / 180);
    const elemRotatedWidth =
        Math.abs(Math.cos(rotationInRadians) * elWidthWithBorder) +
        Math.abs(Math.sin(rotationInRadians) * elHeightWithBorder);
    const elemRotatedHeight =
        Math.abs(Math.sin(rotationInRadians) * elWidthWithBorder) +
        Math.abs(Math.cos(rotationInRadians) * elHeightWithBorder);
    const elemRotatedTop = top + (elHeightWithBorder - elemRotatedHeight) / 2;
    const elemRotatedLeft = left + (elWidthWithBorder - elemRotatedWidth) / 2;

    const elemRotatedBottom = elemRotatedTop + elemRotatedHeight;
    const elemRotatedRight = elemRotatedLeft + elemRotatedWidth;

    const bleedBottom = pageHeight - bleed;
    const bleedRight = pageWidth - bleed;

    const outsideOfTheBleed =
        bleed > elemRotatedTop ||
        bleed > elemRotatedLeft ||
        elemRotatedBottom > bleedBottom ||
        elemRotatedRight > bleedRight;
    const outsideOfTheCanvas =
        0 >= elemRotatedTop ||
        0 >= elemRotatedLeft ||
        elemRotatedBottom > pageHeight ||
        elemRotatedRight > pageWidth;
    const CloseOfTheBleed =
        marginBleed + bleed > elemRotatedTop ||
        marginBleed + bleed > elemRotatedLeft ||
        elemRotatedBottom > bleedBottom - marginBleed ||
        elemRotatedRight > bleedRight - marginBleed;

    const { id } = currentItem;

    /*Validation for TextBox*/
    if (currentItem.type === EEditorDataItemTypes.TEXT) {
        if (outsideOfTheBleed) {
            dispatch(
                addErrorPerItem({
                    id,
                    errorType: EErrorType.OUTSIDE_BLEED,
                }),
            );
        } else {
            dispatch(
                delErrorPerItem({
                    id,
                    errorType: EErrorType.OUTSIDE_BLEED,
                }),
            );
        }
    } else {
        if (outsideOfTheBleed && !outsideOfTheCanvas) {
            dispatch(
                addErrorPerItem({
                    id,
                    errorType: EErrorType.WHITE_STRIP,
                }),
            );
        } else {
            dispatch(
                delErrorPerItem({
                    id,
                    errorType: EErrorType.WHITE_STRIP,
                }),
            );
        }
    }
    /*Close of the bleed*/
    if (CloseOfTheBleed && !outsideOfTheBleed) {
        dispatch(addErrorPerItem({ id, errorType: EErrorType.CLOSE_BLEED }));
    } else {
        dispatch(delErrorPerItem({ id, errorType: EErrorType.CLOSE_BLEED }));
    }
};
