import React from "react";
import styled from "styled-components";
import { FontTelloSpan } from "../../assets/Fontello/FontTello";
import { ResetButton } from "./buttons";

interface IArrowButton {
    children?: React.ReactNode;
    before?: boolean;
    handleOnclick: () => void;
    className?: string;
    style?: React.CSSProperties;
}

const StyledArrowButton = styled(ResetButton)`
    display: flex;
    gap: 5px;
    align-items: center;
    .icon-right-open,
    .icon-left-open {
        font-size: 0.7rem;
    }
`;

const ArrowButton = ({
    children,
    before = false,
    handleOnclick,
    className,
    ...props
}: IArrowButton) => {
    return (
        <StyledArrowButton
            onClick={() => handleOnclick()}
            className={className}
            {...props}
        >
            {before && <FontTelloSpan className="icon-right-open" />}
            {children}

            {!before && <FontTelloSpan className="icon-left-open" />}
        </StyledArrowButton>
    );
};

export default ArrowButton;
