import React, { useState, createContext } from "react";
import { IContextProviders } from "../interfaces/context-providers.interface";

export const CanvasBoundingClientRectContext = createContext<DOMRect[] | any>(
    [],
);
const CanvasBoundingClientRectProvider = ({ children }: IContextProviders) => {
    const [canvasBoundingClientRect, setCanvasBoundingClientRect] = useState<
        DOMRect[]
    >([]);
    const value = {
        canvasBoundingClientRect,
        setCanvasBoundingClientRect: (index: number, item: DOMRect) => {
            const updateCanvasBoundingClientRect = [
                ...canvasBoundingClientRect,
            ];
            updateCanvasBoundingClientRect[index] = item;
            setCanvasBoundingClientRect(updateCanvasBoundingClientRect);
        },
    };
    return (
        <CanvasBoundingClientRectContext.Provider value={value}>
            {children}
        </CanvasBoundingClientRectContext.Provider>
    );
};

export default CanvasBoundingClientRectProvider;
