import React from "react";
import MaskCrop from "./MaskCrop";
import { IEditorDataItem } from "../../../interfaces/editor-data.interface";

interface EEditorWidget {
    children: React.ReactChild;
    item?: IEditorDataItem;
}

const EditorWidget = ({ children, item }: EEditorWidget) => (
    <>
        {item?.isFixCropped && <MaskCrop item={item} />}
        <div
            style={{
                position: "relative",
                zIndex: item?.isFixCropped ? 101 : 2,
            }}
            className="editorWidget"
        >
            {children}
        </div>
    </>
);

export default EditorWidget;
