import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum EErrorType {
    OUTSIDE_BLEED = "שים לב! המיקום של האלמנט נמצא מחוץ לגבולות של החיתוך. ייתכן שהאלמנט יחתך בשלב הייצור.",
    CLOSE_BLEED = "שים לב! אתה קרוב לגבולות החיתוך.",
    WHITE_STRIP = "שים לב! יתכן שלאלמנט יהיו שוליים לבנים, נא למתוח מחוץ לקנבס.",
    IMAGE_RESOLUTION = "שים לב! התמונה שבחרת ברזולוציה נמוכה ולא תודפס בצורה מיטבית.",
}

export interface IErrorPerItem {
    id: string;
    errors: EErrorType[];
}

interface ErrorListPerItemState {
    errorPerItem: IErrorPerItem[];
    openErrorSection: boolean;
}

const initialState: ErrorListPerItemState = {
    errorPerItem: [],
    openErrorSection: false,
};

export const errorListPerItemSlice = createSlice({
    name: "errorListPerItem",
    initialState,
    reducers: {
        setOpenErrorSection: (state, action: PayloadAction<boolean>) => {
            state.openErrorSection = action.payload;
        },
        addErrorPerItem: (
            state,
            action: PayloadAction<{ id: string; errorType: EErrorType }>,
        ) => {
            const { id, errorType } = action.payload;
            const itemIndex = state.errorPerItem.findIndex(
                (item) => item.id === id,
            );
            if (itemIndex === -1) {
                state.errorPerItem.push({ id, errors: [errorType] });
            } else if (
                !state.errorPerItem[itemIndex].errors.includes(errorType)
            ) {
                state.errorPerItem[itemIndex].errors.push(errorType);
            }
        },

        delErrorPerItem: (
            state,
            action: PayloadAction<{ id: string; errorType?: EErrorType }>,
        ) => {
            const { id, errorType } = action.payload;
            const itemIndex = state.errorPerItem.findIndex(
                (item) => item.id === id,
            );
            if (itemIndex !== -1) {
                if (errorType === undefined) {
                    state.errorPerItem.splice(itemIndex, 1);
                } else {
                    state.errorPerItem[itemIndex].errors = state.errorPerItem[
                        itemIndex
                    ].errors.filter((error) => error !== errorType);
                    if (state.errorPerItem[itemIndex].errors.length === 0) {
                        state.errorPerItem.splice(itemIndex, 1);
                    }
                }
            }
        },
    },
});

export const { addErrorPerItem, delErrorPerItem, setOpenErrorSection } =
    errorListPerItemSlice.actions;

export default errorListPerItemSlice.reducer;
