import React from "react";
interface ITextIcon {
    fill?: string;
}
export const TextIcon = ({ fill = "#518eed" }: ITextIcon) => {
    return (
        <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.25 12.75H4.75C4.336 12.75 4 13.086 4 13.5C4 13.914 4.336 14.25 4.75 14.25H9.25C9.664 14.25 10 13.914 10 13.5C10 13.086 9.664 12.75 9.25 12.75H7.75V2.25H12.25V3.75C12.25 4.164 12.586 4.5 13 4.5C13.414 4.5 13.75 4.164 13.75 3.75V1.5C13.75 1.086 13.414 0.75 13 0.75H1C0.586 0.75 0.25 1.086 0.25 1.5V3.75C0.25 4.164 0.586 4.5 1 4.5C1.414 4.5 1.75 4.164 1.75 3.75V2.25H6.25V12.75Z"
                fill="#1D1D1B"
            />
        </svg>
    );
};
