export function onRender(
    id: string,
    phase: "mount" | "update",
    actualDuration: number,
    baseDuration: number,
    startTime: number,
    commitTime: number,
    interactions: Set<any>,
) {
    // console.table({
    //     id,
    //     phase,
    //     actualDuration,
    //     baseDuration,
    //     startTime,
    //     commitTime,
    //     interactions,
    // });
}
