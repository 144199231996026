import React, { useContext } from "react";
import { ReactComponent as UndoIcon } from "../../../assets/icons/undo.svg";
import RedoAndUndo from "./RedoAndUndo";
import { HistoryContext } from "../../../context/HistoryProvider";
import { ERedoAndUndoAction } from "../../../enums/redo-and-undo.enum";

const Undo = () => {
    const { numState }: any = useContext(HistoryContext);
    return (
        <RedoAndUndo action={ERedoAndUndoAction.UNDO} disabled={numState === 1}>
            <UndoIcon className="toolbar-icon" />
        </RedoAndUndo>
    );
};

export default Undo;
