import React, { createContext, useCallback, useContext, useState } from "react";
import { PageContext } from "./PageProvider";
import useGenerateImageSingleColor from "../hooks/useGenerateImageSingleColor";
import LoadingTopSnake from "../components/loading-snake/loading-top-snake";
import { HistoryContext } from "./HistoryProvider";
import { PhotosInCanvasContext } from "./PhotosInCanvasProvider";
import { IContextProviders } from "../interfaces/context-providers.interface";
import {
    IEditorData,
    IEditorDataItem,
    IEditorDataPageItemImage,
} from "../interfaces/editor-data.interface";
import { EEditorDataItemTypes } from "../enums/editor-data-item-type.enum";
import { ESharedWithAllPages } from "../enums/shared-with-all-pages.enum";
import { FoldMenuContext } from "./FoldMenuProvider";
import useIsMobile from "../hooks/useIsMobile";
import { useAppDispatch } from "../redux/hook";
import { delErrorPerItem } from "../redux/features/errorListPerItemSlice";
import {
    addSnapPointsElement,
    removeSnapPointsElement,
} from "../redux/features/snapPointsSlice";
import { getElementPoints } from "../utils/snaps";

export enum EUpdateEditorActions {
    ADD_ITEM = "addItem",
    UPDATE_ITEM = "updateItem",
    REMOVE_ITEM = "removeItem",
    REMOVE_PLACE_HOLDER = "removePlaceHolder",
}

interface IEditorDataContext {
    editorData: IEditorData;
    initData: (data: IEditorData) => void;
    updateEditorData: (
        item: IEditorDataItem,
        action?: EUpdateEditorActions,
        imageReplace?: boolean,
    ) => Promise<IEditorData | undefined>;
    updateItems: (items: Array<IEditorDataItem>) => void;
    updateFocusElements: (id: string, firstClick?: boolean) => void;
    updateOrderItems: (items: Array<IEditorDataItem>) => void;
}

export const EditorDataContext = createContext<IEditorDataContext | null>(null);

export const EditorDataProvider = ({ children }: IContextProviders) => {
    const { page } = useContext(PageContext);
    const { setOpenFoldMenu }: any = useContext(FoldMenuContext);
    const isMobile = useIsMobile();
    const [editorData, setEditorData] = useState<IEditorData>([]);
    const { isLoading, mutateAsync } = useGenerateImageSingleColor();
    const { saveHistory }: any = useContext(HistoryContext);
    const { savePhotos }: any = useContext(PhotosInCanvasContext);
    const dispatch = useAppDispatch();

    const getImageSingleColor = async (item: IEditorDataPageItemImage) => {
        if (item.type !== EEditorDataItemTypes.IMAGE || item.duplicate)
            return item;
        const printColor =
            editorData[page].printColor ? editorData[page].printColor : null;
        if (!printColor) return item;
        const { base64, storageSrc } = item;
        const resp = await mutateAsync({
            storageSrc,
            base64,
            color: printColor,
        });
        item.base64 = `data:image/png;base64,${resp.data.base64}`;
        return item;
    };

    const updateSharedWithAllPagesByKeyCallback = useCallback(
        (key: ESharedWithAllPages, data: any) => {
            setEditorData((prevEditorData) => {
                const cpEditorData = Object.assign({}, prevEditorData);

                if (!cpEditorData[0]["sharedWithAllPages"]) {
                    cpEditorData[0]["sharedWithAllPages"] = {};
                }

                if (key === ESharedWithAllPages.PROJECT_NAME) {
                    const projectData =
                        cpEditorData[0]["sharedWithAllPages"].project;
                    if (projectData) {
                        cpEditorData[0]["sharedWithAllPages"].project = {
                            ...projectData,
                            enabled: true,
                            name: data,
                        };
                    }
                } else {
                    cpEditorData[0]["sharedWithAllPages"][key] = data;
                }
                return Object.values(cpEditorData);
            });
        },
        [setEditorData],
    );

    interface IUpdatePageData {
        hideHelper?: boolean;
    }
    const updatePageData = (page: number, data: IUpdatePageData) => {
        setEditorData((prevEditorData) => {
            const cpEditorData = Object.assign({}, prevEditorData);
            cpEditorData[page] = {
                ...cpEditorData[page],
                ...data,
            };

            return Object.values(cpEditorData);
        });
    };

    const value = {
        editorData,
        initData: (data: IEditorData) => setEditorData(data),

        updateEditorData: async (
            item: any,
            action: EUpdateEditorActions = EUpdateEditorActions.ADD_ITEM,
            imageReplace = false,
        ) => {
            const cpEditorData = Object.assign({}, editorData);
            const pageItems = cpEditorData[page].items;
            const { type } = item ?? {};
            //Add Item
            if (action === EUpdateEditorActions.ADD_ITEM) {
                item = await getImageSingleColor(item);
                if (item.type === EEditorDataItemTypes.IMAGE && item.base64) {
                    savePhotos(item);
                }

                if (type === EEditorDataItemTypes.BACKGROUND) {
                    pageItems.unshift(item);
                } else {
                    pageItems.push(item);
                }

                if (type !== EEditorDataItemTypes.BACKGROUND) {
                    pageItems.map(
                        (pageItem) =>
                            (pageItem.focus = item.key === pageItem.key),
                    );

                    const snapPoints = getElementPoints(
                        {
                            top: item.top,
                            left: item.left,
                            width: item.width,
                            height: item.height,
                            rotation: item.rotation,
                        },
                        item.id,
                    );
                    dispatch(
                        addSnapPointsElement({
                            pageIndex: page,
                            points: snapPoints,
                        }),
                    );
                }
                if (isMobile) {
                    setOpenFoldMenu(false);
                }
            }

            //Update Item
            else if (action === EUpdateEditorActions.UPDATE_ITEM) {
                const currentItemIndex = pageItems.findIndex(
                    (pageItem) => pageItem.key === item.id,
                );
                if (currentItemIndex === -1) {
                    return;
                }
                if (imageReplace) {
                    item = await getImageSingleColor(item);
                }
                pageItems[currentItemIndex] = {
                    ...pageItems[currentItemIndex],
                    ...item,
                };
            }

            //Remove Item
            else if (action === EUpdateEditorActions.REMOVE_ITEM) {
                const itemsToKeep = pageItems.reduce<any>((acc, item) => {
                    if (item.focus) {
                        dispatch(delErrorPerItem({ id: item.id }));
                        dispatch(
                            removeSnapPointsElement({
                                pageIndex: page,
                                elementId: item.id,
                            }),
                        );
                        return acc;
                    } else {
                        acc.push(item);
                        return acc;
                    }
                }, []);

                cpEditorData[page].items = itemsToKeep;
            } else if (action === EUpdateEditorActions.REMOVE_PLACE_HOLDER) {
                cpEditorData[page].items = pageItems.filter(
                    (pageItem) => !pageItem.placeHolder,
                );
            }

            //Save.
            const cpEditorDataArray = Object.values(cpEditorData);
            if (
                action === EUpdateEditorActions.ADD_ITEM ||
                action === EUpdateEditorActions.REMOVE_ITEM
            ) {
                saveHistory(cpEditorDataArray);
            }

            setEditorData(cpEditorDataArray);
            return cpEditorDataArray;
        },

        updateItems: (items: Array<IEditorDataItem>) => {
            const cpEditorData = Object.assign({}, editorData);
            const pageItems: any = cpEditorData[page].items;
            items.forEach((item) => {
                const currentItemIndex = pageItems.findIndex(
                    (pageItem: IEditorDataItem) => pageItem.key === item.id,
                );
                pageItems[currentItemIndex] = {
                    ...pageItems[currentItemIndex],
                    ...item,
                };
            });
            const cpEditorDataArray = Object.values(cpEditorData);
            saveHistory(cpEditorDataArray);
            setEditorData(cpEditorDataArray);
        },

        updateOrderItems: (items: any) => {
            const cpEditorData = Object.assign({}, editorData);
            cpEditorData[page].items = items;
            const cpEditorDataArray = Object.values(cpEditorData);
            saveHistory(cpEditorDataArray);
            setEditorData(cpEditorDataArray);
        },

        updateFocusElements: (id: string, firstClick = false) => {
            const cpEditorData = Object.assign({}, editorData);
            cpEditorData[page].items.map((item: any) => {
                item.focus = item.key === id;
                if (item?.editable) {
                    item.editable = false;
                }
                //First Click
                return item;
            });
            const cpEditorDataArray = Object.values(cpEditorData);
            setEditorData(cpEditorDataArray);
        },
        updateScale: (scale: number) => {
            const cpEditorData = Object.assign({}, editorData);
            cpEditorData[page].scale = scale;
            setEditorData(Object.values(cpEditorData));
        },
        sharedWithAllPages: editorData[0]?.sharedWithAllPages,

        updateSharedWithAllPagesByKey: updateSharedWithAllPagesByKeyCallback,
        updatePageData,
    };

    return (
        <EditorDataContext.Provider value={value}>
            {isLoading && <LoadingTopSnake />}
            {children}
        </EditorDataContext.Provider>
    );
};
