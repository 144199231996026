import { useContext } from "react";
import { EditorDataContext } from "../context/EditorDataProvider";
import { IEditorDataItem } from "../interfaces/editor-data.interface";

const useFindItemById = () => {
    const { editorData }: any = useContext(EditorDataContext);

    const findItemById = (itemId: string) => {
        for (const page of editorData) {
            const foundItem = page.items.find(
                (item: IEditorDataItem) => item.id === itemId,
            );
            if (foundItem) {
                return foundItem;
            }
        }
        return null;
    };

    return findItemById;
};

export default useFindItemById;
