import React, { useContext } from "react";
import SnapCanvas from "./SnapCanvas";
import { useAppSelector } from "../../redux/hook";
import {
    ISnapPoint,
    ISnapPointsPage,
} from "../../redux/features/snapPointsSlice";
import { PageContext } from "../../context/PageProvider";
import SnapPointsElement from "./SnapPointsElement";
import { IEditorDataItem } from "../../interfaces/editor-data.interface";
import { EditorDataContext } from "../../context/EditorDataProvider";
import { borderBox, isContentBox } from "../../utils/editor-items";

const SnapPointsRenderer = () => {
    const { page }: any = useContext(PageContext);
    const { editorData }: any = useContext(EditorDataContext);
    const items = editorData[page].items;
    const focusedItem = items.find((item: IEditorDataItem) => item.focus);

    const snapData = useAppSelector((state) => state.snapPoints);
    const { pages: snapPoints, isMove } = snapData;
    const snapPointsPage =
        snapPoints ? (snapPoints[page] as ISnapPointsPage) : undefined;
    if (!isMove) return null;
    const border = isContentBox(focusedItem) ? borderBox : 0;
    return (
        <>
            {snapPointsPage?.snapPointsCanvas?.map(
                (snapPoint: ISnapPoint, index: number) => (
                    <SnapCanvas key={`${page}_snap_${index}`} {...snapPoint} />
                ),
            )}
            <SnapPointsElement
                snapPoints={snapPointsPage?.snapPointElements ?? {}}
                currentId={focusedItem?.id}
                border={border}
            />
        </>
    );
};

export default React.memo(SnapPointsRenderer);
