import { ISnapPointElement } from "../../redux/features/snapPointsSlice";

export enum EAxis {
    X = "x",
    Y = "y",
}

interface IAlignment {
    axis: EAxis;
    condition: (el: ISnapPointElement) => boolean;
    value: (el: ISnapPointElement) => number;
    positionSnap: (el: ISnapPointElement) => number;
}

export const getAlignmentWithElement = (
    currentElement: ISnapPointElement,
    threshold: number,
    border = 0,
): IAlignment[] => [
    {
        axis: EAxis.Y,
        condition: (el: ISnapPointElement) =>
            Math.abs(Math.ceil(currentElement.top) - Math.ceil(el.top)) <=
            threshold,
        value: (el: ISnapPointElement) => el.top,
        positionSnap: (el: ISnapPointElement) => el.top + border,
    },
    {
        axis: EAxis.Y,
        condition: (el: ISnapPointElement) =>
            Math.abs(Math.ceil(currentElement.top) - Math.ceil(el.center.y)) <=
            threshold,
        value: (el: ISnapPointElement) => el.center.y,
        positionSnap: (el: ISnapPointElement) => el.center.y + border,
    },
    {
        axis: EAxis.Y,
        condition: (el: ISnapPointElement) =>
            Math.abs(Math.ceil(currentElement.top) - Math.ceil(el.bottom)) <=
            threshold,
        value: (el: ISnapPointElement) => el.bottom,
        positionSnap: (el: ISnapPointElement) => el.bottom + border,
    },
    {
        axis: EAxis.Y,
        condition: (el: ISnapPointElement) =>
            Math.abs(Math.ceil(currentElement.center.y) - Math.ceil(el.top)) <=
            threshold,
        value: (el: ISnapPointElement) => el.top - currentElement.h / 2,
        positionSnap: (el: ISnapPointElement) =>
            currentElement.center.y + border,
    },
    {
        axis: EAxis.Y,
        condition: (el: ISnapPointElement) =>
            Math.abs(
                Math.ceil(currentElement.center.y) - Math.ceil(el.center.y),
            ) <= threshold,
        value: (el: ISnapPointElement) => el.center.y - currentElement.h / 2,
        positionSnap: (el: ISnapPointElement) =>
            currentElement.center.y + border,
    },
    {
        axis: EAxis.Y,
        condition: (el: ISnapPointElement) =>
            Math.abs(
                Math.ceil(currentElement.center.y) - Math.ceil(el.bottom),
            ) <= threshold,
        value: (el: ISnapPointElement) => el.bottom - currentElement.h / 2,
        positionSnap: (el: ISnapPointElement) =>
            currentElement.center.y + border,
    },
    {
        axis: EAxis.Y,
        condition: (el: ISnapPointElement) =>
            Math.abs(Math.ceil(currentElement.bottom) - Math.ceil(el.top)) <=
            threshold,
        value: (el: ISnapPointElement) => el.top - currentElement.h,
        positionSnap: (el: ISnapPointElement) => currentElement.bottom + border,
    },
    {
        axis: EAxis.Y,
        condition: (el: ISnapPointElement) =>
            Math.abs(
                Math.ceil(currentElement.bottom) - Math.ceil(el.center.y),
            ) <= threshold,
        value: (el: ISnapPointElement) => el.center.y - currentElement.h,
        positionSnap: (el: ISnapPointElement) => currentElement.bottom + border,
    },
    {
        axis: EAxis.Y,
        condition: (el: ISnapPointElement) =>
            Math.abs(Math.ceil(currentElement.bottom) - Math.ceil(el.bottom)) <=
            threshold,
        value: (el: ISnapPointElement) => el.bottom - currentElement.h,
        positionSnap: (el: ISnapPointElement) => currentElement.bottom + border,
    },

    {
        axis: EAxis.X,
        condition: (el: ISnapPointElement) =>
            Math.abs(Math.ceil(currentElement.left) - Math.ceil(el.left)) <=
            threshold,
        value: (el: ISnapPointElement) => el.left,
        positionSnap: (el: ISnapPointElement) => el.left + border,
    },
    {
        axis: EAxis.X,
        condition: (el: ISnapPointElement) =>
            Math.abs(Math.ceil(currentElement.left) - Math.ceil(el.center.x)) <=
            threshold,
        value: (el: ISnapPointElement) => el.center.x,
        positionSnap: (el: ISnapPointElement) => el.center.x + border,
    },
    {
        axis: EAxis.X,
        condition: (el: ISnapPointElement) =>
            Math.abs(Math.ceil(currentElement.left) - Math.ceil(el.right)) <=
            threshold,
        value: (el: ISnapPointElement) => el.right,
        positionSnap: (el: ISnapPointElement) => el.right + border,
    },
    {
        axis: EAxis.X,
        condition: (el: ISnapPointElement) =>
            Math.abs(Math.ceil(currentElement.center.x) - Math.ceil(el.left)) <=
            threshold,
        value: (el: ISnapPointElement) => el.left - currentElement.w / 2,
        positionSnap: (el: ISnapPointElement) =>
            currentElement.center.x + border,
    },
    {
        axis: EAxis.X,
        condition: (el: ISnapPointElement) =>
            Math.abs(
                Math.ceil(currentElement.center.x) - Math.ceil(el.center.x),
            ) <= threshold,
        value: (el: ISnapPointElement) => el.center.x - currentElement.w / 2,
        positionSnap: (el: ISnapPointElement) =>
            currentElement.center.x + border,
    },
    {
        axis: EAxis.X,
        condition: (el: ISnapPointElement) =>
            Math.abs(
                Math.ceil(currentElement.center.x) - Math.ceil(el.right),
            ) <= threshold,
        value: (el: ISnapPointElement) => el.right - currentElement.w / 2,
        positionSnap: (el: ISnapPointElement) =>
            currentElement.center.x + border,
    },
    {
        axis: EAxis.X,
        condition: (el: ISnapPointElement) =>
            Math.abs(Math.ceil(currentElement.right) - Math.ceil(el.left)) <=
            threshold,
        value: (el: ISnapPointElement) => el.left - currentElement.w,
        positionSnap: (el: ISnapPointElement) => currentElement.right + border,
    },
    {
        axis: EAxis.X,
        condition: (el: ISnapPointElement) =>
            Math.abs(
                Math.ceil(currentElement.right) - Math.ceil(el.center.x),
            ) <= threshold,
        value: (el: ISnapPointElement) => el.center.x - currentElement.w,
        positionSnap: (el: ISnapPointElement) => currentElement.right + border,
    },
    {
        axis: EAxis.X,
        condition: (el: ISnapPointElement) =>
            Math.abs(Math.ceil(currentElement.right) - Math.ceil(el.right)) <=
            threshold,
        value: (el: ISnapPointElement) => el.right - currentElement.w,
        positionSnap: (el: ISnapPointElement) => currentElement.right + border,
    },
];
