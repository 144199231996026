import React from "react";
import { IToggleInnerMenu } from "./ToolBar";
import Duplicate from "./tools/Duplicate";
import Arrange from "./tools/Arrange";
import Opacity from "./tools/Opacity";
import Alignment from "./tools/Alignment";

const ToolBarGeneralItem = ({
    innerMenu,
    toggleInnerMenu,
}: IToggleInnerMenu) => {
    return (
        <>
            <Duplicate />
            <Arrange innerMenu={innerMenu} toggleInnerMenu={toggleInnerMenu} />
            <Opacity innerMenu={innerMenu} toggleInnerMenu={toggleInnerMenu} />
            <Alignment
                innerMenu={innerMenu}
                toggleInnerMenu={toggleInnerMenu}
            />
        </>
    );
};

export default ToolBarGeneralItem;
