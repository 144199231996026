import { IPropsBox } from "../../components/editorWidgets/utils/Resizable";
import { EEditorDataItemTypes } from "../../enums/editor-data-item-type.enum";
import { IEditorData } from "../../interfaces/editor-data.interface";
import {
    ISnapPoint,
    ISnapPointElement,
} from "../../redux/features/snapPointsSlice";
import { CM2PX } from "../utils";
import { EAxis, getAlignmentWithElement } from "./alignmentStrategies";

export const getInitialPoints = (editorData: IEditorData) => {
    return editorData.map((pageItem) => {
        const bleed = CM2PX(pageItem?.bleed || 0);
        const w = CM2PX(pageItem.width) - bleed * 2;
        const h = CM2PX(pageItem.height) - bleed * 2;

        const snapPointsCanvas = [
            { top: bleed, left: bleed, w },
            { top: CM2PX(pageItem.height) - bleed, left: bleed, w },
            { top: bleed, left: bleed, h },
            { top: bleed, left: CM2PX(pageItem.width) - bleed, h },
            { top: CM2PX(pageItem.height) / 2, left: bleed, w },
            { top: bleed, left: CM2PX(pageItem.width) / 2, h },
        ];

        const snapPointElements: Record<string, ISnapPointElement> = {};
        pageItem.items.forEach((element) => {
            if (
                (element.type as EEditorDataItemTypes) ===
                EEditorDataItemTypes.BACKGROUND
            ) {
                return;
            }

            const propsBox: IPropsBox = {
                top: element.top,
                left: element.left,
                width: element.width,
                height: element.height,
                rotation: element.rotation,
            };

            const points = getElementPoints(propsBox, element.id);
            snapPointElements[element.id] = points;
        });
        return {
            snapPointsCanvas,
            snapPointElements,
        };
    });
};

export const getElementPoints = (
    propsBox: IPropsBox,
    id: string,
): ISnapPointElement => {
    return {
        id,
        top: propsBox.top,
        left: propsBox.left,
        right: propsBox.left + propsBox.width,
        bottom: propsBox.top + propsBox.height,
        center: {
            x: propsBox.left + propsBox.width / 2,
            y: propsBox.top + propsBox.height / 2,
        },
        w: propsBox.width,
        h: propsBox.height,
    };
};

export const THRESHOLD = 2;
export const getUpdatedSnapPointsCanvas = (
    snapPointsCanvas: ISnapPoint[],
    propsBox: IPropsBox,
    border: number,
): {
    newSnapPointsCanvas: ISnapPoint[];
    closestTop: number | null;
    closestLeft: number | null;
} => {
    const { top, left, width, height } = propsBox;
    const right = left + width;
    const bottom = top + height;
    const center = { x: (left + right) / 2, y: (top + bottom) / 2 };

    let closestTop: number | null = null;
    let closestLeft: number | null = null;

    const newSnapPointsCanvas = snapPointsCanvas?.map(
        (snapPoint: ISnapPoint) => {
            const isNearCenterX =
                Math.abs(center.x - snapPoint.left) <= THRESHOLD &&
                snapPoint?.h;

            const isNearCenterY =
                Math.abs(center.y - snapPoint.top) <= THRESHOLD && snapPoint?.w;

            const isNearTop =
                Math.abs(top - snapPoint.top) <= THRESHOLD && snapPoint?.w;
            const isNearBottom =
                snapPoint.top && Math.abs(bottom - snapPoint.top) <= THRESHOLD;
            const isNearLeft =
                Math.abs(left - snapPoint.left) <= THRESHOLD && snapPoint?.h;
            const isNearRight =
                snapPoint.left && Math.abs(right - snapPoint.left) <= THRESHOLD;

            if (isNearTop) {
                closestTop = snapPoint.top;
            } else if (isNearBottom) {
                closestTop = snapPoint.top - propsBox.height - border;
            } else if (isNearCenterY) {
                closestTop = snapPoint.top - propsBox.height / 2;
            }
            if (isNearLeft) {
                closestLeft = snapPoint.left;
            } else if (isNearRight) {
                closestLeft = snapPoint.left - propsBox.width - border;
            } else if (isNearCenterX) {
                closestLeft = snapPoint.left - propsBox.width / 2;
            }

            const hide =
                !isNearLeft &&
                !isNearTop &&
                !isNearRight &&
                !isNearBottom &&
                !isNearCenterY &&
                !isNearCenterX;

            return { ...snapPoint, show: !hide };
        },
    );

    return { newSnapPointsCanvas, closestTop, closestLeft };
};

export const updateAndGetSnapPointsElements = (
    propsBox: IPropsBox,
    id: string,
    elements: Record<string, ISnapPointElement> = {},
    threshold: number = THRESHOLD,
    border: number,
): {
    closestTopElement: number | null;
    closestLeftElement: number | null;
    updatedSnapPointElements: Record<string, ISnapPointElement>;
} => {
    let closestTopElement: number | null = null;
    let closestLeftElement: number | null = null;

    const updatedElements = { ...elements };
    const currentElement = getElementPoints(propsBox, id);
    updatedElements[id] = currentElement;

    const alignments = getAlignmentWithElement(
        currentElement,
        threshold,
        border,
    );

    for (const key in updatedElements) {
        if (key === id) continue;

        const element = updatedElements[key];

        for (const { condition, value, axis } of alignments) {
            if (condition(element)) {
                if (axis === EAxis.Y) {
                    closestTopElement = value(element);
                } else {
                    closestLeftElement = value(element);
                }
                break;
            }
        }
    }

    return {
        closestTopElement,
        closestLeftElement,
        updatedSnapPointElements: updatedElements,
    };
};
