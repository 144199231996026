import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import ListElements from "./listElements";
import FoldElement from "./FoldElement";
import {
    StyledSideBarTitle,
    StyledSideBarSubTitle,
    StyledSideBarContainer,
    StyledSideBarInner,
} from "../../../style-components/styleComponents";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import {
    EStockImagesNames,
    EStockImagesStatus,
    fetchStockImages,
} from "../../../../redux/features/stockImagesSlice";
import LoadingSnake from "../../../loading-snake/loading-snake";
import PhotoItem from "../../items/photoItem";
import { nanoid } from "nanoid";
import GalleryElements from "../../../gallery-elements/GalleryElements";
import Grid from "../../../Grid/GridContainer";
import ArrowButton from "../../../buttons/ArrowButton";
import { getKeyByValue } from "../../../../utils/utils";

type ImageCategory = {
    name: string;
    images: string[];
    id: string;
};

export interface ImagesState {
    [key: string]: ImageCategory;
}

interface IShowAll {
    title: string;
    items: React.ReactNode[];
}

const StyledGroupTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--small-gap);
`;

const StyledInner = styled(StyledSideBarInner)`
    margin-bottom: var(--large-gap);
`;

const FoldElements = () => {
    const { images, status } = useAppSelector((state) => state.stockImages) as {
        images: ImagesState;
        status: EStockImagesStatus;
    };
    const [showAll, setShowAll] = useState<null | IShowAll>(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (status === EStockImagesStatus.IDLE) {
            dispatch(fetchStockImages());
        }
    }, [dispatch]);

    const renderGallery = (
        title: string,
        items: React.ReactNode[],
        keyPrefix: string,
    ) => (
        <StyledInner key={`renderGallery_${keyPrefix}`}>
            <StyledGroupTitle>
                <StyledSideBarSubTitle>{title}</StyledSideBarSubTitle>

                <ArrowButton
                    handleOnclick={() => setShowAll({ title, items })}
                    style={{ fontSize: "var(--small-font-size)" }}
                >
                    <span>הצג הכל</span>
                </ArrowButton>
            </StyledGroupTitle>
            <GalleryElements>
                {items.map((item, index) => (
                    <React.Fragment key={`${keyPrefix}_${index}`}>
                        {item}
                    </React.Fragment>
                ))}
            </GalleryElements>
        </StyledInner>
    );

    if (status === EStockImagesStatus.LOADING) {
        return (
            <>
                <LoadingSnake />
            </>
        );
    }

    if (showAll) {
        return (
            <StyledSideBarContainer>
                <StyledSideBarTitle>
                    <ArrowButton
                        before={true}
                        handleOnclick={() => setShowAll(null)}
                    >
                        {showAll.title}
                    </ArrowButton>
                </StyledSideBarTitle>
                <Grid itemsPerRow={4} gap="30px">
                    {showAll.items.map((item, index) => (
                        <Fragment key={`showAll_${index}`}>{item}</Fragment>
                    ))}
                </Grid>
            </StyledSideBarContainer>
        );
    }

    return (
        <StyledSideBarContainer>
            <StyledSideBarTitle>אלמנטים</StyledSideBarTitle>
            {ListElements.length > 1 && <div>חיפוש אלמנט</div>}
            {ListElements.map((group, index) => {
                const { groupName: title, groupItems } = group;
                return (
                    <Fragment key={`listElementsGroup_${index}`}>
                        {renderGallery(
                            title,
                            groupItems.map((item, index) => (
                                <FoldElement
                                    key={`render_gallery_${index}`}
                                    {...item}
                                />
                            )),
                            `listElement_${index}`,
                        )}
                    </Fragment>
                );
            })}
            {images &&
                Object.entries(images).map(([key, category], index) => {
                    return renderGallery(
                        category.name,
                        category.images.map((imageSrc, index) => (
                            <PhotoItem
                                className={category.id as string}
                                key={`render_gallery_1${index}`}
                                id={nanoid()}
                                src={imageSrc}
                            />
                        )),
                        `stockImages_${index}`,
                    );
                })}
        </StyledSideBarContainer>
    );
};

export default FoldElements;
