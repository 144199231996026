export const bcard =
    '<div class="helperItem" style="position: absolute; z-index: 2; width:100%">\n' +
    '<?xml version="1.0" encoding="utf-8"?>\n' +
    "<!-- Generator: Adobe Illustrator 24.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->\n" +
    '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"\n' +
    '\t viewBox="0 0 269.29999 212.60001" style="enable-background:new 0 0 269.29999 212.60001;" xml:space="preserve">\n' +
    '<style type="text/css">\n' +
    "\t.st0{opacity:0.1;fill:#6089C6;}\n" +
    "\t.st1{fill:#FFFFFF;}\n" +
    "\t.st2{opacity:0.7;enable-background:new    ;}\n" +
    "\t.st3{fill:#F2F2F2;}\n" +
    "\t.st4{fill:none;stroke:#6E6F71;stroke-width:0.5;stroke-miterlimit:10;}\n" +
    "\t.st5{fill:none;stroke:#6E6F71;stroke-width:0.5;stroke-miterlimit:10;stroke-dasharray:2.9518,2.9518;}\n" +
    "\t.st6{fill:none;stroke:#6E6F71;stroke-width:0.5;stroke-miterlimit:10;stroke-dasharray:2.9661,2.9661;}\n" +
    "\t.st7{fill:#0C2B49;}\n" +
    "\t.st8{opacity:0.5;}\n" +
    "</style>\n" +
    '<g id="Layer_1">\n' +
    '\t<path class="st0" d="M7.09998,7.19754v141.70001h255.10004V7.19754H7.09998z M257.70001,144.49753H11.59998V11.5975h246.10004\n' +
    '\t\tV144.49753z"/>\n' +
    '\t<rect x="25.94888" y="7.19752" class="st1" width="27.67488" height="7.02138"/>\n' +
    "\t<g>\n" +
    '\t\t<g class="st2">\n' +
    '\t\t\t<path class="st3" d="M261.70001,7.69751v140.7H7.60001v-140.7H261.70001 M262.20001,7.19751H7.10001v141.7h255.10001V7.19751\n' +
    '\t\t\t\tL262.20001,7.19751z"/>\n' +
    "\t\t</g>\n" +
    "\t\t<g>\n" +
    "\t\t\t<g>\n" +
    '\t\t\t\t<polyline class="st4" points="260.70001,148.89752 262.20001,148.89752 262.20001,147.39752 \t\t\t\t"/>\n' +
    '\t\t\t\t<line class="st5" x1="262.20001" y1="144.49751" x2="262.20001" y2="10.19752"/>\n' +
    '\t\t\t\t<polyline class="st4" points="262.20001,8.69752 262.20001,7.19752 260.70001,7.19752 \t\t\t\t"/>\n' +
    '\t\t\t\t<line class="st6" x1="257.70001" y1="7.19752" x2="10.1" y2="7.19752"/>\n' +
    '\t\t\t\t<polyline class="st4" points="8.6,7.19752 7.1,7.19752 7.1,8.69752 \t\t\t\t"/>\n' +
    '\t\t\t\t<line class="st5" x1="7.1" y1="11.59752" x2="7.1" y2="145.89752"/>\n' +
    '\t\t\t\t<polyline class="st4" points="7.1,147.39752 7.1,148.89752 8.6,148.89752 \t\t\t\t"/>\n' +
    '\t\t\t\t<line class="st6" x1="11.6" y1="148.89752" x2="259.20001" y2="148.89752"/>\n' +
    "\t\t\t</g>\n" +
    "\t\t</g>\n" +
    "\t</g>\n" +
    '\t<g id="Isolation_Mode_3_">\n' +
    "\t\t<g>\n" +
    "\t\t\t<g>\n" +
    "\t\t\t\t<g>\n" +
    '\t\t\t\t\t<path class="st7" d="M255.33057,178.69327h-2.33997v-0.6825h2.33997c2.43741,0,4.28989-1.94994,4.28989-4.28989v-5.75237\n' +
    '\t\t\t\t\t\th0.6825v5.75237C260.30289,176.45079,258.06046,178.69327,255.33057,178.69327z"/>\n' +
    "\t\t\t\t</g>\n" +
    "\t\t\t\t<g>\n" +
    "\t\t\t\t\t<g>\n" +
    '\t\t\t\t\t\t<polygon class="st7" points="257.28052,170.11348 257.76797,170.60097 260.01041,168.26106 262.25284,170.60097 \n' +
    '\t\t\t\t\t\t\t262.74033,170.11348 260.01041,167.18857 \t\t\t\t\t\t"/>\n' +
    "\t\t\t\t\t</g>\n" +
    "\t\t\t\t</g>\n" +
    "\t\t\t</g>\n" +
    "\t\t\t<g>\n" +
    '\t\t\t\t<path d="M19.31152,179.98853c-0.0957-0.09961-0.14355-0.22363-0.14355-0.37402c0-0.15625,0.04785-0.2832,0.14355-0.38281\n' +
    "\t\t\t\t\tc0.09619-0.09863,0.21289-0.14844,0.35107-0.14844s0.25488,0.0498,0.35107,0.14844\n" +
    "\t\t\t\t\tc0.09619,0.09961,0.14404,0.22656,0.14404,0.38281c0,0.15039-0.04785,0.27441-0.14404,0.37402\n" +
    '\t\t\t\t\tc-0.09619,0.09863-0.21289,0.14844-0.35107,0.14844S19.40771,180.08716,19.31152,179.98853z"/>\n' +
    '\t\t\t\t<path d="M21.80029,179.66431c-0.20117-0.66309-0.30127-1.37598-0.30127-2.1377s0.1001-1.47461,0.30127-2.1377\n' +
    "\t\t\t\t\ts0.49658-1.3125,0.88672-1.94824l0.40479,0.19824c-0.3418,0.56348-0.60156,1.17871-0.77832,1.84473\n" +
    "\t\t\t\t\tc-0.17725,0.66602-0.26562,1.34668-0.26562,2.04297s0.08838,1.37695,0.26562,2.04297\n" +
    "\t\t\t\t\tc0.17676,0.66602,0.43652,1.28125,0.77832,1.84473l-0.40479,0.19824C22.29688,180.97681,22.00146,180.32739,21.80029,179.66431z\n" +
    '\t\t\t\t\t"/>\n' +
    '\t\t\t\t<path d="M28.03271,176.70728v3.32129h-0.69287H24.271h-0.17139v-4.99512h0.69336H26.521\n' +
    "\t\t\t\t\tC27.52881,175.03345,28.03271,175.59204,28.03271,176.70728z M27.33984,179.47974v-2.79883\n" +
    '\t\t\t\t\tc0-0.39062-0.08398-0.6709-0.25195-0.8418s-0.4082-0.25684-0.72021-0.25684h-1.57471v3.89746H27.33984z"/>\n' +
    '\t\t\t\t<path d="M29.34668,176.08618l-0.11719-1.05273h0.68408l0.12598,1.05273v1.3418l-0.00879,0.56641l-0.68408,0.14453V176.08618z"/>\n' +
    '\t\t\t\t<path d="M33.41455,176.63599c0-0.46191,0.08105-0.81348,0.24316-1.05371H31.1377v-0.54883h3.85205v0.54883h-0.66602\n' +
    '\t\t\t\t\tc-0.14404,0.24609-0.21631,0.59766-0.21631,1.05371v3.39258h-0.69287V176.63599z"/>\n' +
    '\t\t\t\t<path d="M38.23828,176.63599c0-0.46191,0.08105-0.81348,0.24316-1.05371h-2.52002v-0.54883h3.85205v0.54883h-0.66602\n' +
    '\t\t\t\t\tc-0.14404,0.24609-0.21631,0.59766-0.21631,1.05371v3.39258h-0.69287V176.63599z"/>\n' +
    '\t\t\t\t<path d="M41.01074,176.08618l-0.11719-1.05273h0.68408l0.12598,1.05273v3.94238h-0.69287V176.08618z"/>\n' +
    '\t\t\t\t<path d="M46.70752,179.47974v0.54883h-1.33203h-2.5918v-0.54883h2.5918v-2.79883c0-0.39062-0.08398-0.6709-0.25195-0.8418\n' +
    '\t\t\t\t\ts-0.40771-0.25684-0.71973-0.25684h-1.62012v-0.54883h1.77295c1.0083,0,1.51221,0.55859,1.51221,1.67383v2.77246H46.70752z"/>\n' +
    '\t\t\t\t<path d="M53.46631,176.70728v3.32129h-0.69287h-3.06885H49.5332v-4.99512h0.69336h1.72803\n' +
    "\t\t\t\t\tC52.9624,175.03345,53.46631,175.59204,53.46631,176.70728z M52.77344,179.47974v-2.79883\n" +
    '\t\t\t\t\tc0-0.39062-0.08398-0.6709-0.25195-0.8418s-0.4082-0.25684-0.72021-0.25684h-1.57471v3.89746H52.77344z"/>\n' +
    '\t\t\t\t<path d="M54.78027,176.08618l-0.11719-1.05273h0.68408l0.12598,1.05273v1.3418l-0.00879,0.56641l-0.68408,0.14453V176.08618z"/>\n' +
    '\t\t\t\t<path d="M59.02832,176.68091c0-0.39062-0.08398-0.6709-0.25195-0.8418s-0.4082-0.25684-0.72021-0.25684h-1.59277v-0.54883\n' +
    '\t\t\t\t\th1.74609c1.00781,0,1.51172,0.55859,1.51172,1.67383v3.32129h-0.69287V176.68091z"/>\n' +
    '\t\t\t\t<path d="M60.79199,175.03345h0.69287l0.52197,4.44629c0.69629-0.07227,1.25244-0.2998,1.66992-0.68457\n' +
    "\t\t\t\t\tc0.41699-0.38379,0.61035-0.96875,0.58008-1.75488v-0.22461c0-0.37207-0.0957-0.67188-0.2876-0.90039\n" +
    "\t\t\t\t\tc-0.19238-0.22754-0.48047-0.35352-0.86426-0.37793l-0.44971-0.02637l0.04492-0.55859l0.50391,0.03613\n" +
    "\t\t\t\t\tc0.52783,0.03027,0.94336,0.18945,1.24658,0.47656c0.30273,0.28809,0.46338,0.70898,0.48145,1.26074l0.00879,0.25195\n" +
    '\t\t\t\t\tc0.03613,1.03223-0.27002,1.7998-0.91797,2.30371s-1.52393,0.7832-2.62793,0.83691L60.79199,175.03345z"/>\n' +
    '\t\t\t\t<path d="M69.77881,179.4436c-0.35742,0.45605-0.86865,0.68359-1.53467,0.68359c-0.22803,0-0.47412-0.02637-0.73779-0.08105\n' +
    "\t\t\t\t\tl0.07178-0.54883c0.19824,0.04883,0.40186,0.07227,0.61182,0.07227c0.49805,0,0.86572-0.17285,1.10254-0.51758\n" +
    "\t\t\t\t\tc0.2373-0.34473,0.35547-0.85938,0.35547-1.54297c0-1.30859-0.42871-1.96289-1.28662-1.96289\n" +
    "\t\t\t\t\tc-0.32422,0-0.58496,0.09375-0.7832,0.2793c-0.19824,0.18652-0.33301,0.42871-0.40479,0.72949l-0.63916,3.47363h-0.69287\n" +
    "\t\t\t\t\tl0.66602-3.50098l-0.69336-1.49414h0.72021l0.44971,1.08008c0.12012-0.37793,0.31201-0.66113,0.57617-0.85059\n" +
    "\t\t\t\t\tc0.26416-0.18848,0.56396-0.2832,0.8999-0.2832c0.57031,0,1.02148,0.19629,1.35449,0.58887\n" +
    '\t\t\t\t\tc0.33301,0.39355,0.49951,1.03125,0.49951,1.91309C70.31396,178.33325,70.13574,178.98755,69.77881,179.4436z"/>\n' +
    '\t\t\t\t<path d="M71.4751,176.08618l-0.11719-1.05273h0.68408l0.12598,1.05273v1.3418l-0.00879,0.56641l-0.68408,0.14453V176.08618z"/>\n' +
    '\t\t\t\t<path d="M76.65918,175.03345v0.58496l-1.7373,4.41016H74.229l1.73682-4.44629h-2.78955v-2.00684h0.63867v1.45801H76.65918z"/>\n' +
    '\t\t\t\t<path d="M77.77539,176.08618l-0.11719-1.05273h0.68408l0.12598,1.05273v1.3418l-0.00879,0.56641l-0.68408,0.14453V176.08618z"/>\n' +
    '\t\t\t\t<path d="M83.53955,179.4436c-0.35742,0.45605-0.86865,0.68359-1.53467,0.68359c-0.22803,0-0.47412-0.02637-0.73779-0.08105\n' +
    "\t\t\t\t\tl0.07178-0.54883c0.19824,0.04883,0.40186,0.07227,0.61182,0.07227c0.49805,0,0.86572-0.17285,1.10254-0.51758\n" +
    "\t\t\t\t\tc0.2373-0.34473,0.35547-0.85938,0.35547-1.54297c0-1.30859-0.42871-1.96289-1.28662-1.96289\n" +
    "\t\t\t\t\tc-0.32422,0-0.58496,0.09375-0.7832,0.2793c-0.19824,0.18652-0.33301,0.42871-0.40479,0.72949l-0.63916,3.47363h-0.69287\n" +
    "\t\t\t\t\tl0.66602-3.50098l-0.69336-1.49414h0.72021l0.44971,1.08008c0.12012-0.37793,0.31201-0.66113,0.57617-0.85059\n" +
    "\t\t\t\t\tc0.26416-0.18848,0.56396-0.2832,0.8999-0.2832c0.57031,0,1.02148,0.19629,1.35449,0.58887\n" +
    '\t\t\t\t\tc0.33301,0.39355,0.49951,1.03125,0.49951,1.91309C84.07471,178.33325,83.89648,178.98755,83.53955,179.4436z"/>\n' +
    '\t\t\t\t<path d="M90.2041,175.03345v0.58496l-1.7373,4.41016h-0.69287l1.73682-4.44629h-2.78955v-2.00684h0.63867v1.45801H90.2041z"/>\n' +
    '\t\t\t\t<path d="M94.78955,179.16431c-0.68115,0.62402-1.71436,0.94238-3.10059,0.9541l-0.62988-5.08496h0.66602l0.30615,2.54688\n' +
    "\t\t\t\t\tc0.27002-0.14355,0.47998-0.29785,0.62988-0.46289s0.25928-0.36816,0.32861-0.60742\n" +
    "\t\t\t\t\tc0.06885-0.24023,0.10938-0.5498,0.12158-0.92773l0.00879-0.53027h0.66602l-0.00879,0.52148\n" +
    "\t\t\t\t\tc-0.01221,0.66016-0.14404,1.18359-0.396,1.57031c-0.25195,0.3877-0.68115,0.71289-1.28711,0.97656l0.1709,1.38574\n" +
    "\t\t\t\t\tc0.9541-0.05957,1.66797-0.3457,2.14209-0.85938c0.47363-0.5127,0.70801-1.2793,0.70215-2.29883l-0.00928-1.31445h0.69287\n" +
    '\t\t\t\t\tl0.00928,1.28711C95.80811,177.59302,95.4707,178.54028,94.78955,179.16431z"/>\n' +
    '\t\t\t\t<path d="M98.62793,177.59888l0.09912-0.60352h0.59375v3.0332h-0.69287V177.59888z M101.71484,176.68091\n' +
    "\t\t\t\t\tc0-0.39062-0.08398-0.6709-0.25195-0.8418s-0.4082-0.25684-0.72021-0.25684h-2.10596v-0.54883h2.25928\n" +
    '\t\t\t\t\tc1.00781,0,1.51172,0.55859,1.51172,1.67383v3.32129h-0.69287V176.68091z"/>\n' +
    '\t\t\t\t<path d="M103.7041,176.08618l-0.11719-1.05273h0.68408l0.12598,1.05273v1.3418l-0.00879,0.56641l-0.68408,0.14453V176.08618z"/>\n' +
    '\t\t\t\t<path d="M105.71973,176.08618l-0.11719-1.05273h0.68408l0.12598,1.05273v1.3418l-0.00879,0.56641l-0.68408,0.14453V176.08618z"\n' +
    "\t\t\t\t\t/>\n" +
    '\t\t\t\t<path d="M107.47461,175.03345h0.69287l0.52197,4.44629c0.69629-0.07227,1.25244-0.2998,1.66992-0.68457\n' +
    "\t\t\t\t\tc0.41699-0.38379,0.61035-0.96875,0.58008-1.75488v-0.22461c0-0.37207-0.0957-0.67188-0.2876-0.90039\n" +
    "\t\t\t\t\tc-0.19238-0.22754-0.48047-0.35352-0.86426-0.37793l-0.44971-0.02637l0.04492-0.55859l0.50391,0.03613\n" +
    "\t\t\t\t\tc0.52783,0.03027,0.94336,0.18945,1.24658,0.47656c0.30273,0.28809,0.46338,0.70898,0.48145,1.26074l0.00879,0.25195\n" +
    '\t\t\t\t\tc0.03613,1.03223-0.27002,1.7998-0.91797,2.30371s-1.52393,0.7832-2.62793,0.83691L107.47461,175.03345z"/>\n' +
    '\t\t\t\t<path d="M113.06396,180.11841l-0.54932-4.53613l-0.06299-0.54883h2.45703c0.52783,0,0.94043,0.16406,1.23779,0.49023\n' +
    "\t\t\t\t\tc0.29688,0.32715,0.4541,0.77344,0.47217,1.33691l0.00928,0.2793C116.6875,179.01782,115.49951,180.01099,113.06396,180.11841z\n" +
    "\t\t\t\t\t M115.36768,178.85864c0.41406-0.34766,0.60303-0.92676,0.56689-1.7373v-0.21582c0-0.38965-0.09424-0.70801-0.2832-0.9541\n" +
    '\t\t\t\t\ts-0.45459-0.36914-0.79639-0.36914h-1.64746l0.46826,3.89746C114.38965,179.41333,114.95361,179.2063,115.36768,178.85864z"/>\n' +
    '\t\t\t\t<path d="M119.59766,176.08618l-0.11719-1.05273h0.68408l0.12598,1.05273v5.74219h-0.69287V176.08618z"/>\n' +
    '\t\t\t\t<path d="M121.31641,179.47974h1.18799c0.48633,0,0.85205-0.14062,1.09814-0.42285s0.36914-0.69629,0.36914-1.24219v-0.56738\n' +
    "\t\t\t\t\tc0-0.5459-0.12305-0.95996-0.36914-1.24219c-0.24609-0.28125-0.61182-0.42285-1.09814-0.42285h-1.18799v-0.54883h1.32324\n" +
    "\t\t\t\t\tc0.64795,0,1.14746,0.19238,1.49854,0.57617s0.52637,0.93945,0.52637,1.66504v0.5127c0,0.72656-0.17529,1.28125-0.52637,1.66504\n" +
    '\t\t\t\t\ts-0.85059,0.57617-1.49854,0.57617h-1.32324V179.47974z"/>\n' +
    '\t\t\t\t<path d="M125.34863,179.65942c0.35352-0.03027,0.59814-0.12988,0.7334-0.30176c0.13477-0.1709,0.20215-0.42969,0.20215-0.77832\n' +
    "\t\t\t\t\tv-3.5459h2.20508c1.0083,0,1.51221,0.55859,1.51221,1.67383v3.32129h-0.69287v-3.34766c0-0.39062-0.08447-0.6709-0.25244-0.8418\n" +
    "\t\t\t\t\ts-0.40771-0.25684-0.71973-0.25684h-1.35889v2.9248c0,0.52832-0.12305,0.92285-0.36914,1.18359\n" +
    '\t\t\t\t\tc-0.24609,0.26172-0.64209,0.41602-1.18799,0.46387L125.34863,179.65942z"/>\n' +
    '\t\t\t\t<path d="M131.29736,176.08618l-0.11719-1.05273h0.68408l0.12598,1.05273v1.3418l-0.00879,0.56641l-0.68408,0.14453V176.08618z"\n' +
    "\t\t\t\t\t/>\n" +
    '\t\t\t\t<path d="M132.80957,179.65942c0.35352-0.03027,0.59814-0.12988,0.7334-0.30176c0.13477-0.1709,0.20215-0.42969,0.20215-0.77832\n' +
    "\t\t\t\t\tv-3.5459h2.20605c1.00781,0,1.51172,0.55859,1.51172,1.67383v3.32129h-0.69336v-3.34766\n" +
    "\t\t\t\t\tc0-0.39062-0.08398-0.6709-0.25195-0.8418s-0.4082-0.25684-0.71973-0.25684h-1.35938v2.9248\n" +
    '\t\t\t\t\tc0,0.52832-0.12305,0.92285-0.36914,1.18359c-0.24609,0.26172-0.64209,0.41602-1.18799,0.46387L132.80957,179.65942z"/>\n' +
    '\t\t\t\t<path d="M138.38965,181.41431c0.3418-0.56348,0.60156-1.17871,0.77832-1.84473s0.26562-1.34668,0.26562-2.04297\n' +
    "\t\t\t\t\ts-0.08887-1.37695-0.26562-2.04297s-0.43652-1.28125-0.77832-1.84473l0.40527-0.19824\n" +
    "\t\t\t\t\tc0.38965,0.63574,0.68555,1.28516,0.88574,1.94824c0.20117,0.66309,0.30176,1.37598,0.30176,2.1377\n" +
    '\t\t\t\t\ts-0.10059,1.47461-0.30176,2.1377c-0.2002,0.66309-0.49609,1.3125-0.88574,1.94824L138.38965,181.41431z"/>\n' +
    '\t\t\t\t<path d="M145.74316,176.68091c0-0.39062-0.08398-0.6709-0.25195-0.8418s-0.4082-0.25684-0.71973-0.25684h-1.74609v-0.54883\n' +
    '\t\t\t\t\th1.89844c1.00879,0,1.5127,0.55859,1.5127,1.67383v5.12109h-0.69336V176.68091z"/>\n' +
    '\t\t\t\t<path d="M147.75879,176.08618l-0.11719-1.05273h0.68359l0.12598,1.05273v3.94238h-0.69238V176.08618z"/>\n' +
    '\t\t\t\t<path d="M149.27051,179.65942c0.35352-0.03027,0.59863-0.12988,0.7334-0.30176c0.13477-0.1709,0.20215-0.42969,0.20215-0.77832\n' +
    "\t\t\t\t\tv-3.5459h2.20508c1.00781,0,1.51172,0.55859,1.51172,1.67383v3.32129h-0.69238v-3.34766\n" +
    "\t\t\t\t\tc0-0.39062-0.08398-0.6709-0.25195-0.8418s-0.4082-0.25684-0.7207-0.25684h-1.3584v2.9248\n" +
    '\t\t\t\t\tc0,0.52832-0.12305,0.92285-0.36914,1.18359c-0.24609,0.26172-0.64258,0.41602-1.18848,0.46387L149.27051,179.65942z"/>\n' +
    '\t\t\t\t<path d="M155.21973,176.08618l-0.11719-1.05273h0.68359l0.12598,1.05273v1.3418l-0.00879,0.56641l-0.68359,0.14453V176.08618z"\n' +
    "\t\t\t\t\t/>\n" +
    '\t\t\t\t<path d="M160.5918,175.48853c0.26465,0.30273,0.39648,0.75098,0.39648,1.34473v3.19531h-0.69336v-3.22168\n' +
    "\t\t\t\t\tc0-0.40234-0.08203-0.70703-0.24707-0.91406s-0.40625-0.31055-0.72461-0.31055h-1.53027v4.44629h-0.69238v-4.76074v-0.23438\n" +
    '\t\t\t\t\th2.37598C159.95605,175.03345,160.32812,175.18481,160.5918,175.48853z"/>\n' +
    '\t\t\t\t<path d="M162.14062,177.59888l0.09863-0.60352h0.59473v3.0332h-0.69336V177.59888z M165.22754,176.68091\n' +
    "\t\t\t\t\tc0-0.39062-0.08398-0.6709-0.25195-0.8418s-0.4082-0.25684-0.71973-0.25684h-2.10645v-0.54883h2.25879\n" +
    '\t\t\t\t\tc1.00879,0,1.5127,0.55859,1.5127,1.67383v3.32129h-0.69336V176.68091z"/>\n' +
    '\t\t\t\t<path d="M169.0166,176.08618l-0.11719-1.05273h0.68359l0.12598,1.05273v3.94238h-0.69238V176.08618z"/>\n' +
    '\t\t\t\t<path d="M172.31055,179.47974c0.31738-0.03613,0.58496-0.11133,0.80078-0.22559\n' +
    "\t\t\t\t\tc0.21582-0.11328,0.39551-0.31152,0.54004-0.59375c0.14355-0.28223,0.2334-0.67773,0.26953-1.1875l0.15332-1.89062h-3.24023\n" +
    "\t\t\t\t\tv-0.54883h3.97852l-0.02734,0.49512l-0.14453,1.80859c-0.04199,0.87012-0.26172,1.53027-0.66113,1.98047\n" +
    "\t\t\t\t\ts-0.93164,0.6875-1.59766,0.71094L172.31055,179.47974z M170.85254,177.4729l0.09863-0.6123h0.59375v4.96777h-0.69238V177.4729z\n" +
    '\t\t\t\t\t"/>\n' +
    '\t\t\t\t<path d="M177.26953,179.65942c0.35352-0.03027,0.59863-0.12988,0.7334-0.30176c0.13477-0.1709,0.20215-0.42969,0.20215-0.77832\n' +
    "\t\t\t\t\tv-3.5459h2.20508c1.00781,0,1.51172,0.55859,1.51172,1.67383v3.32129h-0.69238v-3.34766\n" +
    "\t\t\t\t\tc0-0.39062-0.08398-0.6709-0.25195-0.8418s-0.4082-0.25684-0.7207-0.25684h-1.3584v2.9248\n" +
    '\t\t\t\t\tc0,0.52832-0.12305,0.92285-0.36914,1.18359c-0.24609,0.26172-0.64258,0.41602-1.18848,0.46387L177.26953,179.65942z"/>\n' +
    '\t\t\t\t<path d="M186.48535,180.02856l-2.04297-3.06934l-0.96289,3.06934h-0.66602l0.98145-3.0957l0.24316-0.59473l-0.82812-1.30469\n' +
    '\t\t\t\t\th0.71094l0.80078,1.25977l0.8457,1.25098l0.20703-0.74707l0.54004-1.76367h0.6748l-1.0166,3.12305l1.26855,1.87207H186.48535z"\n' +
    "\t\t\t\t\t/>\n" +
    '\t\t\t\t<path d="M190.05859,176.08618l-0.11719-1.05273h0.68359l0.12598,1.05273v5.74219h-0.69238V176.08618z"/>\n' +
    '\t\t\t\t<path d="M195.82227,179.4436c-0.35645,0.45605-0.86816,0.68359-1.53418,0.68359c-0.22852,0-0.47363-0.02637-0.73828-0.08105\n' +
    "\t\t\t\t\tl0.07227-0.54883c0.19824,0.04883,0.40234,0.07227,0.6123,0.07227c0.49805,0,0.86523-0.17285,1.10254-0.51758\n" +
    "\t\t\t\t\tc0.23633-0.34473,0.35547-0.85938,0.35547-1.54297c0-1.30859-0.42969-1.96289-1.28711-1.96289\n" +
    "\t\t\t\t\tc-0.32422,0-0.58496,0.09375-0.7832,0.2793c-0.19824,0.18652-0.33301,0.42871-0.40527,0.72949l-0.63867,3.47363h-0.69336\n" +
    "\t\t\t\t\tl0.66602-3.50098l-0.69238-1.49414h0.71973l0.4502,1.08008c0.12012-0.37793,0.31152-0.66113,0.57617-0.85059\n" +
    "\t\t\t\t\tc0.26367-0.18848,0.56348-0.2832,0.89941-0.2832c0.57031,0,1.02148,0.19629,1.35449,0.58887\n" +
    '\t\t\t\t\tc0.33301,0.39355,0.5,1.03125,0.5,1.91309C196.3584,178.33325,196.17969,178.98755,195.82227,179.4436z"/>\n' +
    '\t\t\t\t<path d="M197.87012,180.11841l-0.54883-4.53613l-0.0625-0.54883h2.45703c0.52734,0,0.94043,0.16406,1.2373,0.49023\n' +
    "\t\t\t\t\tc0.29688,0.32715,0.4541,0.77344,0.47266,1.33691l0.00879,0.2793C201.49414,179.01782,200.30664,180.01099,197.87012,180.11841z\n" +
    "\t\t\t\t\t M200.1748,178.85864c0.41406-0.34766,0.60254-0.92676,0.56641-1.7373v-0.21582c0-0.38965-0.09473-0.70801-0.2832-0.9541\n" +
    "\t\t\t\t\tc-0.18945-0.24609-0.45508-0.36914-0.79688-0.36914h-1.64648l0.46777,3.89746\n" +
    '\t\t\t\t\tC199.19629,179.41333,199.76074,179.2063,200.1748,178.85864z"/>\n' +
    '\t\t\t\t<path d="M206.35254,179.4436c-0.35645,0.45605-0.86816,0.68359-1.53418,0.68359c-0.22852,0-0.47363-0.02637-0.73828-0.08105\n' +
    "\t\t\t\t\tl0.07227-0.54883c0.19824,0.04883,0.40234,0.07227,0.6123,0.07227c0.49805,0,0.86523-0.17285,1.10254-0.51758\n" +
    "\t\t\t\t\tc0.23633-0.34473,0.35547-0.85938,0.35547-1.54297c0-1.30859-0.42969-1.96289-1.28711-1.96289\n" +
    "\t\t\t\t\tc-0.32422,0-0.58496,0.09375-0.7832,0.2793c-0.19824,0.18652-0.33301,0.42871-0.40527,0.72949l-0.63867,3.47363h-0.69336\n" +
    "\t\t\t\t\tl0.66602-3.50098l-0.69238-1.49414h0.71973l0.4502,1.08008c0.12012-0.37793,0.31152-0.66113,0.57617-0.85059\n" +
    "\t\t\t\t\tc0.26367-0.18848,0.56348-0.2832,0.89941-0.2832c0.57031,0,1.02148,0.19629,1.35449,0.58887\n" +
    '\t\t\t\t\tc0.33301,0.39355,0.5,1.03125,0.5,1.91309C206.88867,178.33325,206.70996,178.98755,206.35254,179.4436z"/>\n' +
    '\t\t\t\t<path d="M209.84961,176.08618l-0.11719-1.05273h0.68359l0.12598,1.05273v3.94238h-0.69238V176.08618z"/>\n' +
    '\t\t\t\t<path d="M213.14355,179.47974c0.31738-0.03613,0.58496-0.11133,0.80078-0.22559\n' +
    "\t\t\t\t\tc0.21582-0.11328,0.39551-0.31152,0.54004-0.59375c0.14355-0.28223,0.2334-0.67773,0.26953-1.1875l0.15332-1.89062h-3.24023\n" +
    "\t\t\t\t\tv-0.54883h3.97852l-0.02734,0.49512l-0.14453,1.80859c-0.04199,0.87012-0.26172,1.53027-0.66113,1.98047\n" +
    "\t\t\t\t\ts-0.93164,0.6875-1.59766,0.71094L213.14355,179.47974z M211.68555,177.4729l0.09863-0.6123h0.59375v4.96777h-0.69238V177.4729z\n" +
    '\t\t\t\t\t"/>\n' +
    '\t\t\t\t<path d="M216.80664,176.08618l-0.11719-1.05273h0.68359l0.12598,1.05273v3.94238h-0.69238V176.08618z"/>\n' +
    '\t\t\t\t<path d="M218.82227,176.08618l-0.11719-1.05273h0.68359l0.12598,1.05273v3.94238h-0.69238V176.08618z"/>\n' +
    '\t\t\t\t<path d="M222.11621,179.47974c0.31738-0.03613,0.58496-0.11133,0.80078-0.22559\n' +
    "\t\t\t\t\tc0.21582-0.11328,0.39551-0.31152,0.54004-0.59375c0.14355-0.28223,0.2334-0.67773,0.26953-1.1875l0.15332-1.89062h-3.24023\n" +
    "\t\t\t\t\tv-0.54883h3.97852l-0.02734,0.49512l-0.14453,1.80859c-0.04199,0.87012-0.26172,1.53027-0.66113,1.98047\n" +
    '\t\t\t\t\ts-0.93164,0.6875-1.59766,0.71094L222.11621,179.47974z M220.6582,177.4729l0.09863-0.6123h0.59375v4.96777h-0.69238V177.4729z"\n' +
    "\t\t\t\t\t/>\n" +
    '\t\t\t\t<path d="M229.52734,179.4436c-0.35645,0.45605-0.86816,0.68359-1.53418,0.68359c-0.22852,0-0.47363-0.02637-0.73828-0.08105\n' +
    "\t\t\t\t\tl0.07227-0.54883c0.19824,0.04883,0.40234,0.07227,0.6123,0.07227c0.49805,0,0.86523-0.17285,1.10254-0.51758\n" +
    "\t\t\t\t\tc0.23633-0.34473,0.35547-0.85938,0.35547-1.54297c0-1.30859-0.42969-1.96289-1.28711-1.96289\n" +
    "\t\t\t\t\tc-0.32422,0-0.58496,0.09375-0.7832,0.2793c-0.19824,0.18652-0.33301,0.42871-0.40527,0.72949l-0.63867,3.47363h-0.69336\n" +
    "\t\t\t\t\tl0.66602-3.50098l-0.69238-1.49414h0.71973l0.4502,1.08008c0.12012-0.37793,0.31152-0.66113,0.57617-0.85059\n" +
    "\t\t\t\t\tc0.26367-0.18848,0.56348-0.2832,0.89941-0.2832c0.57031,0,1.02148,0.19629,1.35449,0.58887\n" +
    '\t\t\t\t\tc0.33301,0.39355,0.5,1.03125,0.5,1.91309C230.06348,178.33325,229.88477,178.98755,229.52734,179.4436z"/>\n' +
    '\t\t\t\t<path d="M231.06152,177.59888l0.09863-0.60352h0.59473v3.0332h-0.69336V177.59888z M234.14844,176.68091\n' +
    "\t\t\t\t\tc0-0.39062-0.08398-0.6709-0.25195-0.8418s-0.4082-0.25684-0.71973-0.25684h-2.10645v-0.54883h2.25879\n" +
    '\t\t\t\t\tc1.00879,0,1.5127,0.55859,1.5127,1.67383v3.32129h-0.69336V176.68091z"/>\n' +
    '\t\t\t\t<path d="M237.9375,176.08618l-0.11719-1.05273h0.68359l0.12598,1.05273v3.94238h-0.69238V176.08618z"/>\n' +
    '\t\t\t\t<path d="M241.23242,179.47974c0.31738-0.03613,0.58496-0.11133,0.80078-0.22559\n' +
    "\t\t\t\t\tc0.21582-0.11328,0.39551-0.31152,0.54004-0.59375c0.14355-0.28223,0.2334-0.67773,0.26953-1.1875l0.15332-1.89062h-3.24023\n" +
    "\t\t\t\t\tv-0.54883h3.97852l-0.02734,0.49512l-0.14453,1.80859c-0.04199,0.87012-0.26172,1.53027-0.66113,1.98047\n" +
    "\t\t\t\t\ts-0.93164,0.6875-1.59766,0.71094L241.23242,179.47974z M239.77441,177.4729l0.09863-0.6123h0.59375v4.96777h-0.69238V177.4729z\n" +
    '\t\t\t\t\t"/>\n' +
    '\t\t\t\t<path d="M244.73242,177.59888l0.09863-0.60352h0.59473v3.0332h-0.69336V177.59888z M247.81934,176.68091\n' +
    "\t\t\t\t\tc0-0.39062-0.08398-0.6709-0.25195-0.8418s-0.4082-0.25684-0.71973-0.25684h-2.10645v-0.54883H247\n" +
    '\t\t\t\t\tc1.00879,0,1.5127,0.55859,1.5127,1.67383v3.32129h-0.69336V176.68091z"/>\n' +
    '\t\t\t\t<path d="M26.98828,187.43579c0-0.46191,0.08105-0.81348,0.24316-1.05371h-2.52002v-0.54883h3.85205v0.54883h-0.66602\n' +
    '\t\t\t\t\tc-0.14404,0.24609-0.21631,0.59766-0.21631,1.05371v3.39258h-0.69287V187.43579z"/>\n' +
    '\t\t\t\t<path d="M33.36035,185.83325c0,1.23633-0.04639,2.15723-0.13916,2.7627c-0.09326,0.60645-0.31201,1.08691-0.65723,1.44043\n' +
    "\t\t\t\t\tc-0.34473,0.35449-0.89844,0.60645-1.66016,0.75586l-1.51221,0.29688l-0.09912-0.55762l1.59326-0.30566l-1.16113-4.39258\n" +
    "\t\t\t\t\th0.69287l1.09814,4.21191c0.37207-0.14941,0.63916-0.38184,0.80078-0.69727c0.16211-0.31543,0.26123-0.7168,0.29736-1.20605\n" +
    '\t\t\t\t\tc0.03564-0.48926,0.05664-1.25879,0.06299-2.30859H33.36035z"/>\n' +
    '\t\t\t\t<path d="M39.69629,186.28833c0.26367,0.30273,0.396,0.75098,0.396,1.34473v3.19531h-0.69287v-3.22168\n' +
    "\t\t\t\t\tc0-0.40234-0.08252-0.70703-0.24756-0.91406s-0.40674-0.31055-0.72461-0.31055h-1.53027v4.44629H36.2041v-4.76074v-0.23438\n" +
    '\t\t\t\t\th2.37598C39.06006,185.83325,39.43213,185.98462,39.69629,186.28833z"/>\n' +
    '\t\t\t\t<path d="M41.40625,186.88599l-0.11719-1.05273h0.68408l0.12598,1.05273v3.94238h-0.69287V186.88599z"/>\n' +
    '\t\t\t\t<path d="M42.91846,190.45923c0.35352-0.03027,0.59814-0.12988,0.7334-0.30176c0.13477-0.1709,0.20215-0.42969,0.20215-0.77832\n' +
    "\t\t\t\t\tv-3.5459h2.20508c1.0083,0,1.51221,0.55859,1.51221,1.67383v3.32129h-0.69287v-3.34766c0-0.39062-0.08447-0.6709-0.25244-0.8418\n" +
    "\t\t\t\t\ts-0.40771-0.25684-0.71973-0.25684h-1.35889v2.9248c0,0.52832-0.12305,0.92285-0.36914,1.18359\n" +
    '\t\t\t\t\tc-0.24609,0.26172-0.64209,0.41602-1.18799,0.46387L42.91846,190.45923z"/>\n' +
    '\t\t\t\t<path d="M52.61572,190.24341c-0.35742,0.45605-0.86865,0.68359-1.53467,0.68359c-0.22803,0-0.47412-0.02637-0.73779-0.08105\n' +
    "\t\t\t\t\tl0.07178-0.54883c0.19824,0.04883,0.40186,0.07227,0.61182,0.07227c0.49805,0,0.86572-0.17285,1.10254-0.51758\n" +
    "\t\t\t\t\tc0.2373-0.34473,0.35547-0.85938,0.35547-1.54297c0-1.30859-0.42871-1.96289-1.28662-1.96289\n" +
    "\t\t\t\t\tc-0.32422,0-0.58496,0.09375-0.7832,0.2793c-0.19824,0.18652-0.33301,0.42871-0.40479,0.72949l-0.63916,3.47363h-0.69287\n" +
    "\t\t\t\t\tl0.66602-3.50098l-0.69336-1.49414h0.72021l0.44971,1.08008c0.12012-0.37793,0.31201-0.66113,0.57617-0.85059\n" +
    "\t\t\t\t\tc0.26416-0.18848,0.56396-0.2832,0.8999-0.2832c0.57031,0,1.02148,0.19629,1.35449,0.58887\n" +
    '\t\t\t\t\tc0.33301,0.39355,0.49951,1.03125,0.49951,1.91309C53.15088,189.13306,52.97266,189.78735,52.61572,190.24341z"/>\n' +
    '\t\t\t\t<path d="M57.48047,185.83325v0.58496l-1.7373,4.41016h-0.69287l1.73682-4.44629h-2.78955v-2.00684h0.63867v1.45801H57.48047z"/>\n' +
    '\t\t\t\t<path d="M63.86572,189.96411c-0.68115,0.62402-1.71436,0.94238-3.10059,0.9541l-0.62988-5.08496h0.66602l0.30615,2.54688\n' +
    "\t\t\t\t\tc0.27002-0.14355,0.47998-0.29785,0.62988-0.46289s0.25928-0.36816,0.32861-0.60742\n" +
    "\t\t\t\t\tc0.06885-0.24023,0.10938-0.5498,0.12158-0.92773l0.00879-0.53027h0.66602l-0.00879,0.52148\n" +
    "\t\t\t\t\tc-0.01221,0.66016-0.14404,1.18359-0.396,1.57031c-0.25195,0.3877-0.68115,0.71289-1.28711,0.97656l0.1709,1.38574\n" +
    "\t\t\t\t\tc0.9541-0.05957,1.66797-0.3457,2.14209-0.85938c0.47363-0.5127,0.70801-1.2793,0.70215-2.29883l-0.00928-1.31445h0.69287\n" +
    '\t\t\t\t\tl0.00928,1.28711C64.88428,188.39282,64.54688,189.34009,63.86572,189.96411z"/>\n' +
    '\t\t\t\t<path d="M66.06641,186.88599l-0.11719-1.05273h0.68408l0.12598,1.05273v1.3418l-0.00879,0.56641l-0.68408,0.14453V186.88599z"/>\n' +
    '\t\t\t\t<path d="M73.78809,187.50708v3.32129h-0.69287h-3.06885h-0.17139v-4.99512h0.69336h1.72803\n' +
    "\t\t\t\t\tC73.28418,185.83325,73.78809,186.39185,73.78809,187.50708z M73.09521,190.27954v-2.79883\n" +
    '\t\t\t\t\tc0-0.39062-0.08398-0.6709-0.25195-0.8418s-0.4082-0.25684-0.72021-0.25684h-1.57471v3.89746H73.09521z"/>\n' +
    '\t\t\t\t<path d="M75.10205,186.88599l-0.11719-1.05273h0.68408l0.12598,1.05273v1.3418l-0.00879,0.56641l-0.68408,0.14453V186.88599z"/>\n' +
    '\t\t\t\t<path d="M80.71777,185.83325c0,1.23633-0.04639,2.15723-0.13916,2.7627c-0.09326,0.60645-0.31201,1.08691-0.65723,1.44043\n' +
    "\t\t\t\t\tc-0.34473,0.35449-0.89844,0.60645-1.66016,0.75586l-1.51221,0.29688l-0.09912-0.55762l1.59326-0.30566l-1.16113-4.39258\n" +
    "\t\t\t\t\th0.69287l1.09814,4.21191c0.37207-0.14941,0.63916-0.38184,0.80078-0.69727c0.16211-0.31543,0.26123-0.7168,0.29736-1.20605\n" +
    '\t\t\t\t\tc0.03564-0.48926,0.05664-1.25879,0.06299-2.30859H80.71777z"/>\n' +
    '\t\t\t\t<path d="M83.1748,190.27954c0.31787-0.03613,0.58496-0.11133,0.80078-0.22559c0.21631-0.11328,0.396-0.31152,0.54004-0.59375\n' +
    "\t\t\t\t\ts0.23438-0.67773,0.27002-1.1875l0.15332-1.89062h-3.24023v-0.54883h3.97803l-0.02686,0.49512l-0.14404,1.80859\n" +
    "\t\t\t\t\tc-0.04199,0.87012-0.2627,1.53027-0.66162,1.98047s-0.93164,0.6875-1.59766,0.71094L83.1748,190.27954z M81.7168,188.27271\n" +
    '\t\t\t\t\tl0.09912-0.6123h0.59375v4.96777H81.7168V188.27271z"/>\n' +
    '\t\t\t\t<path d="M89.06982,187.48071c0-0.39062-0.08398-0.6709-0.25195-0.8418s-0.4082-0.25684-0.72021-0.25684h-1.59277v-0.54883\n' +
    '\t\t\t\t\th1.74609c1.00781,0,1.51172,0.55859,1.51172,1.67383v3.32129h-0.69287V187.48071z"/>\n' +
    '\t\t\t\t<path d="M90.93262,188.39868l0.09912-0.60352h0.59375v3.0332h-0.69287V188.39868z M94.01953,187.48071\n' +
    "\t\t\t\t\tc0-0.39062-0.08398-0.6709-0.25195-0.8418s-0.4082-0.25684-0.72021-0.25684h-2.10596v-0.54883h2.25928\n" +
    '\t\t\t\t\tc1.00781,0,1.51172,0.55859,1.51172,1.67383v3.32129h-0.69287V187.48071z"/>\n' +
    '\t\t\t\t<path d="M97.30469,190.45923c0.35352-0.03027,0.59814-0.12988,0.7334-0.30176c0.13477-0.1709,0.20215-0.42969,0.20215-0.77832\n' +
    "\t\t\t\t\tv-3.5459h2.20508c1.0083,0,1.51221,0.55859,1.51221,1.67383v3.32129h-0.69287v-3.34766c0-0.39062-0.08447-0.6709-0.25244-0.8418\n" +
    "\t\t\t\t\ts-0.40771-0.25684-0.71973-0.25684h-1.35889v2.9248c0,0.52832-0.12305,0.92285-0.36914,1.18359\n" +
    '\t\t\t\t\tc-0.24609,0.26172-0.64209,0.41602-1.18799,0.46387L97.30469,190.45923z"/>\n' +
    '\t\t\t\t<path d="M106.521,190.82837l-2.04346-3.06934l-0.96289,3.06934h-0.66602l0.98096-3.0957l0.24316-0.59473l-0.82812-1.30469\n' +
    '\t\t\t\t\th0.71094l0.80127,1.25977l0.8457,1.25098l0.20703-0.74707l0.54004-1.76367h0.67529l-1.01709,3.12305l1.26904,1.87207H106.521z"\n' +
    "\t\t\t\t\t/>\n" +
    '\t\t\t\t<path d="M108.29346,186.88599l-0.11719-1.05273h0.68408l0.12598,1.05273v3.94238h-0.69287V186.88599z"/>\n' +
    '\t\t\t\t<path d="M114.62061,187.48071c0-0.39062-0.08398-0.6709-0.25195-0.8418s-0.4082-0.25684-0.71973-0.25684h-1.74609v-0.54883\n' +
    '\t\t\t\t\th1.89893c1.00781,0,1.51172,0.55859,1.51172,1.67383v5.12109h-0.69287V187.48071z"/>\n' +
    '\t\t\t\t<path d="M116.63672,186.88599l-0.11719-1.05273h0.68408l0.12598,1.05273v3.94238h-0.69287V186.88599z"/>\n' +
    '\t\t\t\t<path d="M118.14844,190.45923c0.35352-0.03027,0.59814-0.12988,0.7334-0.30176c0.13477-0.1709,0.20215-0.42969,0.20215-0.77832\n' +
    "\t\t\t\t\tv-3.5459h2.20508c1.0083,0,1.51221,0.55859,1.51221,1.67383v3.32129h-0.69287v-3.34766c0-0.39062-0.08447-0.6709-0.25244-0.8418\n" +
    "\t\t\t\t\ts-0.40771-0.25684-0.71973-0.25684h-1.35889v2.9248c0,0.52832-0.12305,0.92285-0.36914,1.18359\n" +
    '\t\t\t\t\tc-0.24609,0.26172-0.64209,0.41602-1.18799,0.46387L118.14844,190.45923z"/>\n' +
    '\t\t\t\t<path d="M124.09766,186.88599l-0.11719-1.05273h0.68408l0.12598,1.05273v1.3418l-0.00879,0.56641l-0.68408,0.14453V186.88599z"\n' +
    "\t\t\t\t\t/>\n" +
    '\t\t\t\t<path d="M129.47021,186.28833c0.26367,0.30273,0.396,0.75098,0.396,1.34473v3.19531h-0.69287v-3.22168\n' +
    "\t\t\t\t\tc0-0.40234-0.08252-0.70703-0.24756-0.91406s-0.40674-0.31055-0.72461-0.31055h-1.53027v4.44629h-0.69287v-4.76074v-0.23438\n" +
    '\t\t\t\t\th2.37598C128.83398,185.83325,129.20605,185.98462,129.47021,186.28833z"/>\n' +
    '\t\t\t\t<path d="M131.01807,188.39868l0.09912-0.60352h0.59375v3.0332h-0.69287V188.39868z M134.10498,187.48071\n' +
    "\t\t\t\t\tc0-0.39062-0.08398-0.6709-0.25195-0.8418s-0.4082-0.25684-0.72021-0.25684h-2.10596v-0.54883h2.25928\n" +
    '\t\t\t\t\tc1.00781,0,1.51172,0.55859,1.51172,1.67383v3.32129h-0.69287V187.48071z"/>\n' +
    '\t\t\t\t<path d="M137.89453,186.88599l-0.11719-1.05273h0.68359l0.12598,1.05273v3.94238h-0.69238V186.88599z"/>\n' +
    '\t\t\t\t<path d="M141.18945,190.27954c0.31738-0.03613,0.58496-0.11133,0.80078-0.22559\n' +
    "\t\t\t\t\tc0.21582-0.11328,0.39551-0.31152,0.54004-0.59375c0.14355-0.28223,0.2334-0.67773,0.26953-1.1875l0.15332-1.89062h-3.24023\n" +
    "\t\t\t\t\tv-0.54883h3.97852l-0.02734,0.49512l-0.14453,1.80859c-0.04199,0.87012-0.26172,1.53027-0.66113,1.98047\n" +
    "\t\t\t\t\ts-0.93164,0.6875-1.59766,0.71094L141.18945,190.27954z M139.73145,188.27271l0.09863-0.6123h0.59375v4.96777h-0.69238\n" +
    '\t\t\t\t\tV188.27271z"/>\n' +
    '\t\t\t\t<path d="M148.01953,185.83325v0.58496l-1.7373,4.41016h-0.69238l1.73633-4.44629h-2.79004v-2.00684h0.63965v1.45801H148.01953z"\n' +
    "\t\t\t\t\t/>\n" +
    '\t\t\t\t<path d="M154.8418,187.50708v3.32129h-0.69336h-3.06934h-0.1709v-4.99512h0.69336h1.72754\n' +
    "\t\t\t\t\tC154.33691,185.83325,154.8418,186.39185,154.8418,187.50708z M154.14844,190.27954v-2.79883\n" +
    '\t\t\t\t\tc0-0.39062-0.08398-0.6709-0.25195-0.8418s-0.4082-0.25684-0.71973-0.25684h-1.5752v3.89746H154.14844z"/>\n' +
    '\t\t\t\t<path d="M156.15527,186.88599l-0.11719-1.05273h0.68359l0.12598,1.05273v1.3418l-0.00879,0.56641l-0.68359,0.14453V186.88599z"\n' +
    "\t\t\t\t\t/>\n" +
    '\t\t\t\t<path d="M157.91113,185.83325h0.69238l0.52246,4.44629c0.69531-0.07227,1.25195-0.2998,1.66895-0.68457\n' +
    "\t\t\t\t\tc0.41699-0.38379,0.61035-0.96875,0.58105-1.75488v-0.22461c0-0.37207-0.09668-0.67188-0.28809-0.90039\n" +
    "\t\t\t\t\tc-0.19238-0.22754-0.48047-0.35352-0.86426-0.37793l-0.4502-0.02637l0.04492-0.55859l0.50391,0.03613\n" +
    "\t\t\t\t\tc0.52832,0.03027,0.94434,0.18945,1.24707,0.47656c0.30273,0.28809,0.46387,0.70898,0.48145,1.26074l0.00879,0.25195\n" +
    '\t\t\t\t\tc0.03613,1.03223-0.26953,1.7998-0.91797,2.30371c-0.64746,0.50391-1.52441,0.7832-2.62793,0.83691L157.91113,185.83325z"/>\n' +
    '\t\t\t\t<path d="M164.42676,190.27954c0.31738-0.03613,0.58496-0.11133,0.80078-0.22559\n' +
    "\t\t\t\t\tc0.21582-0.11328,0.39551-0.31152,0.54004-0.59375c0.14355-0.28223,0.2334-0.67773,0.26953-1.1875l0.15332-1.89062h-3.24023\n" +
    "\t\t\t\t\tv-0.54883h3.97852l-0.02734,0.49512l-0.14453,1.80859c-0.04199,0.87012-0.26172,1.53027-0.66113,1.98047\n" +
    "\t\t\t\t\ts-0.93164,0.6875-1.59766,0.71094L164.42676,190.27954z M162.96875,188.27271l0.09863-0.6123h0.59375v4.96777h-0.69238\n" +
    '\t\t\t\t\tV188.27271z"/>\n' +
    '\t\t\t\t<path d="M168.08984,186.88599l-0.11719-1.05273h0.68359l0.12598,1.05273v1.3418l-0.00879,0.56641l-0.68359,0.14453V186.88599z"\n' +
    "\t\t\t\t\t/>\n" +
    '\t\t\t\t<path d="M173.78613,190.27954v0.54883h-1.33203h-2.5918v-0.54883h2.5918v-2.79883c0-0.39062-0.08398-0.6709-0.25195-0.8418\n' +
    '\t\t\t\t\ts-0.4082-0.25684-0.71973-0.25684h-1.62012v-0.54883h1.77344c1.00781,0,1.51172,0.55859,1.51172,1.67383v2.77246H173.78613z"/>\n' +
    '\t\t\t\t<path d="M174.83887,186.88599l-0.11719-1.05273h0.68359l0.12598,1.05273v3.94238h-0.69238V186.88599z"/>\n' +
    '\t\t\t\t<path d="M180.12207,190.82837l-2.04297-3.06934l-0.96289,3.06934h-0.66602l0.98145-3.0957l0.24316-0.59473l-0.82812-1.30469\n' +
    '\t\t\t\t\th0.71094l0.80078,1.25977l0.8457,1.25098l0.20703-0.74707l0.54004-1.76367h0.6748l-1.0166,3.12305l1.26855,1.87207H180.12207z"\n' +
    "\t\t\t\t\t/>\n" +
    '\t\t\t\t<path d="M185.74707,187.43579c0-0.46191,0.08105-0.81348,0.24316-1.05371h-2.52051v-0.54883h3.85254v0.54883h-0.66602\n' +
    '\t\t\t\t\tc-0.14453,0.24609-0.21582,0.59766-0.21582,1.05371v3.39258h-0.69336V187.43579z"/>\n' +
    '\t\t\t\t<path d="M188.51953,186.88599l-0.11719-1.05273h0.68359l0.12598,1.05273v1.3418l-0.00879,0.56641l-0.68359,0.14453V186.88599z"\n' +
    "\t\t\t\t\t/>\n" +
    '\t\t\t\t<path d="M194.2832,190.24341c-0.35645,0.45605-0.86816,0.68359-1.53418,0.68359c-0.22852,0-0.47363-0.02637-0.73828-0.08105\n' +
    "\t\t\t\t\tl0.07227-0.54883c0.19824,0.04883,0.40234,0.07227,0.6123,0.07227c0.49805,0,0.86523-0.17285,1.10254-0.51758\n" +
    "\t\t\t\t\tc0.23633-0.34473,0.35547-0.85938,0.35547-1.54297c0-1.30859-0.42969-1.96289-1.28711-1.96289\n" +
    "\t\t\t\t\tc-0.32422,0-0.58496,0.09375-0.7832,0.2793c-0.19824,0.18652-0.33301,0.42871-0.40527,0.72949l-0.63867,3.47363h-0.69336\n" +
    "\t\t\t\t\tl0.66602-3.50098l-0.69238-1.49414h0.71973l0.4502,1.08008c0.12012-0.37793,0.31152-0.66113,0.57617-0.85059\n" +
    "\t\t\t\t\tc0.26367-0.18848,0.56348-0.2832,0.89941-0.2832c0.57031,0,1.02148,0.19629,1.35449,0.58887\n" +
    '\t\t\t\t\tc0.33301,0.39355,0.5,1.03125,0.5,1.91309C194.81934,189.13306,194.64062,189.78735,194.2832,190.24341z"/>\n' +
    '\t\t\t\t<path d="M199.49902,190.82837h-0.71094h-3.15918v-0.54883h2.83496l-2.6543-4.44629h0.71973l1.27832,2.15137l0.91797-2.15137\n' +
    '\t\t\t\t\th0.70215l-1.24219,2.78125L199.49902,190.82837z"/>\n' +
    '\t\t\t\t<path d="M200.4707,188.39868l0.09863-0.60352h0.59473v3.0332h-0.69336V188.39868z M203.55762,187.48071\n' +
    "\t\t\t\t\tc0-0.39062-0.08398-0.6709-0.25195-0.8418s-0.4082-0.25684-0.71973-0.25684h-2.10645v-0.54883h2.25879\n" +
    '\t\t\t\t\tc1.00879,0,1.5127,0.55859,1.5127,1.67383v3.32129h-0.69336V187.48071z"/>\n' +
    '\t\t\t\t<path d="M208.71484,185.83325v0.58496l-1.7373,4.41016h-0.69238l1.73633-4.44629h-2.79004v-2.00684h0.63965v1.45801H208.71484z"\n' +
    "\t\t\t\t\t/>\n" +
    '\t\t\t\t<path d="M214.89746,190.82837l-2.04297-3.06934l-0.96289,3.06934h-0.66602l0.98145-3.0957l0.24316-0.59473l-0.82812-1.30469\n' +
    '\t\t\t\t\th0.71094l0.80078,1.25977l0.8457,1.25098l0.20703-0.74707l0.54004-1.76367h0.6748l-1.0166,3.12305l1.26855,1.87207H214.89746z"\n' +
    "\t\t\t\t\t/>\n" +
    '\t\t\t\t<path d="M219.83887,185.83325v0.58496l-1.7373,4.41016h-0.69238l1.73633-4.44629h-2.79004v-2.00684h0.63965v1.45801H219.83887z"\n' +
    "\t\t\t\t\t/>\n" +
    '\t\t\t\t<path d="M224.4248,189.96411c-0.68164,0.62402-1.71484,0.94238-3.10059,0.9541l-0.62988-5.08496h0.66602l0.30566,2.54688\n' +
    "\t\t\t\t\tc0.26953-0.14355,0.47949-0.29785,0.62988-0.46289s0.25977-0.36816,0.32812-0.60742\n" +
    "\t\t\t\t\tc0.06934-0.24023,0.10938-0.5498,0.12207-0.92773l0.00879-0.53027h0.66602l-0.00879,0.52148\n" +
    "\t\t\t\t\tc-0.0127,0.66016-0.14453,1.18359-0.39648,1.57031c-0.25195,0.3877-0.68066,0.71289-1.28711,0.97656l0.17188,1.38574\n" +
    "\t\t\t\t\tc0.95312-0.05957,1.66797-0.3457,2.1416-0.85938c0.47363-0.5127,0.70801-1.2793,0.70215-2.29883l-0.00879-1.31445h0.69238\n" +
    '\t\t\t\t\tl0.00879,1.28711C225.44238,188.39282,225.10547,189.34009,224.4248,189.96411z"/>\n' +
    '\t\t\t\t<path d="M230.10742,189.74829l0.9541,2.87988h-0.68359l-2.24121-6.79492h0.6748l1.00781,3.05078l1.22461-3.05078h0.65625\n' +
    '\t\t\t\t\tL230.10742,189.74829z"/>\n' +
    '\t\t\t\t<path d="M235.78711,185.83325v0.58496l-1.7373,4.41016h-0.69238l1.73633-4.44629h-2.79004v-2.00684h0.63965v1.45801H235.78711z"\n' +
    "\t\t\t\t\t/>\n" +
    '\t\t\t\t<path d="M240.65039,190.24341c-0.35645,0.45605-0.86816,0.68359-1.53418,0.68359c-0.22852,0-0.47363-0.02637-0.73828-0.08105\n' +
    "\t\t\t\t\tl0.07227-0.54883c0.19824,0.04883,0.40234,0.07227,0.6123,0.07227c0.49805,0,0.86523-0.17285,1.10254-0.51758\n" +
    "\t\t\t\t\tc0.23633-0.34473,0.35547-0.85938,0.35547-1.54297c0-1.30859-0.42969-1.96289-1.28711-1.96289\n" +
    "\t\t\t\t\tc-0.32422,0-0.58496,0.09375-0.7832,0.2793c-0.19824,0.18652-0.33301,0.42871-0.40527,0.72949l-0.63867,3.47363h-0.69336\n" +
    "\t\t\t\t\tl0.66602-3.50098l-0.69238-1.49414h0.71973l0.4502,1.08008c0.12012-0.37793,0.31152-0.66113,0.57617-0.85059\n" +
    "\t\t\t\t\tc0.26367-0.18848,0.56348-0.2832,0.89941-0.2832c0.57031,0,1.02148,0.19629,1.35449,0.58887\n" +
    '\t\t\t\t\tc0.33301,0.39355,0.5,1.03125,0.5,1.91309C241.18652,189.13306,241.00781,189.78735,240.65039,190.24341z"/>\n' +
    '\t\t\t\t<path d="M242.34766,186.88599l-0.11719-1.05273h0.68359l0.12598,1.05273v3.94238h-0.69238V186.88599z"/>\n' +
    '\t\t\t\t<path d="M248.11133,190.24341c-0.35645,0.45605-0.86816,0.68359-1.53418,0.68359c-0.22852,0-0.47363-0.02637-0.73828-0.08105\n' +
    "\t\t\t\t\tl0.07227-0.54883c0.19824,0.04883,0.40234,0.07227,0.6123,0.07227c0.49805,0,0.86523-0.17285,1.10254-0.51758\n" +
    "\t\t\t\t\tc0.23633-0.34473,0.35547-0.85938,0.35547-1.54297c0-1.30859-0.42969-1.96289-1.28711-1.96289\n" +
    "\t\t\t\t\tc-0.32422,0-0.58496,0.09375-0.7832,0.2793c-0.19824,0.18652-0.33301,0.42871-0.40527,0.72949l-0.63867,3.47363h-0.69336\n" +
    "\t\t\t\t\tl0.66602-3.50098l-0.69238-1.49414h0.71973l0.4502,1.08008c0.12012-0.37793,0.31152-0.66113,0.57617-0.85059\n" +
    "\t\t\t\t\tc0.26367-0.18848,0.56348-0.2832,0.89941-0.2832c0.57031,0,1.02148,0.19629,1.35449,0.58887\n" +
    '\t\t\t\t\tc0.33301,0.39355,0.5,1.03125,0.5,1.91309C248.64746,189.13306,248.46875,189.78735,248.11133,190.24341z"/>\n' +
    '\t\t\t\t<path d="M105.71924,201.58911c-0.0957-0.09961-0.14355-0.22363-0.14355-0.37402c0-0.15625,0.04785-0.2832,0.14355-0.38281\n' +
    "\t\t\t\t\tc0.09619-0.09863,0.21289-0.14844,0.35107-0.14844s0.25488,0.0498,0.35107,0.14844\n" +
    "\t\t\t\t\tc0.09619,0.09961,0.14404,0.22656,0.14404,0.38281c0,0.15039-0.04785,0.27441-0.14404,0.37402\n" +
    '\t\t\t\t\tc-0.09619,0.09863-0.21289,0.14844-0.35107,0.14844S105.81543,201.68774,105.71924,201.58911z"/>\n' +
    '\t\t\t\t<path d="M111.76758,198.30786v3.32129h-0.69287h-3.06885h-0.17139v-4.99512h0.69336h1.72803\n' +
    "\t\t\t\t\tC111.26367,196.63403,111.76758,197.19263,111.76758,198.30786z M111.07471,201.08032v-2.79883\n" +
    '\t\t\t\t\tc0-0.39062-0.08398-0.6709-0.25195-0.8418s-0.4082-0.25684-0.72021-0.25684h-1.57471v3.89746H111.07471z"/>\n' +
    '\t\t\t\t<path d="M113.08154,197.68677l-0.11719-1.05273h0.68408l0.12598,1.05273v1.3418l-0.00879,0.56641l-0.68408,0.14453V197.68677z"\n' +
    "\t\t\t\t\t/>\n" +
    '\t\t\t\t<path d="M115.09766,197.68677l-0.11719-1.05273h0.68408l0.12598,1.05273v1.3418l-0.00879,0.56641l-0.68408,0.14453V197.68677z"\n' +
    "\t\t\t\t\t/>\n" +
    '\t\t\t\t<path d="M117.11377,197.68677l-0.11719-1.05273h0.68408l0.12598,1.05273v3.94238h-0.69287V197.68677z"/>\n' +
    '\t\t\t\t<path d="M122.64844,201.62915h-0.71143h-3.15869v-0.54883h2.83496l-2.65527-4.44629h0.72021l1.27783,2.15137l0.91797-2.15137\n' +
    '\t\t\t\t\th0.70215l-1.2417,2.78125L122.64844,201.62915z"/>\n' +
    '\t\t\t\t<path d="M126.01465,198.28149c0-0.39062-0.08398-0.6709-0.25195-0.8418s-0.4082-0.25684-0.72021-0.25684h-1.59277v-0.54883\n' +
    '\t\t\t\t\th1.74609c1.00781,0,1.51172,0.55859,1.51172,1.67383v3.32129h-0.69287V198.28149z"/>\n' +
    '\t\t\t\t<path d="M133.10645,201.62915l-2.04346-3.06934l-0.96289,3.06934h-0.66602l0.98096-3.0957l0.24316-0.59473l-0.82812-1.30469\n' +
    "\t\t\t\t\th0.71094l0.80127,1.25977l0.8457,1.25098l0.20703-0.74707l0.54004-1.76367h0.67529l-1.01709,3.12305l1.26904,1.87207H133.10645z\n" +
    '\t\t\t\t\t"/>\n' +
    '\t\t\t\t<path d="M138.04785,196.63403v0.58496l-1.7373,4.41016h-0.69336l1.7373-4.44629h-2.79004v-2.00684h0.63867v1.45801H138.04785z"\n' +
    "\t\t\t\t\t/>\n" +
    '\t\t\t\t<path d="M144.87012,198.30786v3.32129h-0.69336h-3.06934h-0.1709v-4.99512h0.69336h1.72754\n' +
    "\t\t\t\t\tC144.36523,196.63403,144.87012,197.19263,144.87012,198.30786z M144.17676,201.08032v-2.79883\n" +
    '\t\t\t\t\tc0-0.39062-0.08398-0.6709-0.25195-0.8418s-0.4082-0.25684-0.71973-0.25684h-1.5752v3.89746H144.17676z"/>\n' +
    '\t\t\t\t<path d="M146.18359,197.68677l-0.11719-1.05273H146.75l0.12598,1.05273v1.3418l-0.00879,0.56641l-0.68359,0.14453V197.68677z"/>\n' +
    '\t\t\t\t<path d="M147.92871,201.08032h1.56641v-2.99707c0-0.29395-0.05859-0.51172-0.17578-0.65234\n' +
    "\t\t\t\t\tc-0.11719-0.1416-0.29199-0.22949-0.52637-0.26562l-0.60254-0.08984l0.09863-0.54004l0.7832,0.11719\n" +
    '\t\t\t\t\tc0.35938,0.05957,0.63574,0.20801,0.82812,0.44531c0.19141,0.2373,0.28809,0.55957,0.28809,0.9668v3.56445h-2.25977V201.08032z"\n' +
    "\t\t\t\t\t/>\n" +
    '\t\t\t\t<path d="M155.19238,201.08032v0.54883h-1.33203h-2.5918v-0.54883h2.5918v-2.79883c0-0.39062-0.08398-0.6709-0.25195-0.8418\n' +
    '\t\t\t\t\ts-0.4082-0.25684-0.71973-0.25684h-1.62012v-0.54883h1.77344c1.00781,0,1.51172,0.55859,1.51172,1.67383v2.77246H155.19238z"/>\n' +
    '\t\t\t\t<path d="M159.41309,196.63403v0.58496l-1.7373,4.41016h-0.69238l1.73633-4.44629h-2.79004v-2.00684h0.63965v1.45801H159.41309z"\n' +
    "\t\t\t\t\t/>\n" +
    '\t\t\t\t<path d="M166.23535,198.30786v3.32129h-0.69336h-3.06934h-0.1709v-4.99512h0.69336h1.72754\n' +
    "\t\t\t\t\tC165.73047,196.63403,166.23535,197.19263,166.23535,198.30786z M165.54199,201.08032v-2.79883\n" +
    '\t\t\t\t\tc0-0.39062-0.08398-0.6709-0.25195-0.8418s-0.4082-0.25684-0.71973-0.25684h-1.5752v3.89746H165.54199z"/>\n' +
    '\t\t\t\t<path d="M167.5498,197.68677l-0.11719-1.05273h0.68359l0.12598,1.05273v1.3418l-0.00879,0.56641l-0.68359,0.14453V197.68677z"/>\n' +
    '\t\t\t\t<path d="M169.56543,197.68677l-0.11719-1.05273h0.68359l0.12598,1.05273v1.3418l-0.00879,0.56641l-0.68359,0.14453V197.68677z"\n' +
    "\t\t\t\t\t/>\n" +
    '\t\t\t\t<path d="M174.74902,196.63403v0.58496l-1.7373,4.41016h-0.69238l1.73633-4.44629h-2.79004v-2.00684h0.63965v1.45801H174.74902z"\n' +
    "\t\t\t\t\t/>\n" +
    '\t\t\t\t<path d="M175.86523,197.68677l-0.11719-1.05273h0.68359l0.12598,1.05273v3.94238h-0.69238V197.68677z"/>\n' +
    '\t\t\t\t<path d="M181.35059,200.76489c-0.68164,0.62402-1.71484,0.94238-3.10059,0.9541l-0.62988-5.08496h0.66602l0.30566,2.54688\n' +
    "\t\t\t\t\tc0.26953-0.14355,0.47949-0.29785,0.62988-0.46289s0.25977-0.36816,0.32812-0.60742\n" +
    "\t\t\t\t\tc0.06934-0.24023,0.10938-0.5498,0.12207-0.92773l0.00879-0.53027h0.66602l-0.00879,0.52148\n" +
    "\t\t\t\t\tc-0.0127,0.66016-0.14453,1.18359-0.39648,1.57031c-0.25195,0.3877-0.68066,0.71289-1.28711,0.97656l0.17188,1.38574\n" +
    "\t\t\t\t\tc0.95312-0.05957,1.66797-0.3457,2.1416-0.85938c0.47363-0.5127,0.70801-1.2793,0.70215-2.29883l-0.00879-1.31445h0.69238\n" +
    '\t\t\t\t\tl0.00879,1.28711C182.36816,199.1936,182.03125,200.14087,181.35059,200.76489z"/>\n' +
    '\t\t\t\t<path d="M187.29883,201.04419c-0.35645,0.45605-0.86816,0.68359-1.53418,0.68359c-0.22852,0-0.47363-0.02637-0.73828-0.08105\n' +
    "\t\t\t\t\tl0.07227-0.54883c0.19824,0.04883,0.40234,0.07227,0.6123,0.07227c0.49805,0,0.86523-0.17285,1.10254-0.51758\n" +
    "\t\t\t\t\tc0.23633-0.34473,0.35547-0.85938,0.35547-1.54297c0-1.30859-0.42969-1.96289-1.28711-1.96289\n" +
    "\t\t\t\t\tc-0.32422,0-0.58496,0.09375-0.7832,0.2793c-0.19824,0.18652-0.33301,0.42871-0.40527,0.72949l-0.63867,3.47363h-0.69336\n" +
    "\t\t\t\t\tl0.66602-3.50098l-0.69238-1.49414h0.71973l0.4502,1.08008c0.12012-0.37793,0.31152-0.66113,0.57617-0.85059\n" +
    "\t\t\t\t\tc0.26367-0.18848,0.56348-0.2832,0.89941-0.2832c0.57031,0,1.02148,0.19629,1.35449,0.58887\n" +
    '\t\t\t\t\tc0.33301,0.39355,0.5,1.03125,0.5,1.91309C187.83496,199.93384,187.65625,200.58813,187.29883,201.04419z"/>\n' +
    '\t\t\t\t<path d="M194.39551,196.63403c0,1.23633-0.0459,2.15723-0.13965,2.7627c-0.09277,0.60645-0.31152,1.08691-0.65625,1.44043\n' +
    "\t\t\t\t\tc-0.3457,0.35449-0.89941,0.60645-1.66113,0.75586l-1.51172,0.29688l-0.09863-0.55762l1.59277-0.30566l-1.16113-4.39258h0.69336\n" +
    "\t\t\t\t\tl1.09766,4.21191c0.37207-0.14941,0.63867-0.38184,0.80078-0.69727s0.26074-0.7168,0.29688-1.20605\n" +
    '\t\t\t\t\ts0.05762-1.25879,0.06348-2.30859H194.39551z"/>\n' +
    '\t\t\t\t<path d="M195.30469,201.08032h1.56641v-2.99707c0-0.29395-0.05859-0.51172-0.17578-0.65234\n' +
    "\t\t\t\t\tc-0.11719-0.1416-0.29199-0.22949-0.52637-0.26562l-0.60254-0.08984l0.09863-0.54004l0.7832,0.11719\n" +
    '\t\t\t\t\tc0.35938,0.05957,0.63574,0.20801,0.82812,0.44531c0.19141,0.2373,0.28809,0.55957,0.28809,0.9668v3.56445h-2.25977V201.08032z"\n' +
    "\t\t\t\t\t/>\n" +
    '\t\t\t\t<path d="M202.63477,201.04419c-0.35645,0.45605-0.86816,0.68359-1.53418,0.68359c-0.22852,0-0.47363-0.02637-0.73828-0.08105\n' +
    "\t\t\t\t\tl0.07227-0.54883c0.19824,0.04883,0.40234,0.07227,0.6123,0.07227c0.49805,0,0.86523-0.17285,1.10254-0.51758\n" +
    "\t\t\t\t\tc0.23633-0.34473,0.35547-0.85938,0.35547-1.54297c0-1.30859-0.42969-1.96289-1.28711-1.96289\n" +
    "\t\t\t\t\tc-0.32422,0-0.58496,0.09375-0.7832,0.2793c-0.19824,0.18652-0.33301,0.42871-0.40527,0.72949l-0.63867,3.47363h-0.69336\n" +
    "\t\t\t\t\tl0.66602-3.50098l-0.69238-1.49414h0.71973l0.4502,1.08008c0.12012-0.37793,0.31152-0.66113,0.57617-0.85059\n" +
    "\t\t\t\t\tc0.26367-0.18848,0.56348-0.2832,0.89941-0.2832c0.57031,0,1.02148,0.19629,1.35449,0.58887\n" +
    '\t\t\t\t\tc0.33301,0.39355,0.5,1.03125,0.5,1.91309C203.1709,199.93384,202.99219,200.58813,202.63477,201.04419z"/>\n' +
    '\t\t\t\t<path d="M204.16992,199.19946l0.09863-0.60352h0.59473v3.0332h-0.69336V199.19946z M207.25684,198.28149\n' +
    "\t\t\t\t\tc0-0.39062-0.08398-0.6709-0.25195-0.8418s-0.4082-0.25684-0.71973-0.25684h-2.10645v-0.54883h2.25879\n" +
    '\t\t\t\t\tc1.00879,0,1.5127,0.55859,1.5127,1.67383v3.32129h-0.69336V198.28149z"/>\n' +
    '\t\t\t\t<path d="M212.41406,196.63403v0.58496l-1.7373,4.41016h-0.69238l1.73633-4.44629h-2.79004v-2.00684h0.63965v1.45801H212.41406z"\n' +
    "\t\t\t\t\t/>\n" +
    '\t\t\t\t<path d="M215.33008,197.68677l-0.11719-1.05273h0.68359l0.12598,1.05273v1.3418l-0.00879,0.56641l-0.68359,0.14453V197.68677z"\n' +
    "\t\t\t\t\t/>\n" +
    '\t\t\t\t<path d="M219.39746,198.23657c0-0.46191,0.08105-0.81348,0.24316-1.05371h-2.52051v-0.54883h3.85254v0.54883h-0.66602\n' +
    '\t\t\t\t\tc-0.14453,0.24609-0.21582,0.59766-0.21582,1.05371v3.39258h-0.69336V198.23657z"/>\n' +
    '\t\t\t\t<path d="M221.87305,201.08032h1.1875c0.48633,0,0.85254-0.14062,1.09863-0.42285\n' +
    "\t\t\t\t\tc0.24512-0.28223,0.36816-0.69629,0.36816-1.24219v-0.56738c0-0.5459-0.12305-0.95996-0.36816-1.24219\n" +
    "\t\t\t\t\tc-0.24609-0.28125-0.6123-0.42285-1.09863-0.42285h-1.1875v-0.54883h1.32227c0.64844,0,1.14844,0.19238,1.49902,0.57617\n" +
    "\t\t\t\t\ts0.52637,0.93945,0.52637,1.66504v0.5127c0,0.72656-0.17578,1.28125-0.52637,1.66504s-0.85059,0.57617-1.49902,0.57617h-1.32227\n" +
    '\t\t\t\t\tV201.08032z"/>\n' +
    '\t\t\t\t<path d="M229.05957,200.90063c0.10449,0.14355,0.15723,0.3418,0.15723,0.59375c0,0.36523-0.10059,0.69141-0.30176,0.97656\n' +
    "\t\t\t\t\tc-0.20117,0.28418-0.47266,0.50195-0.81445,0.65234l-0.1709-0.39648c0.24609-0.10742,0.43652-0.25488,0.57129-0.44043\n" +
    "\t\t\t\t\tc0.13574-0.18652,0.20605-0.40234,0.21191-0.64844c-0.01758,0.00586-0.04785,0.00879-0.08984,0.00879\n" +
    "\t\t\t\t\tc-0.14453,0-0.26465-0.04297-0.36035-0.12988s-0.14355-0.20605-0.14355-0.35547c0-0.14453,0.05078-0.25977,0.15234-0.34668\n" +
    '\t\t\t\t\tc0.10254-0.08691,0.22266-0.13086,0.36035-0.13086C228.81152,200.68384,228.9541,200.7561,229.05957,200.90063z"/>\n' +
    '\t\t\t\t<path d="M230.22461,199.19946l0.09863-0.60352h0.59473v3.0332h-0.69336V199.19946z M233.31152,198.28149\n' +
    "\t\t\t\t\tc0-0.39062-0.08398-0.6709-0.25195-0.8418s-0.4082-0.25684-0.71973-0.25684h-2.10645v-0.54883h2.25879\n" +
    '\t\t\t\t\tc1.00879,0,1.5127,0.55859,1.5127,1.67383v3.32129h-0.69336V198.28149z"/>\n' +
    '\t\t\t\t<path d="M238.81934,201.62915h-0.71094h-3.15918v-0.54883h2.83496l-2.6543-4.44629h0.71973l1.27832,2.15137l0.91797-2.15137\n' +
    '\t\t\t\t\th0.70215l-1.24219,2.78125L238.81934,201.62915z"/>\n' +
    '\t\t\t\t<path d="M241.23145,201.08032c0.31738-0.03613,0.58496-0.11133,0.80078-0.22559\n' +
    "\t\t\t\t\tc0.21582-0.11328,0.39551-0.31152,0.54004-0.59375c0.14355-0.28223,0.2334-0.67773,0.26953-1.1875l0.15332-1.89062h-3.24023\n" +
    "\t\t\t\t\tv-0.54883h3.97852l-0.02734,0.49512l-0.14453,1.80859c-0.04199,0.87012-0.26172,1.53027-0.66113,1.98047\n" +
    "\t\t\t\t\ts-0.93164,0.6875-1.59766,0.71094L241.23145,201.08032z M239.77344,199.07349l0.09863-0.6123h0.59375v4.96777h-0.69238\n" +
    '\t\t\t\t\tV199.07349z"/>\n' +
    '\t\t\t\t<path d="M244.73242,199.19946l0.09863-0.60352h0.59473v3.0332h-0.69336V199.19946z M247.81934,198.28149\n' +
    "\t\t\t\t\tc0-0.39062-0.08398-0.6709-0.25195-0.8418s-0.4082-0.25684-0.71973-0.25684h-2.10645v-0.54883H247\n" +
    '\t\t\t\t\tc1.00879,0,1.5127,0.55859,1.5127,1.67383v3.32129h-0.69336V198.28149z"/>\n' +
    "\t\t\t</g>\n" +
    "\t\t</g>\n" +
    "\t</g>\n" +
    "</g>\n" +
    '<g id="Isolation_Mode_2_">\n' +
    '\t<g class="st8">\n' +
    '\t\t<path class="st7" d="M32.43663,9.38637c0.1546,0.17075,0.23233,0.42814,0.23233,0.77218v1.90539h-0.55854v-1.93173\n' +
    "\t\t\tc0-0.38227-0.15674-0.5734-0.46977-0.5734h-0.80361v2.50513h-0.55854V9.35494V9.13068h1.50316\n" +
    '\t\t\tC32.06328,9.13068,32.2816,9.21562,32.43663,9.38637z"/>\n' +
    '\t\t<path class="st7" d="M33.35238,9.74146l-0.06244-0.61078h0.55301l0.06796,0.61078v2.32249h-0.55854\n' +
    '\t\t\tC33.35237,12.06395,33.35237,9.74146,33.35238,9.74146z"/>\n' +
    '\t\t<path class="st7" d="M34.47454,9.13068h0.55853l0.2816,2.4788c0.80404-0.07985,1.19353-0.5012,1.16932-1.26318v-0.14611\n' +
    "\t\t\tc0-0.18774-0.05054-0.34234-0.15163-0.46382c-0.10067-0.12233-0.25018-0.19028-0.44853-0.20388l-0.26122-0.01614l0.04205-0.43833\n" +
    "\t\t\tl0.30794,0.01614c0.31303,0.02039,0.56363,0.11723,0.75137,0.28967s0.28882,0.42219,0.30284,0.7484l0.0051,0.14696\n" +
    '\t\t\tc0.02081,0.60483-0.16608,1.05506-0.56108,1.34898c-0.39501,0.29392-0.94378,0.45617-1.64673,0.4876L34.47454,9.13068z"/>\n' +
    '\t\t<path class="st7" d="M39.81908,11.63581v0.42814h-0.91872h-1.45092v-0.42814h1.45092V10.0804\n' +
    "\t\t\tc0-0.18434-0.03993-0.31771-0.1202-0.39926c-0.07985-0.08155-0.19666-0.12232-0.34956-0.12232h-0.98116V9.13068h1.12217\n" +
    '\t\t\tc0.27821,0,0.49567,0.07815,0.6524,0.23446c0.15673,0.15715,0.23488,0.40435,0.23488,0.7416v1.52907H39.81908z"/>\n' +
    '\t\t<path class="st7" d="M42.66315,10.0804c0-0.18434-0.03992-0.31771-0.11978-0.39926\n' +
    "\t\t\tc-0.0807-0.08155-0.19708-0.12232-0.34999-0.12232h-0.96586V9.13068h1.10688c0.27778,0,0.49525,0.07815,0.6524,0.23446\n" +
    '\t\t\tc0.15631,0.15715,0.23446,0.40435,0.23446,0.7416v1.95721h-0.55811V10.0804z"/>\n' +
    '\t\t<path class="st7" d="M43.91529,9.74146l-0.06201-0.61078h0.55302l0.06796,0.61078v2.32249h-0.55896V9.74146z"/>\n' +
    '\t\t<path class="st7" d="M45.40784,10.16875c0-0.27099,0.04927-0.47486,0.14611-0.60993H45.0536V9.13068h1.41949v0.42814h-0.4069\n' +
    '\t\t\tc-0.04248,0.07985-0.06881,0.16905-0.08155,0.26844c-0.01189,0.09939-0.01784,0.21322-0.01784,0.34149v1.8952h-0.55896V10.16875z"\n' +
    "\t\t\t/>\n" +
    '\t\t<path class="st7" d="M49.00965,12.06395l-1.09584-1.66499l-0.51734,1.66499H46.8647l0.5734-1.79581l0.14611-0.37037\n' +
    "\t\t\tl-0.47996-0.76709h0.56831L48.1109,9.8349l0.44003,0.6575l0.12912-0.45362l0.26674-0.9081h0.53687l-0.60483,1.84763\n" +
    '\t\t\tl0.72546,1.08564H49.00965z"/>\n' +
    "\t</g>\n" +
    "</g>\n" +
    "</svg>\n" +
    "</div>";

export const pen = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 396.9 56.2" style="enable-background:new 0 0 396.9 56.2;position:absolute;height: 118px;left: -229px;top: -27px;" xml:space="preserve">
<style type="text/css">
\t.st0{fill:none;stroke:#676767;stroke-width:0.25;stroke-miterlimit:10;}
\t.st1{fill:#FFFFFF;stroke:#676767;stroke-width:0.25;stroke-miterlimit:10;}
\t.st2{opacity:0.7;}
\t.st3{fill:none;stroke:#F2F2F2;stroke-width:0.25;stroke-miterlimit:10;}
\t.st4{fill:none;stroke:#6E6F71;stroke-width:0.25;stroke-miterlimit:10;stroke-dasharray:3;}
</style>
<g>
\t<path class="st0" d="M372,17.2c-2.4-3-4.9-4.1-4.9-4.1c-0.3-0.1-0.5-0.3-0.8-0.4h-26.6h-11.2h-265"></path>
\t<path class="st0" d="M63.4,41.5h302.4c1.5,0,2.9-0.4,4.1-1.2c1.2-0.8,1.9-1.4,2.7-2.4"></path>
\t<path class="st0" d="M295.3,16.3c-0.9-2.5-1.8-3.5-1.8-3.5"></path>
\t<path class="st0" d="M293,41.5c1-1.3,2-2.2,2.5-3.7"></path>
\t<path class="st0" d="M363.2,37.9c-4.3,0-7.8-4.6-7.8-10.3s3.5-10.3,7.8-10.3"></path>
\t<path class="st0" d="M367.5,37.9c-1.4,1.1-2.8,1.5-4.5,1.5c-5,0-9.1-5.3-9.1-11.9s4.1-11.9,9.1-11.9c1.8,0,3.1,0.4,4.6,1.6"></path>
\t<path class="st0" d="M367.7,17.2h19.8c0,0,10,9.1,1,20.7h-25.3"></path>
\t<path class="st0" d="M363.2,17.3l4.5-0.1"></path>
\t<path class="st1" d="M353.1,41.5"></path>
\t<g>
\t\t<path class="st0" d="M349.9,12.8c0,0-16.7,5.5-88.9,2.8c-2.2-0.1-4.1,1.9-4.1,4.1v14.9c0,2.2,1.8,4.2,4.1,4.1
\t\t\tc72.3-3,88.9,2.8,88.9,2.8"></path>
\t</g>
\t<path class="st0" d="M63.5,12.8l-57.1,9l0.3,11.9c11,4.6,56.2,7.9,56.2,7.9h0.5"></path>
\t<path class="st0" d="M6.7,33.7c-1.2,0.1-2-2.7-2.1-6s0.9-5.8,1.8-5.9c1-0.2,1.9,2.6,1.9,5.9C8.4,30.9,7.7,33.6,6.7,33.7z"></path>
\t
\t\t<ellipse transform="matrix(0.9999 -1.647448e-02 1.647448e-02 0.9999 -0.4604 0.1109)" class="st0" cx="6.5" cy="28" rx="1" ry="3.1"></ellipse>
\t<g>
\t\t<g class="st2">
\t\t\t<g>
\t\t\t\t
\t\t\t\t\t<rect x="109.1" y="12.9" transform="matrix(1 -3.270440e-03 3.270440e-03 1 -8.770029e-02 0.5656)" class="st3" width="127.6" height="28.4"></rect>
\t\t\t</g>
\t\t</g>
\t\t<g>
\t\t
\t\t\t<g>
\t\t\t\t
\t\t\t\t\t<rect x="109.1" y="12.9" transform="matrix(1 -3.270440e-03 3.270440e-03 1 -8.770029e-02 0.5656)" class="st4" width="127.6" height="28.4"></rect>
\t\t\t</g>
\t\t</g>
\t</g>
</g>
</svg>`;

export const a4 = `
<div class="helperItem" style="position: absolute; z-index: 2; width:100%">

<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 26.2.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd" [
	<!ENTITY ns_extend "http://ns.adobe.com/Extensibility/1.0/">
	<!ENTITY ns_ai "http://ns.adobe.com/AdobeIllustrator/10.0/">
	<!ENTITY ns_graphs "http://ns.adobe.com/Graphs/1.0/">
	<!ENTITY ns_vars "http://ns.adobe.com/Variables/1.0/">
	<!ENTITY ns_imrep "http://ns.adobe.com/ImageReplacement/1.0/">
	<!ENTITY ns_sfw "http://ns.adobe.com/SaveForWeb/1.0/">
	<!ENTITY ns_custom "http://ns.adobe.com/GenericCustomNamespace/1.0/">
	<!ENTITY ns_adobe_xpath "http://ns.adobe.com/XPath/1.0/">

<svg version="1.1" id="Layer_1" xmlns:x="&ns_extend;" xmlns:i="&ns_ai;" xmlns:graph="&ns_graphs;"
	 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 841.9 648"
	 style="enable-background:new 0 0 841.9 648;" xml:space="preserve">
<style type="text/css">
	.st0{fill:none;stroke:#FFFFFF;stroke-width:2.8346;stroke-miterlimit:10;}
	
		.st1{opacity:0.7;fill:none;stroke:#6E6F71;stroke-width:2.8346;stroke-miterlimit:10;stroke-dasharray:14.1732;enable-background:new    ;}
	.st2{fill:none;}
	.st3{opacity:0.3;fill:#518EED;enable-background:new    ;}
	.st4{fill:#FFFFFF;}
	.st5{font-family:'Assistant-Regular';}
	.st6{font-size:17.0079px;}
</style>
<metadata>
	<sfw  xmlns="&ns_sfw;">
		<slices></slices>
		<sliceSourceBounds  bottomLeftOrigin="true" height="644.7" width="844.7" x="-1.4" y="32.7"></sliceSourceBounds>
	</sfw>
</metadata>
<g>
	<g>
		<rect y="0" class="st0" width="841.9" height="595.3"/>
		<rect y="0" class="st1" width="841.9" height="595.3"/>
	</g>
	<rect x="13.5" y="14.4" class="st2" width="244.3" height="39.8"/>
	<rect y="0" class="st3" width="38.4" height="38.4"/>
	<text transform="matrix(1 0 0 1 10.3631 25.2383)" class="st4 st5 st6">A4</text>
</g>
<g>
	<path d="M315.2,619.1c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.6,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3s0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.7
		c0,0.3-0.1,0.5-0.3,0.7s-0.4,0.3-0.7,0.3S315.4,619.3,315.2,619.1z"/>
	<path d="M323.1,612.8c0-0.9,0.1-1.5,0.5-2h-4.8v-1h7.3v1h-1.3c-0.3,0.5-0.4,1.1-0.4,2v6.5h-1.3L323.1,612.8L323.1,612.8z"/>
	<path d="M335.4,618.2v1h-2.5H328v-1h4.9v-5.3c0-0.8-0.1-1.3-0.5-1.6c-0.3-0.3-0.8-0.5-1.4-0.5H328v-1h3.3c1.9,0,2.9,1,2.9,3.2v5.3
		L335.4,618.2L335.4,618.2z"/>
	<path d="M343.4,609.7v1.1l-3.3,8.4h-1.3l3.3-8.4h-5.3v-3.8h1.2v2.8H343.4z"/>
	<path d="M352.6,618.2v1H350h-4.9v-1h4.9v-5.3c0-0.8-0.1-1.3-0.5-1.6c-0.3-0.3-0.8-0.5-1.4-0.5h-3.1v-1h3.3c1.9,0,2.9,1,2.9,3.2v5.3
		L352.6,618.2L352.6,618.2z"/>
	<path d="M358,611.7l-0.2-2h1.3l0.2,2v2.6v1l-1.3,0.3V611.7z"/>
	<path d="M366.1,612.9c0-0.8-0.1-1.3-0.5-1.6c-0.3-0.3-0.8-0.5-1.4-0.5h-3v-1h3.3c1.9,0,2.9,1,2.9,3.2v6.3h-1.3V612.9L366.1,612.9z"
		/>
	<path d="M369.9,611.7l-0.2-2h1.3l0.2,2v7.5h-1.3V611.7z"/>
	<path d="M373.2,609.7h1.3l1,8.4c1.3-0.1,2.4-0.6,3.2-1.3c0.8-0.7,1.2-1.8,1.1-3.3v-0.4c0-0.7-0.2-1.3-0.6-1.7
		c-0.3-0.4-0.9-0.7-1.7-0.7l-0.8-0.1l0.1-1l1,0.1c1,0.1,1.8,0.3,2.4,0.9c0.6,0.6,0.9,1.3,0.9,2.4v0.5c0.1,2-0.5,3.4-1.7,4.4
		c-1.3,1-2.9,1.5-5,1.6L373.2,609.7z"/>
	<path d="M385.6,618.2c0.6-0.1,1.1-0.2,1.5-0.4c0.4-0.2,0.8-0.6,1-1.1c0.3-0.6,0.4-1.3,0.5-2.2l0.3-3.6h-6.1v-1h7.5l-0.1,0.9
		L390,614c-0.1,1.7-0.5,2.9-1.3,3.8c-0.8,0.8-1.7,1.3-3.1,1.3L385.6,618.2z M382.9,614.4l0.2-1.2h1.1v9.4h-1.3V614.4L382.9,614.4z"
		/>
	<path d="M392.5,611.7l-0.2-2h1.3l0.2,2v7.5h-1.3V611.7z"/>
	<path d="M400.1,608h3.1c1.3,0,2.4,0.3,3.1,0.8c0.7,0.5,1.1,1.3,1.1,2.5c0,1.1-0.3,2-1.1,2.6c-0.7,0.6-1.7,0.8-3,0.8h-1.9v4.5h-1.3
		L400.1,608L400.1,608z M403.1,613.6c1,0,1.8-0.2,2.3-0.6c0.5-0.3,0.8-1,0.8-1.8c0-0.8-0.3-1.4-0.8-1.7c-0.5-0.3-1.3-0.5-2.4-0.5
		h-1.7v4.6H403.1z"/>
	<path d="M409.7,608h2.8c1.7,0,3,0.5,3.9,1.5c0.9,1,1.3,2.4,1.3,4.1c0,1.7-0.4,3.1-1.3,4.2c-0.9,1-2.2,1.5-3.8,1.5h-2.8L409.7,608
		L409.7,608z M412.3,618.2c1.3,0,2.4-0.4,3.1-1.2c0.7-0.8,1-2,1-3.4s-0.3-2.6-1-3.3c-0.7-0.8-1.7-1.2-3.1-1.2h-1.4v9.1H412.3
		L412.3,618.2z"/>
	<path d="M420.1,608h6.4v1h-5.2v4h4.3v1h-4.3v5.2h-1.3V608L420.1,608z"/>
	<path d="M435.9,612.9c0-0.8-0.1-1.3-0.5-1.6c-0.3-0.3-0.8-0.5-1.4-0.5h-3v-1h3.3c1.9,0,2.9,1,2.9,3.2v6.3h-1.3V612.9L435.9,612.9z"
		/>
	<path d="M438.8,618.5c0.7-0.1,1.1-0.3,1.4-0.6c0.3-0.3,0.4-0.8,0.4-1.5v-6.8h4.2c1.9,0,2.9,1,2.9,3.2v6.3h-1.3v-6.4
		c0-0.8-0.1-1.3-0.5-1.6c-0.3-0.3-0.8-0.5-1.4-0.5h-2.6v5.6c0,1-0.2,1.7-0.7,2.2c-0.5,0.5-1.3,0.8-2.2,0.9L438.8,618.5z"/>
	<path d="M456.4,619.2l-3.9-5.9l-1.8,5.9h-1.3l1.9-5.9l0.5-1.1l-1.6-2.5h1.3l1.5,2.4l1.6,2.4l0.4-1.4l1-3.3h1.3l-2,5.9l2.4,3.6
		L456.4,619.2L456.4,619.2z"/>
	<path d="M465.7,609.7v1.1l-3.3,8.4h-1.3l3.3-8.4h-5.3v-3.8h1.2v2.8H465.7z"/>
	<path d="M470.3,618.5c0.7-0.1,1.1-0.3,1.4-0.6c0.3-0.3,0.4-0.8,0.4-1.5v-6.8h4.2c1.9,0,2.9,1,2.9,3.2v6.3h-1.3v-6.4
		c0-0.8-0.1-1.3-0.5-1.6s-0.8-0.5-1.4-0.5h-2.6v5.6c0,1-0.2,1.7-0.7,2.2s-1.3,0.8-2.2,0.9L470.3,618.5z"/>
	<path d="M481.6,611.7l-0.2-2h1.3l0.2,2v7.5h-1.3V611.7z"/>
	<path d="M491.5,609.7v1.1l-3.3,8.4h-1.3l3.3-8.4h-5.3v-3.8h1.2v2.8H491.5z"/>
	<path d="M500.4,609.7c0,2.4-0.1,4.1-0.3,5.2c-0.2,1.2-0.6,2.1-1.3,2.7c-0.6,0.7-1.7,1.2-3.1,1.5l-2.9,0.6l-0.2-1l3-0.6l-2.2-8.4
		h1.3l2.1,8c0.7-0.3,1.2-0.7,1.5-1.3c0.3-0.6,0.5-1.4,0.6-2.3c0.1-0.9,0.1-2.4,0.1-4.4H500.4z"/>
	<path d="M508.7,609.7v1.1l-3.3,8.4h-1.3l3.3-8.4h-5.3v-3.8h1.2v2.8H508.7z"/>
	<path d="M520.8,617.6c-1.3,1.2-3.3,1.8-5.9,1.8l-1.2-9.7h1.3l0.6,4.8c0.5-0.3,0.9-0.6,1.2-0.9c0.3-0.3,0.5-0.7,0.6-1.2
		s0.2-1,0.2-1.7v-1h1.3v1c0,1.3-0.3,2.2-0.8,3c-0.5,0.8-1.3,1.3-2.4,1.9l0.3,2.6c1.8-0.1,3.1-0.6,4-1.6s1.3-2.4,1.3-4.4v-2.5h1.3
		v2.4C522.8,614.6,522.1,616.4,520.8,617.6z"/>
	<path d="M525,611.7l-0.2-2h1.3l0.2,2v2.6v1l-1.3,0.3V611.7z"/>
	<path d="M193.9,632.2l-0.2-2h1.3l0.2,2v2.6v1l-1.3,0.3V632.2z"/>
	<path d="M197.2,638.7h3V633c0-0.6-0.1-1-0.3-1.3c-0.2-0.3-0.6-0.4-1-0.5l-1.1-0.1l0.2-1l1.5,0.2c0.7,0.1,1.2,0.4,1.6,0.8
		c0.3,0.4,0.6,1,0.6,1.8v6.8h-4.3L197.2,638.7L197.2,638.7z"/>
	<path d="M208.3,633.4c0-0.8-0.1-1.3-0.5-1.6c-0.3-0.3-0.8-0.5-1.4-0.5h-3v-1h3.3c1.9,0,2.9,1,2.9,3.2v6.3h-1.3V633.4z"/>
	<path d="M212.2,632.2l-0.2-2h1.3l0.2,2v7.5h-1.3V632.2z"/>
	<path d="M222.6,639.8h-1.3h-6v-1h5.4l-5-8.4h1.4l2.4,4.1l1.7-4.1h1.3l-2.4,5.3L222.6,639.8z"/>
	<path d="M233,633.4c0-0.8-0.1-1.3-0.5-1.6c-0.3-0.3-0.8-0.5-1.4-0.5h-3.3v-1h3.6c1.9,0,2.9,1,2.9,3.2v9.8h-1.3L233,633.4L233,633.4
		z"/>
	<path d="M236.8,632.2l-0.2-2h1.3l0.2,2v7.5h-1.3V632.2z"/>
	<path d="M239.7,639.1c0.7-0.1,1.1-0.3,1.4-0.6c0.3-0.3,0.4-0.8,0.4-1.5v-6.8h4.2c1.9,0,2.9,1,2.9,3.2v6.3h-1.3v-6.3
		c0-0.8-0.1-1.3-0.5-1.6c-0.3-0.3-0.8-0.5-1.4-0.5h-2.6v5.6c0,1-0.2,1.7-0.7,2.2c-0.5,0.5-1.3,0.8-2.2,0.9L239.7,639.1z"/>
	<path d="M251,632.2l-0.2-2h1.3l0.2,2v2.6v1l-1.3,0.3V632.2z"/>
	<path d="M261.2,631.1c0.5,0.6,0.8,1.4,0.8,2.6v6.1h-1.3v-6.1c0-0.8-0.1-1.3-0.5-1.7c-0.3-0.4-0.8-0.6-1.4-0.6h-2.9v8.4h-1.3v-9.1
		v-0.4h4.5C260,630.3,260.7,630.6,261.2,631.1z"/>
	<path d="M271.4,638.7v1h-2.5H264v-1h4.9v-5.3c0-0.8-0.1-1.3-0.5-1.6c-0.3-0.3-0.8-0.5-1.4-0.5H264v-1h3.3c1.9,0,2.9,1,2.9,3.2v5.3
		L271.4,638.7L271.4,638.7z"/>
	<path d="M283.1,639.8l-3.9-5.9l-1.8,5.9h-1.3l1.9-5.9l0.5-1.1l-1.6-2.5h1.3l1.5,2.4l1.6,2.4l0.4-1.4l1-3.3h1.3l-2,5.9l2.4,3.6
		L283.1,639.8L283.1,639.8z"/>
	<path d="M292.4,630.3v1.1l-3.3,8.4h-1.3l3.3-8.4h-5.3v-3.8h1.2v2.8H292.4z"/>
	<path d="M294.6,632.2l-0.2-2h1.3l0.2,2v7.5h-1.3V632.2z"/>
	<path d="M307.7,631.3c0.6,0.7,0.9,1.7,0.9,3.1v8.6h-1.3v-8.6c0-2.1-0.8-3.1-2.4-3.1h-1.8l-0.3,1.6c-0.1,0.3-0.1,0.7-0.1,1
		c0,0.4,0.1,0.7,0.2,0.9c0.1,0.2,0.4,0.4,0.7,0.5l-0.3,0.8c-0.6-0.1-1.1-0.3-1.4-0.7c-0.3-0.3-0.4-0.8-0.4-1.5
		c0-0.3,0.1-0.8,0.1-1.1l0.6-2.6h3.1C306.4,630.3,307.2,630.6,307.7,631.3z"/>
	<path d="M311.2,632.2l-0.2-2h1.3l0.2,2v7.5h-1.3V632.2z"/>
	<path d="M317.4,638.7c0.6-0.1,1.1-0.2,1.5-0.4c0.4-0.2,0.8-0.6,1-1.1c0.3-0.6,0.4-1.3,0.5-2.2l0.3-3.6h-6.1v-1h7.5l-0.1,0.9
		l-0.3,3.4c-0.1,1.7-0.5,2.9-1.3,3.8c-0.8,0.8-1.7,1.3-3.1,1.3L317.4,638.7z M314.6,634.9l0.2-1.2h1.1v9.4h-1.3V634.9z"/>
	<path d="M331,638.1c-1.3,1.2-3.3,1.8-5.9,1.8l-1.2-9.7h1.3l0.6,4.8c0.5-0.3,0.9-0.6,1.2-0.9c0.3-0.3,0.5-0.7,0.6-1.2
		c0.1-0.5,0.2-1,0.2-1.7v-1h1.3v1c0,1.3-0.3,2.2-0.8,3c-0.5,0.8-1.3,1.3-2.4,1.9l0.3,2.6c1.8-0.1,3.1-0.6,4-1.6
		c0.9-1,1.3-2.4,1.3-4.4v-2.5h1.3v2.4C332.9,635.1,332.3,636.9,331,638.1z"/>
	<path d="M345.4,630.3c0,2.4-0.1,4.1-0.3,5.2c-0.2,1.2-0.6,2.1-1.3,2.7c-0.6,0.7-1.7,1.2-3.1,1.5l-2.9,0.6l-0.2-1l3-0.6l-2.2-8.4
		h1.3l2.1,8c0.7-0.3,1.2-0.7,1.5-1.3c0.3-0.6,0.5-1.4,0.6-2.3c0.1-0.9,0.1-2.4,0.1-4.4H345.4z"/>
	<path d="M350,638.7c0.6-0.1,1.1-0.2,1.5-0.4c0.4-0.2,0.8-0.6,1-1.1c0.3-0.6,0.4-1.3,0.5-2.2l0.3-3.6h-6.1v-1h7.5l-0.1,0.9l-0.3,3.4
		c-0.1,1.7-0.5,2.9-1.3,3.8c-0.8,0.8-1.7,1.3-3.1,1.3L350,638.7z M347.3,634.9l0.2-1.2h1.1v9.4h-1.3V634.9z"/>
	<path d="M361.3,633.4c0-0.8-0.1-1.3-0.5-1.6c-0.3-0.3-0.8-0.5-1.4-0.5h-3v-1h3.3c1.9,0,2.9,1,2.9,3.2v6.3h-1.3V633.4z"/>
	<path d="M376,633.4v6.3h-1.3h-5.9h-0.3v-9.5h1.3h3.3C375,630.3,376,631.3,376,633.4z M374.6,638.7v-5.3c0-0.8-0.1-1.3-0.5-1.6
		c-0.3-0.3-0.8-0.5-1.4-0.5h-2.9v7.4H374.6z"/>
	<path d="M385.3,630.3c0,2.4-0.1,4.1-0.3,5.2c-0.2,1.2-0.6,2.1-1.3,2.7c-0.6,0.7-1.7,1.2-3.1,1.5l-2.9,0.6l-0.2-1l3-0.6l-2.2-8.4
		h1.3l2.1,8c0.7-0.3,1.2-0.7,1.5-1.3c0.3-0.6,0.5-1.4,0.6-2.3c0.1-0.9,0.1-2.4,0.1-4.4H385.3z"/>
	<path d="M391.6,639.9l-1-8.6l-0.1-1h4.7c1,0,1.8,0.3,2.4,0.9c0.6,0.6,0.8,1.5,0.9,2.5v0.6C398.5,637.8,396.2,639.7,391.6,639.9z
		 M396,637.5c0.8-0.6,1.1-1.7,1-3.3v-0.4c0-0.8-0.2-1.3-0.6-1.8c-0.3-0.5-0.8-0.7-1.5-0.7h-3.1l0.9,7.4
		C394.1,638.6,395.2,638.2,396,637.5z"/>
	<path d="M400.6,638.7h2.8c0.9,0,1.6-0.3,2.2-0.8c0.6-0.5,0.8-1.3,0.8-2.4v-1c0-1-0.2-1.8-0.6-2.4c-0.4-0.6-1-0.8-1.8-0.8H402
		l-0.4,1.9c-0.1,0.3-0.1,0.6-0.1,0.8c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.6,0.3,1,0.5l-0.3,0.9c-0.8-0.1-1.4-0.3-1.7-0.7
		c-0.3-0.3-0.6-0.9-0.6-1.5c0-0.3,0-0.6,0.1-1l0.6-2.9h3c1.1,0,2,0.3,2.6,1.1c0.6,0.7,0.9,1.8,0.9,3.1v1c0,1.5-0.3,2.6-1.1,3.3
		c-0.8,0.7-1.7,1-2.9,1h-3L400.6,638.7L400.6,638.7z"/>
	<path d="M413.7,633.3c0-0.9,0.1-1.5,0.5-2h-4.8v-1h7.3v1h-1.3c-0.3,0.5-0.4,1.1-0.4,2v6.5h-1.3L413.7,633.3L413.7,633.3z"/>
	<path d="M419,632.2l-0.2-2h1.3l0.2,2v7.5H419V632.2z"/>
	<path d="M422.9,632.2l-0.2-2h1.3l0.2,2v2.6v1l-1.3,0.3V632.2z"/>
	<path d="M437.2,638.6c-0.7,0.8-1.7,1.3-2.9,1.3c-0.4,0-0.9-0.1-1.4-0.1l0.1-1c0.3,0.1,0.8,0.1,1.2,0.1c1,0,1.7-0.3,2.1-1
		c0.4-0.6,0.7-1.6,0.7-2.9c0-2.5-0.8-3.7-2.4-3.7c-0.6,0-1.1,0.2-1.5,0.6c-0.3,0.3-0.6,0.8-0.8,1.4l-1.2,6.6h-1.4l1.3-6.6l-1.3-2.9
		h1.4l0.8,2c0.2-0.7,0.6-1.3,1.1-1.6c0.5-0.3,1-0.6,1.7-0.6c1.1,0,2,0.3,2.6,1.1c0.6,0.8,1,2,1,3.6
		C438.2,636.6,437.9,637.8,437.2,638.6z"/>
	<path d="M441.7,630.7c0.1,0.1,0.3,0.3,0.3,0.6c0,0.3-0.1,0.5-0.2,0.6c-0.1,0.1-0.3,0.2-0.6,0.2s-0.6-0.1-0.7-0.3
		c-0.1-0.2-0.3-0.6-0.3-1c0-1.3,0.6-2.2,1.7-2.9l0.3,0.6c-0.4,0.3-0.8,0.6-1,0.9c-0.2,0.3-0.3,0.7-0.3,1.2c0,0,0.1,0,0.1,0
		C441.3,630.5,441.5,630.6,441.7,630.7z M444.5,630.7c0.1,0.1,0.3,0.3,0.3,0.6c0,0.3-0.1,0.5-0.2,0.6c-0.1,0.1-0.3,0.2-0.6,0.2
		c-0.3,0-0.6-0.1-0.7-0.3c-0.2-0.3-0.3-0.6-0.3-1c0-1.3,0.6-2.2,1.7-2.9l0.3,0.6c-0.4,0.3-0.8,0.6-1,0.9c-0.2,0.3-0.3,0.7-0.3,1.2
		c0,0,0.1,0,0.1,0C444.2,630.5,444.4,630.6,444.5,630.7z"/>
	<path d="M454.6,638.6c-0.7,0.8-1.7,1.3-2.9,1.3c-0.4,0-0.9-0.1-1.4-0.1l0.1-1c0.3,0.1,0.8,0.1,1.2,0.1c1,0,1.7-0.3,2.1-1
		c0.4-0.6,0.7-1.6,0.7-2.9c0-2.5-0.8-3.7-2.4-3.7c-0.6,0-1.1,0.2-1.5,0.6c-0.3,0.3-0.6,0.8-0.8,1.4l-1.2,6.6h-1.3l1.3-6.6l-1.3-2.9
		h1.4l0.8,2c0.2-0.7,0.6-1.3,1.1-1.6c0.5-0.3,1-0.6,1.7-0.6c1.1,0,2,0.3,2.6,1.1c0.6,0.8,1,2,1,3.6
		C455.6,636.6,455.2,637.8,454.6,638.6z"/>
	<path d="M460.7,638.7h2.5v-8.3h-2v-0.8c0.9-0.1,1.7-0.4,2.3-0.8h1v9.9h2.3v1h-6.1L460.7,638.7L460.7,638.7z"/>
	<path d="M469.5,639.5c-0.6-0.3-1-0.6-1.5-1l0.6-0.8c0.4,0.4,0.8,0.7,1.3,0.9c0.4,0.2,1,0.3,1.5,0.3c0.5,0,0.9-0.1,1.3-0.3
		c0.3-0.2,0.7-0.5,0.9-0.9s0.3-0.8,0.3-1.4c0-0.8-0.2-1.4-0.6-1.8c-0.4-0.4-1-0.7-1.7-0.7c-0.3,0-0.7,0.1-1,0.1s-0.6,0.3-1,0.5
		l-0.7-0.4l0.3-5.2h5.4v1h-4.3l-0.3,3.4c0.3-0.1,0.6-0.3,0.8-0.3s0.6-0.1,0.9-0.1c1,0,1.8,0.3,2.4,0.8c0.6,0.6,0.9,1.4,0.9,2.6
		c0,0.8-0.1,1.4-0.5,2s-0.8,1-1.3,1.3c-0.6,0.3-1.1,0.4-1.8,0.4C470.8,640,470.1,639.8,469.5,639.5z"/>
	<path d="M483.3,630.3v1.1l-3.3,8.4h-1.3l3.3-8.4h-5.3v-3.8h1.2v2.8H483.3z"/>
	<path d="M487.9,639.1c0.7-0.1,1.1-0.3,1.4-0.6c0.3-0.3,0.4-0.8,0.4-1.5v-6.8h4.2c1.9,0,2.9,1,2.9,3.2v6.3h-1.3v-6.3
		c0-0.8-0.1-1.3-0.5-1.6c-0.3-0.3-0.8-0.5-1.4-0.5H491v5.6c0,1-0.2,1.7-0.7,2.2s-1.3,0.8-2.2,0.9L487.9,639.1z"/>
	<path d="M505.6,631.1c0.5,0.6,0.8,1.4,0.8,2.6v6.1H505v-6.1c0-0.8-0.1-1.3-0.5-1.7c-0.3-0.4-0.8-0.6-1.4-0.6h-2.9v8.4h-1.3v-9.1
		v-0.4h4.5C504.4,630.3,505.1,630.6,505.6,631.1z"/>
	<path d="M507.9,639.1c0.7-0.1,1.1-0.3,1.4-0.6c0.3-0.3,0.4-0.8,0.4-1.5v-6.8h4.2c1.9,0,2.9,1,2.9,3.2v6.3h-1.3v-6.3
		c0-0.8-0.1-1.3-0.5-1.6c-0.3-0.3-0.8-0.5-1.4-0.5h-2.6v5.6c0,1-0.2,1.7-0.7,2.2c-0.5,0.5-1.3,0.8-2.2,0.9L507.9,639.1z"/>
	<path d="M526.3,638.6c-0.7,0.8-1.7,1.3-2.9,1.3c-0.4,0-0.9-0.1-1.4-0.1l0.1-1c0.3,0.1,0.8,0.1,1.2,0.1c1,0,1.7-0.3,2.1-1
		s0.7-1.6,0.7-2.9c0-2.5-0.8-3.7-2.4-3.7c-0.6,0-1.1,0.2-1.5,0.6c-0.3,0.3-0.6,0.8-0.8,1.4l-1.2,6.6h-1.3l1.3-6.6l-1.3-2.9h1.4
		l0.8,2c0.2-0.7,0.6-1.3,1.1-1.6c0.5-0.3,1-0.6,1.7-0.6c1.1,0,2,0.3,2.6,1.1c0.6,0.8,1,2,1,3.6C527.3,636.6,527,637.8,526.3,638.6z"
		/>
	<path d="M532.9,632.2l-0.2-2h1.3l0.2,2v7.5h-1.3V632.2z"/>
	<path d="M539.2,638.7c0.6-0.1,1.1-0.2,1.5-0.4c0.4-0.2,0.8-0.6,1-1.1c0.3-0.6,0.4-1.3,0.5-2.2l0.3-3.6h-6.1v-1h7.5l-0.1,0.9
		l-0.3,3.4c-0.1,1.7-0.5,2.9-1.3,3.8c-0.8,0.8-1.7,1.3-3.1,1.3L539.2,638.7z M536.4,634.9l0.2-1.2h1.1v9.4h-1.3L536.4,634.9
		L536.4,634.9z"/>
	<path d="M549.6,632.2l-0.2-2h1.3l0.2,2v2.6v1l-1.3,0.3V632.2z"/>
	<path d="M560.4,638.7v1h-2.5h-4.9v-1h4.9v-5.3c0-0.8-0.1-1.3-0.5-1.6c-0.3-0.3-0.8-0.5-1.4-0.5h-3.1v-1h3.3c1.9,0,2.9,1,2.9,3.2
		v5.3L560.4,638.7L560.4,638.7z"/>
	<path d="M562.4,632.2l-0.2-2h1.3l0.2,2v7.5h-1.3V632.2z"/>
	<path d="M573.1,630.3c0,2.4-0.1,4.1-0.3,5.2c-0.2,1.2-0.6,2.1-1.3,2.7c-0.6,0.7-1.7,1.2-3.1,1.5l-2.9,0.6l-0.2-1l3-0.6l-2.2-8.4
		h1.3l2.1,8c0.7-0.3,1.2-0.7,1.5-1.3c0.3-0.6,0.5-1.4,0.6-2.3c0.1-0.9,0.1-2.4,0.1-4.4H573.1z"/>
	<path d="M586.2,633.4v6.3h-1.3H579h-0.3v-9.5h1.3h3.3C585.2,630.3,586.2,631.3,586.2,633.4z M584.8,638.7v-5.3
		c0-0.8-0.1-1.3-0.5-1.6c-0.3-0.3-0.8-0.5-1.4-0.5h-3v7.4H584.8z"/>
	<path d="M595.5,630.3c0,2.4-0.1,4.1-0.3,5.2c-0.2,1.2-0.6,2.1-1.3,2.7c-0.6,0.7-1.7,1.2-3.1,1.5l-2.9,0.6l-0.2-1l3-0.6l-2.2-8.4
		h1.3l2.1,8c0.7-0.3,1.2-0.7,1.5-1.3c0.3-0.6,0.5-1.4,0.6-2.3c0.1-0.9,0.1-2.4,0.1-4.4H595.5z"/>
	<path d="M600.7,630.3h1.3l1,8.4c1.3-0.1,2.4-0.6,3.2-1.3c0.8-0.7,1.2-1.8,1.1-3.3v-0.4c0-0.7-0.2-1.3-0.6-1.7
		c-0.3-0.4-0.9-0.7-1.7-0.7l-0.8-0.1l0.1-1l1,0.1c1,0.1,1.8,0.3,2.4,0.9c0.6,0.6,0.9,1.3,0.9,2.4v0.5c0.1,2-0.5,3.4-1.7,4.4
		c-1.3,1-2.9,1.5-5,1.6L600.7,630.3z"/>
	<path d="M613,638.7c0.6-0.1,1.1-0.2,1.5-0.4c0.4-0.2,0.8-0.6,1-1.1c0.3-0.6,0.4-1.3,0.5-2.2l0.3-3.6h-6.1v-1h7.5l-0.1,0.9l-0.3,3.4
		c-0.1,1.7-0.5,2.9-1.3,3.8c-0.8,0.8-1.7,1.3-3.1,1.3L613,638.7z M610.3,634.9l0.2-1.2h1.1v9.4h-1.3L610.3,634.9L610.3,634.9z"/>
	<path d="M620,632.2l-0.2-2h1.3l0.2,2v2.6v1l-1.3,0.3V632.2z"/>
	<path d="M623.8,632.2l-0.2-2h1.3l0.2,2v2.6v1l-1.3,0.3V632.2z"/>
	<path d="M634.7,638.7v1h-2.5h-4.9v-1h4.9v-5.3c0-0.8-0.1-1.3-0.5-1.6c-0.3-0.3-0.8-0.5-1.4-0.5h-3.1v-1h3.3c1.9,0,2.9,1,2.9,3.2
		v5.3L634.7,638.7L634.7,638.7z"/>
	<path d="M636.6,632.2l-0.2-2h1.3l0.2,2v7.5h-1.3V632.2z"/>
	<path d="M646.6,639.8l-3.9-5.9l-1.8,5.9h-1.3l1.9-5.9l0.5-1.1l-1.6-2.5h1.3l1.5,2.4l1.6,2.4l0.4-1.4l1-3.3h1.3l-2,5.9l2.4,3.6
		L646.6,639.8L646.6,639.8z"/>
</g>
</svg>

</div>
`;
