import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";

export enum ETooltipPlace {
    TOP = "top",
    RIGHT = "right",
    BOTTOM = "bottom",
    LEFT = "left",
}

interface ITooltip {
    id: string;
    children: React.ReactChild;
    elemRef: React.RefObject<HTMLInputElement>;
    place?: ETooltipPlace;
}

const Tooltip = ({
    id,
    children,
    elemRef,
    place = ETooltipPlace.TOP,
}: ITooltip) => {
    const [show, setShow] = useState(true);

    useEffect((): any => {
        const elem = elemRef.current;
        if (!elem) {
            return false;
        }
        const handleMouseOver = () => setShow(true);
        elem.addEventListener("mouseover", handleMouseOver);

        const handleMouseLeave = () => setShow(false);
        elem.addEventListener("mouseleave", handleMouseLeave);

        return () => {
            if (!elem) {
                return false;
            }
            elem.removeEventListener("mouseover", handleMouseOver);
            elem.removeEventListener("mouseleave", handleMouseLeave);
        };
    }, []);

    const style = { display: show ? "block" : "none" };

    return (
        <div className="hide-mobile" style={{ ...style, position: "absolute" }}>
            <ReactTooltip
                place={place}
                id={id}
                backgroundColor="rgba(85, 106, 128, 0.9);"
                effect="solid"
            >
                {children}
            </ReactTooltip>
        </div>
    );
};

export default Tooltip;
