/* eslint-disable */
import React, { useRef, useState } from "react";
// @ts-ignore
import Slider from "react-slick-pnth";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import ArrowButton from "../buttons/ArrowButton";
import { breakpoints, device } from "../../assets/styles/global";
import { styledBackgroundImages } from "../sideBar/foldMenu/backgrounds/BackgroundsImages";

interface IGalleryElements {
    children: React.ReactNode;
}

const GalleryContainer = styled.div`
    position: relative;
    .slick-track {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const StyledSlider = styled(Slider)`
    padding: 0 20px;
    margin: 0 auto;
    .boxStyled {
        margin: 0 auto;
    }
    .background {
        img {
            object-fit: cover;
            max-width: initial;
            max-height: initial;
        }
    }
    .icons {
        width: 30px;
        display: flex;
        align-items: center;
    }
    img {
        margin: 0 auto;
        width: 45px;
    }
`;

const StyledButton = styled(ArrowButton)`
    height: 100%;
`;

const GalleryElements = ({ children }: IGalleryElements) => {
    const sliderRef = useRef<Slider | null>(null);
    const childrenArray = React.Children.toArray(children);

    const maxShowDesktop = 4;
    const settings = {
        LazyLoad: true,
        infinite: childrenArray.length > maxShowDesktop,
        speed: 500,
        slidesToShow: Math.min(maxShowDesktop, childrenArray.length),
        slidesToScroll:
            childrenArray.length > maxShowDesktop * 2 ? maxShowDesktop : 1,
        swipe: false,
        rtl: true,
        draggable: false,
        touchMove: false,
        responsive: [
            {
                breakpoint: breakpoints.mobile,
                settings: {
                    swipe: true,
                    draggable: true,
                    touchMove: true,
                },
            },
        ],
    };

    const goToNextSlide = () => {
        sliderRef.current?.slickPrev();
    };

    const goToPreviousSlide = () => {
        sliderRef.current?.slickNext();
    };

    return (
        <GalleryContainer>
            <StyledSlider ref={sliderRef} {...settings}>
                {childrenArray}
            </StyledSlider>
            {childrenArray.length > maxShowDesktop && (
                <StyledButton
                    className="positionMiddle left_0"
                    handleOnclick={goToPreviousSlide}
                />
            )}
            {childrenArray.length > maxShowDesktop && (
                <StyledButton
                    className="positionMiddle right_0"
                    before={true}
                    handleOnclick={goToNextSlide}
                />
            )}
        </GalleryContainer>
    );
};

export default GalleryElements;
