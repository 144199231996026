import React, { useCallback, useContext, useEffect, useRef } from "react";

import ToolbarBtn from "../../buttons/ToolbarBtn";
import { PageContext } from "../../../context/PageProvider";
import {
    EditorDataContext,
    EUpdateEditorActions,
} from "../../../context/EditorDataProvider";
import Tooltip, { ETooltipPlace } from "../../tool-tip/Tooltip";
import { nanoid } from "nanoid";
import { ReactComponent as DuplicateIcon } from "../../../assets/icons/duplicate.svg";
import { IEditorDataItem } from "../../../interfaces/editor-data.interface";
import { StyledTitleToolBar } from "../../style-components/styleComponents";

export function duplicateItem(item: IEditorDataItem) {
    const id = nanoid();
    const key = id;
    const left = item.left + 40;
    const top = item.top - 40;
    return { ...item, id, key, left, top, duplicate: true };
}
interface IDuplicate {
    showToolBarTxt?: boolean;
}
const Duplicate = ({ showToolBarTxt = true }: IDuplicate) => {
    const { page }: any = useContext(PageContext);
    const { editorData, updateEditorData }: any = useContext(EditorDataContext);
    const items = editorData[page].items;
    const focusedItems = items.filter((item: IEditorDataItem) => item.focus);

    const handleKeyPress = useCallback(
        async (e) => {
            if (e.keyCode === 68 && (e.ctrlKey || e.metaKey)) {
                e.preventDefault();
                handleOnDuplicate();
            }
        },
        [editorData],
    );

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPress);
        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [handleKeyPress]);

    const handleOnDuplicate = () =>
        focusedItems.map((item: IEditorDataItem) =>
            updateEditorData(
                duplicateItem(item),
                EUpdateEditorActions.ADD_ITEM,
            ),
        );

    const ref = useRef(null);
    const tooltipId = `duplicateTooltip-${nanoid()}`;
    const txt = "שכפול";
    return (
        <>
            <ToolbarBtn
                ref={ref}
                data-tip
                data-for={tooltipId}
                onClick={handleOnDuplicate}
            >
                <DuplicateIcon />
                {showToolBarTxt && (
                    <StyledTitleToolBar className="hide-desktop">
                        {txt}
                    </StyledTitleToolBar>
                )}
            </ToolbarBtn>
            <Tooltip place={ETooltipPlace.BOTTOM} id={tooltipId} elemRef={ref}>
                {txt}
            </Tooltip>
        </>
    );
};

export default Duplicate;
