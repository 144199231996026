import React, { Profiler } from "react";
import ReactDOM from "react-dom/client";
import "./assets/fonts/fonts.css";
import "./assets/styles/global.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { onRender } from "./utils/performanceProfiler";
const isProd = window.location.href.includes("https://onlineeditor.co.il/");

if (isProd) {
    Sentry.init({
        dsn: "https://dbd22969fb56015913c5e975527ea675@o4507205201756160.ingest.de.sentry.io/4507213722943568",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            "https://onlineeditor.s3.eu-west-1.amazonaws.com/index.html",
        ],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}
//npx @sentry/wizard@latest -i sourcemaps
const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);

root.render(
    <React.StrictMode>
        <Profiler id="App" onRender={onRender}>
            {isProd ?
                <App />
            :   <Profiler id="App" onRender={onRender}>
                    <App />
                </Profiler>
            }
        </Profiler>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
